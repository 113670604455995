import { useSession } from 'next-auth/react';

const getSessionData = () => {
  const { data: session } = useSession();
  //@ts-ignore
  const role = session && session.user && session.user.role;
  //@ts-ignore
  const userId = session && session.user && session.user.id;
  //@ts-ignore
  const name = session && session.user && session.user.name;
  //@ts-ignore
  const userEmail =
    session && session.user && session.user.email && session.user.email;

  return { role, userId, userEmail, name };
};

export default getSessionData;
