/** @jsxImportSource @emotion/react */
import axios from 'axios';
import { signIn } from 'next-auth/react';
import { useRouter } from 'next/router';
import * as React from 'react';
import { useState } from 'react';
import { useMutation } from 'react-query';
import { BodyDataPost } from '../../../backend/type';
import ComponentWithLogicDataFetching from '../../../components/utils/ComponentWithLogicDataFetching';
import { postDataForAWS } from '../../../services/amazon';
import { postDataToAWS } from '../../../services/amazon/function';
import { routesOut, statusServer } from '../../../services/bridge';
import {
  logErrorAsyncMessage,
  logMessage,
  resizeFile,
  urltoFile,
} from '../../../services/common';
import { TLanguages } from '../../../services/data';
import errorsJson from '../../../services/data/json/errors.json';
import successJson from '../../../services/data/json/success.json';
import { bcryptHash } from '../../../services/security';
import { TTypeConfigSizeImage } from '../../../services/style/type';
import { submitFormCases } from '../data/constant';
import { connectReactHookFormWithYup } from '../service/UtilComponent';
import { doWeResizeImage } from '../service/function';
import { TForm } from '../type';
import { getFieldForForm } from './elements/GetFieldForForm';
import SubmitForm from './elements/SubmitForm';
import SubmitFormV2 from './elements/SubmitFormV2';
import { errorCSS, successCSS } from './elements/sharedCSS';

const returnSubmitHTML = (value) => {
  switch (value) {
    case submitFormCases.submitFormV2:
      return SubmitFormV2;
    default:
      return SubmitForm;
  }
};

interface TFormComponet extends TForm {
  // eslint-disable-next-line no-unused-vars
  refetch?: (x?: any) => void;

  // eslint-disable-next-line no-unused-vars
  functionToApply?: (x: any) => void;
  typeSubmit?: string;
}
const FormDefault: React.FC<TFormComponet> = ({
  fields,
  URLKey,
  typeForm,
  submitValue,
  refetch,
  functionToApply,
  typeSubmit,
}) => {
  const { locale, push } = useRouter();
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [doWeDisableSubmit, setDoWeDisableSubmit] = useState(false);
  const [notification, setNotification] = useState({
    error: false,
    message: '',
  });
  const [error, setError] = useState(false);
  const { handleSubmit, errors, register, control, watch, setValue, reset } =
    connectReactHookFormWithYup(typeForm, locale as TLanguages);
  const SubmitHOC = ComponentWithLogicDataFetching(
    returnSubmitHTML(typeSubmit),
  );
  const postForm = useMutation(
    (body: BodyDataPost) => axios.post(routesOut.data[URLKey].link, body),
    {
      onSuccess: (response) => {
        if (
          response &&
          functionToApply &&
          response.data &&
          (typeForm == 'requestImageApiGenerator' ||
            typeForm == 'characteristicAvatar')
        )
          functionToApply(response.data);

        if (
          typeForm == 'newPatient' ||
          typeForm == 'newUserToProcessEvaluation' ||
          typeForm == 'updateUserDetails' ||
          typeForm == 'newComment' ||
          typeForm == 'newCommentJourney' ||
          typeForm == 'newJourney' ||
          typeForm == 'newConsentForm' ||
          typeForm == 'newPatientNoDiagnostics' ||
          typeForm == 'confirmAccount' ||
          typeForm == 'defineTheNewPassword' ||
          typeForm == 'saveDataGeneratorImage'
        ) {
          const message =
            response &&
            response.data &&
            response.data.message &&
            successJson[locale][response.data.message]
              ? successJson[locale][response.data.message]
              : successJson[locale].success;
          if (typeForm == 'newUserToProcessEvaluation') {
            refetch({ message, error: false });
          } else {
            refetch();
            setNotification({
              error: false,
              message: message,
            });
          }
        }

        setDoWeDisableSubmit(false);
        reset();
        setTimeout(() => setNotification({ error: false, message: '' }), 3500);
        if (typeForm == 'newRestrictedUser') setTimeout(() => push('/'), 3500);
        //! tofix all of my queries does not show up in devtool. form and react-query must be checked for more infos
        // queryClient.invalidateQueries(['processEvaluation', Number(id)]);
      },
      onError: (error: { response: { data: { message: string } } }) => {
        const message =
          error &&
          error.response &&
          error.response.data &&
          error.response.data.message &&
          errorsJson[locale][error.response.data.message]
            ? errorsJson[locale][error.response.data.message]
            : errorsJson[locale].server;
        if (typeForm == 'newUserToProcessEvaluation') {
          refetch({ message, error: true });
        } else {
          setNotification({
            error: true,
            message,
          });
        }
        setDoWeDisableSubmit(false);
        setTimeout(() => setNotification({ error: false, message: '' }), 3500);
      },
    },
  );
  const { isLoading } = postForm;

  const onSubmit = async (body) => {
    try {
      setLoadingSubmit(true);
      setDoWeDisableSubmit(true);
      if (body && body.image && body.image.length > 0) {
        body.image = await Promise.all(
          body.image.map(async (img) => {
            if (img.data_url.startsWith('https')) {
              return img.data_url;
            } else {
              img.data_url = doWeResizeImage(typeForm)
                ? await resizeFile(img.file, typeForm as TTypeConfigSizeImage)
                : img.data_url;
              img.file = await urltoFile(
                img.data_url,
                img.file.name,
                img.file.type,
              ).then(function (file) {
                return file;
              });
              const isUrl = await postDataToAWS(
                postDataForAWS.uploadImage,
                img.file,
              );
              return isUrl ? isUrl : '';
            }
          }),
        );
      }
      if (body && body.passwordCreate) {
        body.passwordCreate = await bcryptHash(body.passwordCreate);
        body.passwordConfirmation = body.passwordCreate;
      }
      if (typeForm == 'newUserToProcessEvaluation') {
        const result = await axios.get(
          routesOut.data.isUserAlreadyRegistered(body.email as string).link,
        );
        if (result && result.status === statusServer.success) {
          body.isUserAlreadyRegistered = true;
          if (result && !result.data) {
            await signIn('email', {
              redirect: false,
              email: body.email,
              callbackUrl: '/',
            });
            body.isUserAlreadyRegistered = false;
          }
        } else {
          throw new Error('');
        }
      }
      postForm.mutate(body);
    } catch (error) {
      setDoWeDisableSubmit(false);
      logMessage(`${logErrorAsyncMessage('organism/form', 'on submit')},
			${error}`);
    } finally {
      setLoadingSubmit(false);
    }
  };

  if (error) setTimeout(() => setError(false), 3000);

  return (
    <form
      onSubmit={handleSubmit((datas: any) => onSubmit(datas))}
      id={'hook-form'}
      data-testid="form"
    >
      {fields.map((field) => {
        field.register = { ...register(field.name) };
        field.errors = errors;
        field.control = control;
        field.tractValuesForm = watch;
        field.updateValue = setValue;
        if (field.openField) {
          field.openField.register = { ...register(field.openField.name) };
          field.openField.errors = errors;
          field.openField.control = control;
          field.openField.tractValuesForm = watch;
          field.openField.updateValue = setValue;
        }
        return <div key={field.name}>{getFieldForForm(field)}</div>;
      })}
      <div className="flex_column_center_align submit_button_wrapper">
        <SubmitHOC
          isErrorServer={false}
          isLoading={isLoading || loadingSubmit}
          typeLoader={'v2'}
          props={{ value: submitValue, disabled: doWeDisableSubmit }}
        />
        <p
          css={[notification.error ? errorCSS : successCSS]}
          className="notification_form font_size_22_light"
        >
          {notification.message}
        </p>
      </div>
    </form>
  );
};
export default FormDefault;
