import { css } from '@emotion/react';
import { usePathname } from 'next/navigation';
import { useRouter } from 'next/router';
import { useEffect, useId, useState } from 'react';
import Select from 'react-select';
import { evaluationData } from '../../../features/evaluation';
import { administration } from '../../../services/common/constant';
import { TLanguages } from '../../../services/data';
import { optionsLanguages } from '../../../services/data/constant';
import { findIndexOptionsLanguages } from '../../../services/header';
import { ITheme } from '../../../services/style/type';
import IconsLogos from '../../elements/IconsLogos';
import { secondarySpaceMarginAsGapFlex } from '../../share';
const customStyles = {
  self: (props: ITheme) => css`
    .deft_header_select svg {
      font-size: 1.5rem;
      width: 1.9rem;
      padding: 0;
    }

    .select_language__menu-list {
      padding: 0;
    }

    .select_language__menu {
      text-align: center; /* Center the text within the select menu */
      width: max-content;
    }

    .select_language__option {
      text-transform: capitalize;
      text-align: center;
      color: black;
    }

    .select_language__option--is-focused {
      background-color: ${props.colors.fourth_third};
    }
    .select_language__option--is-selected {
      background-color: ${props.colors.fourth_second};
    }

    .select_language__control {
      background-color: transparent;
      border: none;
      position: relative;
    }

    .select_language__control::after {
      content: '';
      position: absolute;
      width: 100%;
      left: 0;
      top: 100%;
      color: #888;
      border: 1px solid #fcfcfc91;
    }
    .select_language__indicator {
      color: #fcfcfc91;
    }
    @media (max-width: 500px) {
      .select_language__indicators {
        display: none;
      }
      .deft_header_select {
        gap: 0.3rem;
      }
    }
    .select_language__indicator-is-selected {
      color: white;
    }
    .select_language__indicator-separator {
      display: none;
    }
    .select_language__indicator {
      margin-left: -4px;
      padding: 0px;
    }

    .select_language__value-container {
      padding: 0;
    }
    .select_language__single-value {
      color: white;
      text-transform: capitalize;
      font-size: min(1rem, 18px);
      font-family: 'Book';
      padding: 5px;
    }
    @media (max-width: 550px) : {
      width: 100%;
    }
  `,
};
const LanguageSelection = ({ languagePreference }) => {
  const [languageSelected, setLanguageSelected] = useState(languagePreference);
  const IDSelect = useId();
  const { locale } = useRouter();
  const indexOfLocale = findIndexOptionsLanguages(locale as TLanguages);
  const path = usePathname();
  useEffect(() => {
    const language = languageSelected || languagePreference;
    if (language) {
      window.location.href = `${administration.website}/${language + path}`;
      if (languageSelected !== languagePreference && languageSelected)
        document.cookie = `selectedLanguage=${languageSelected};path=/;max-age=31536000`;
    }
  }, [languageSelected || languagePreference]);

  return (
    <div css={customStyles.self} className="flex_row_btw_center">
      <div
        className="deft_header_select flex_align_center"
        css={secondarySpaceMarginAsGapFlex}
      >
        <IconsLogos type={'world'} />
        <form data-testid="form-select-lanaguage">
          <label htmlFor={IDSelect}>
            <Select
              classNamePrefix="select_language"
              instanceId={IDSelect}
              name="selectLanguage"
              placeholder={evaluationData[locale].selects.default}
              aria-labelledby={IDSelect}
              defaultValue={optionsLanguages[0]}
              value={
                indexOfLocale > -1 ? optionsLanguages[indexOfLocale] : null
              }
              onChange={(choice) => setLanguageSelected(choice.value)}
              options={optionsLanguages}
            />
          </label>
        </form>
      </div>
    </div>
  );
};

export default LanguageSelection;
