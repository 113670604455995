import * as React from 'react';
import IconsLogos from '../elements/IconsLogos';
import { css } from '@emotion/react';
import { ITheme } from '../../services/style/type';

type TButtonOriginal = {
  text: string;
  icon: string;
  functionToApply?: () => void;
  extra?: React.ReactNode;
};
const componentCSS = {
  self: (props: ITheme) =>
    css({
      width: '100%',
      minHeight: 55,
      textAlign: 'center',
      verticalAlign: 'middle',
      backgroundColor: props.background.secondary,
      border: 'none',
      borderRadius: `${props.extra.border_radius * 1.5}px`,
      color: 'white',
      paddingTop: '.4rem',
      paddingBottom: '.4rem',
      '@media (min-width: 450px)': {
        width: 'max-content',
      },
      '& span': {
        paddingLeft: '.5rem',
        paddingRight: '.5rem',
        '&::first-letter': {
          textTransform: 'uppercase',
        },
      },
      '& svg': {
        '& path': {
          fill: 'white',
        },
      },
    }),
};
const ButtonOriginal: React.FC<TButtonOriginal> = ({
  text,
  icon,
  functionToApply,
  extra,
}) => {
  return (
    <button
      onClick={() => {
        if (functionToApply) functionToApply();
      }}
      css={componentCSS.self}
      className="flex_align_center_justify_center_wrap button_original"
      aria-labelledby="button original"
    >
      <span className="image_upload_button_select font_size_26_bold">
        {' '}
        {text}{' '}
      </span>
      <IconsLogos type={icon} />
      {extra && extra}
    </button>
  );
};
export default ButtonOriginal;
