import { EditorState } from 'draft-js';
import React, { useEffect, useState } from 'react';
import RichEditor from '../../../../components/elements/RichEditor';
import { TFormField } from '../../type';

type Props = {
  data?: TFormField;
};

const JourneyForm: React.FC<Props> = ({ data }) => {
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const handleEditorState = (editorState) => setEditorState(editorState);

  useEffect(() => {
    setEditorState(EditorState.createEmpty());
  }, [data.defaultValue]);

  return (
    <div>
      <RichEditor
        data={{
          editorState,
          handleEditorState,
        }}
        formDataControl={data}
      />
    </div>
  );
};
export default JourneyForm;
