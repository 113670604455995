import { css } from '@emotion/react';
import { useSession } from 'next-auth/react';
import * as React from 'react';
//useQueryClient
import { useRouter } from 'next/router';
import ButtonPlus from '../../../components/buttons/ButtonPlus';
import Alert from '../../../components/elements/Alert';
import { TLanguages } from '../../../services/data';
import {
  isRoleAllowed,
  returnDetailsUser,
} from '../../../services/user/functions';
import {
  NewProcessEvaluation,
  TEvaluationType,
  evaluationsType,
} from '../../evaluation';
import { getUserDetails } from '../../user';
import { postNewProcess } from '../service/util';
import evaluationsData from '../data/evaluations.json';

const componentCSS = {
  self:
    // @ts-ignore
    css`
      position: fixed;
      bottom: 0;
      right: 1rem;
    `,
};
type TPatientEvaluationsFooter = {
  refetch: () => void;
  isEvaluationAlreadyAdded;
};
const AddNewProcessEvaluation: React.FC<TPatientEvaluationsFooter> = ({
  refetch,
  isEvaluationAlreadyAdded,
}) => {
  const { locale } = useRouter();
  const [doWeDisplay, setDoWeDisplay] = React.useState(false);
  const [isDisabled, setIsDisabled] = React.useState(true);
  const [doWeDisplayAlert, setDoWeDisplayAlert] = React.useState(false);
  const [notification, setNotification] = React.useState({
    type: '',
    text: '',
  });
  if (!doWeDisplay && notification.type)
    setNotification({ type: '', text: '' });

  const handleDisplayNewEvaluationSelection = () =>
    setDoWeDisplay(!doWeDisplay);
  const { data: session } = useSession();
  const userDetails = getUserDetails(
    //@ts-ignore
    session && session.user && session.user.id ? session.user.id : null,
  );

  const isUserHasTheRightToInputEvaluation =
    userDetails &&
    userDetails.data &&
    userDetails.data.data &&
    userDetails.data.data.UserInvitedPrivilege &&
    ((userDetails.data.data.UserInvitedPrivilege[0] &&
      (userDetails.data.data.UserInvitedPrivilege[0]
        .canInputEvaluationAndNotSeeAllEvaluations ||
        userDetails.data.data.UserInvitedPrivilege[0]
          .canInputAndSeeAllEvaluations)) ||
      userDetails.data.data.UserInvitedPrivilege.length == 0);

  if (isDisabled)
    setTimeout(() => {
      setIsDisabled(false);
    }, 1000);
  const handleAddSingleEvaluation = () => {
    if (isDisabled) return;
    handleSelectedOption(options[0]);
  };
  const user = returnDetailsUser(session, userDetails);
  const router = useRouter();
  const { id } = router.query;
  const mutation = postNewProcess(
    locale as TLanguages,
    refetch,
    setNotification,
  );
  const handleSelectedOption = (option: {
    value: TEvaluationType | null;
    label: TEvaluationType | string;
  }) => {
    if (option && option.value) {
      let newEvaluationProcess = {
        // @ts-ignore
        profile: session.user.role,
        name: user.name,
        // @ts-ignore
        userId: session.user.id,
        patientId: id as string,
        // @ts-ignore
        evaluationType: option.value === 'adhd' ? 'tdah' : option.value,
        processEvaluationId: null,
        id: 0,
      };
      mutation.mutate(newEvaluationProcess);
    }
  };
  const options = evaluationsType.map((evaluation: TEvaluationType) => {
    return {
      label:
        //@ts-ignore
        evaluation === 'adhd' && (locale == 'ptbr' || locale == 'fr')
          ? 'tdah'
          : evaluation,
      value: evaluation,
    };
  });
  const onConfirm = (mess) => {
    setDoWeDisplayAlert(false);
    if (mess) {
      if (options.length > 1) return handleDisplayNewEvaluationSelection();
      handleAddSingleEvaluation();
    }
  };
  return (
    <div css={componentCSS.self} className="patient_evaluations_footer">
      {doWeDisplayAlert && (
        <Alert
          message={evaluationsData[locale].confirmNewEvaluationAdding}
          onConfirm={onConfirm}
          answers={evaluationsData[locale].confirm}
        />
      )}
      {isRoleAllowed(session) && isUserHasTheRightToInputEvaluation && (
        <>
          <div
            onKeyDown={(event) => {
              if (isEvaluationAlreadyAdded) return setDoWeDisplayAlert(true);
              if (event.key === 'Enter') {
                if (options.length > 1)
                  return handleDisplayNewEvaluationSelection();
                handleAddSingleEvaluation();
              }
            }}
            role="button"
            tabIndex={0}
            onClick={() => {
              if (isEvaluationAlreadyAdded) return setDoWeDisplayAlert(true);
              if (options.length > 1)
                return handleDisplayNewEvaluationSelection();
              handleAddSingleEvaluation();
            }}
            className="flex_end button_add_new_evaluation"
          >
            <ButtonPlus icon={'plus'} />
          </div>
          {doWeDisplay && evaluationsType.length > 1 && (
            <NewProcessEvaluation
              hide={handleDisplayNewEvaluationSelection}
              handleSelectedOption={handleSelectedOption}
              isLoading={mutation.isLoading}
              notification={notification}
              options={options}
            />
          )}
        </>
      )}
    </div>
  );
};
export default AddNewProcessEvaluation;
