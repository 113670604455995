import { Message } from 'react-hook-form';
import * as Yup from 'yup';
import { logErrorAsyncMessage, logMessage } from '../../../services/common';
import errors from '../../../services/data/json/errors.json';
import { TLanguages } from '../../../services/data/type';
import staticData from '../../../services/data/json/staticData.json';
import { TFormsType } from '../type';

const getValidationSchema = (form: TFormsType, language: TLanguages) => {
  const schemasForm = {
    updatePrivileges: Yup.object().shape({
      id: Yup.string(),
      canInputEvaluationAndNotSeeAllEvaluations: Yup.boolean(),
      canInputAndSeeAllEvaluations: Yup.boolean(),
      canSeeJourneyComments: Yup.boolean(),
      canAddJourneyComments: Yup.boolean(),
    }),
    saveColorHeaderFooter: Yup.object().shape({
      color: Yup.object().shape({
        mainColor: Yup.string(),
        gradient: Yup.string(),
      }),
      userId: Yup.string().required(
        errors[language].required as unknown as Message,
      ),
    }),
    updateJourneyPrivilege: Yup.object().shape({
      journeyId: Yup.string(),
      state: Yup.mixed(),
    }),
    updateNotificationView: Yup.mixed(),
    test: Yup.object().shape({
      name: Yup.string()
        .required(errors[language].required as unknown as Message)
        .matches(/^\S/, errors[language].noSpace[0])
        .matches(/^([^0-9]*)$/, errors[language].noDigit[0])
        .typeError(errors[language].name[0]),
      email: Yup.string()
        .matches(/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g, errors[language].email[0])
        .required(errors[language].required as unknown as Message),
      startDate: Yup.mixed().required(
        errors[language].required as unknown as Message,
      ),
      preconditionPatient: Yup.string().required(
        errors[language].required as unknown as Message,
      ),
      professionalType: Yup.object().shape({
        label: Yup.string().required(
          errors[language].required as unknown as Message,
        ),
        value: Yup.string().required(
          errors[language].required as unknown as Message,
        ),
      }),
    }),
    saveUploadedImageForCustomisation: Yup.object().shape({
      typeGenerator: Yup.string().required(
        errors[language].required as unknown as Message,
      ),
      userId: Yup.string().required(
        errors[language].required as unknown as Message,
      ),
      image: Yup.mixed(),
    }),
    updateDataBase: Yup.mixed(),
    newUser: Yup.object().shape({
      acceptPolicy: Yup.string()
        .matches(/^true$/, errors[language].acceptPolicy[0])
        .required(),
      name: Yup.string()
        .matches(/^\S/, errors[language].noSpace[0])
        .typeError(errors[language].name[0])
        .required(errors[language].required as unknown as Message),
      email: Yup.string()
        .email(errors[language].email[0])
        .required(errors[language].required as unknown as Message),
      country: Yup.object().shape({
        id: Yup.number().required(
          errors[language].required as unknown as Message,
        ),
        label: Yup.string().required(
          errors[language].required as unknown as Message,
        ),
        value: Yup.string().required(
          errors[language].required as unknown as Message,
        ),
      }),
      school: Yup.object().shape({
        id: Yup.number().required(
          errors[language].required as unknown as Message,
        ),
        label: Yup.string().required(
          errors[language].required as unknown as Message,
        ),
        value: Yup.string().required(
          errors[language].required as unknown as Message,
        ),
      }),
      classRoom: Yup.object().shape({
        id: Yup.number().required(
          errors[language].required as unknown as Message,
        ),
        label: Yup.string().required(
          errors[language].required as unknown as Message,
        ),
        value: Yup.string().required(
          errors[language].required as unknown as Message,
        ),
      }),
      // .typeError(errors[language].required as unknown as Message)
      // .required(errors[language].required as unknown as Message),

      phone: Yup.string()
        // Note: no rules are applied to "phone" as the field must accept all different kind of number such: +44() - 01 etc.
        // However, we can add a select box where the user need to choose the country code and apply a regex against the entry.
        .required(errors[language].required as unknown as Message),
      // @ts-ignore
      interest: Yup.object().required(
        errors[language].required as unknown as Message,
      ),
      profile: Yup.object().required(
        errors[language].required as unknown as Message,
      ),
    }),
    confirmAccount: Yup.object().shape({
      confirmPassword: Yup.string().required(),
    }),
    login: Yup.object().shape({
      email: Yup.string()
        .matches(/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g, errors[language].email[0])
        .required(errors[language].required as unknown as Message),
      password: Yup.string().required(
        errors[language].required as unknown as Message,
      ),
    }),
    userInscription: Yup.object().shape({
      name: Yup.string()
        .required(errors[language].required as unknown as Message)
        .matches(/^\S/, errors[language].noSpace[0])
        .matches(/^([^0-9]*)$/, errors[language].noDigit[0])
        .typeError(errors[language].name[0]),
      email: Yup.string()
        .required(errors[language].required as unknown as Message)
        .matches(/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g, errors[language].email[0]),

      passwordCreate: Yup.string().required(
        errors[language].required as unknown as Message,
      ),
      passwordConfirmation: Yup.string().oneOf(
        //@ts-ignore
        [Yup.ref('passwordCreate'), null],
        errors[language].rewritePassword[0],
      ),
      language: Yup.string().required(
        errors[language].required as unknown as Message,
      ),
    }),
    askForANewPassowrd: Yup.object().shape({
      email: Yup.string()
        .matches(/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g, errors[language].email[0])
        .required(errors[language].required as unknown as Message),
      language: Yup.string().required(
        errors[language].required as unknown as Message,
      ),
    }),
    defineTheNewPassword: Yup.object().shape({
      email: Yup.string()
        .required(errors[language].required as unknown as Message)
        .matches(/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g, errors[language].email[0]),
      passwordCreate: Yup.string().required(
        errors[language].required as unknown as Message,
      ),
      passwordConfirmation: Yup.string()
        .required(errors[language].required as unknown as Message)
        .oneOf(
          //@ts-ignore
          [Yup.ref('passwordCreate'), null],
          errors[language].rewritePassword[0],
        ),
      id: Yup.string().required(),
    }),
    newRestrictedUser: Yup.object().shape({
      email: Yup.string()
        .email(errors[language].email[0])
        .required(errors[language].required as unknown as Message),
      pin: Yup.string().required(
        errors[language].required as unknown as Message,
      ),
      urlTemporaryId: Yup.string().required(
        errors[language].required as unknown as Message,
      ),
    }),
    updateUserDetails: Yup.object().shape({
      name: Yup.string()
        .matches(/^\S/, errors[language].noSpace[0])
        .typeError(errors[language].name[0])
        .required(errors[language].required as unknown as Message),
      email: Yup.string()
        .email(errors[language].email[0])
        .required(errors[language].required as unknown as Message),
      // image: Yup.mixed(),
      authorisationEmailing: Yup.string()
        .matches(/^(true|false)$/i)
        .nullable(),
      userId: Yup.string().required(
        errors[language].required as unknown as Message,
      ),
    }),
    updateAuthorisationEmailing: Yup.object().shape({
      authorisationEmailing: Yup.string().matches(/^(true|false)$/i),
      userId: Yup.string().required(
        errors[language].required as unknown as Message,
      ),
    }),
    newPatient: Yup.object().shape({
      name: Yup.string()
        .matches(/^\S/, errors[language].noSpace[0])
        .typeError(errors[language].name[0])
        .required(errors[language].required as unknown as Message),
      userId: Yup.string().required(
        errors[language].required as unknown as Message,
      ),
      surname: Yup.string()
        .matches(/^\S/, errors[language].noSpace[0])
        .typeError(errors[language].name[0])
        .required(errors[language].required as unknown as Message),
      dateOfBirth: Yup.string().required(errors[language].requiredDate[0]),
      gender: Yup.object().shape({
        label: Yup.string().required(
          errors[language].required as unknown as Message,
        ),
        value: Yup.string().required(
          errors[language].required as unknown as Message,
        ),
      }),
      country: Yup.object().shape({
        id: Yup.number().required(
          errors[language].required as unknown as Message,
        ),
        label: Yup.string().required(
          errors[language].required as unknown as Message,
        ),
        value: Yup.string().required(
          errors[language].required as unknown as Message,
        ),
      }),
      school: Yup.object().shape({
        id: Yup.number().nullable(),
        label: Yup.string().required(
          errors[language].required as unknown as Message,
        ),
        value: Yup.string().required(
          errors[language].required as unknown as Message,
        ),
      }),
      classRoom: Yup.object().shape({
        id: Yup.number().nullable(),
        label: Yup.string().required(
          errors[language].required as unknown as Message,
        ),
        value: Yup.string().required(
          errors[language].required as unknown as Message,
        ),
      }),
      motherName: Yup.string().nullable(),
      motherDateOfBirth: Yup.string().nullable(),
      motherOccupation: Yup.string().nullable(),
      motherSchooling: Yup.object().shape({
        label: Yup.string().nullable(),
        value: Yup.string().nullable(),
      }),
      motherMartialStatus: Yup.object().shape({
        label: Yup.string().nullable(),
        value: Yup.string().nullable(),
      }),
      motherMartialStatusSpecification: Yup.string().nullable(),
      motherBiologicalParent: Yup.object().shape({
        label: Yup.string().nullable(),
        value: Yup.string().nullable(),
      }),
      childBirth: Yup.object().shape({
        label: Yup.string().nullable(),
        value: Yup.string().nullable(),
      }),
      gestationalAge: Yup.object().shape({
        label: Yup.string().nullable(),
        value: Yup.string().nullable(),
      }),
      weight: Yup.string().nullable(),
      length: Yup.string().nullable(),
      cryAfterBirth: Yup.object().shape({
        label: Yup.string().nullable(),
        value: Yup.string().nullable(),
      }),
      healthProblems: Yup.object().shape({
        label: Yup.string().nullable(),
        value: Yup.string().nullable(),
      }),
      healthProblemSpecification: Yup.string().nullable(),
      dischareMaternity: Yup.object().shape({
        label: Yup.string().nullable(),
        value: Yup.string().nullable(),
      }),
      fatherName: Yup.string().nullable(),
      fatherDateOfBirth: Yup.string().nullable(),
      fatherOccupation: Yup.string().nullable(),
      fatherSchooling: Yup.object().shape({
        label: Yup.string().nullable(),
        value: Yup.string().nullable(),
      }),
      fatherMartialStatus: Yup.object().shape({
        label: Yup.string().nullable(),
        value: Yup.string().nullable(),
      }),
      fatherBiologicalParent: Yup.object().shape({
        label: Yup.string().nullable(),
        value: Yup.string().nullable(),
      }),
      peopleInHouse: Yup.string().nullable(),
      siblingNameFirst: Yup.string().nullable(),
      siblingAgeFirst: Yup.string().nullable(),
      siblingIsFatherFirst: Yup.object().shape({
        label: Yup.string().nullable(),
        value: Yup.string().nullable(),
      }),
      siblingIsMotherFirst: Yup.object().shape({
        label: Yup.string().nullable(),
        value: Yup.string().nullable(),
      }),
      siblingNameSecond: Yup.string().nullable(),
      siblingAgeSecond: Yup.string().nullable(),
      siblingIsFatherSecond: Yup.object().shape({
        label: Yup.string().nullable(),
        value: Yup.string().nullable(),
      }),
      siblingIsMotherSecond: Yup.object().shape({
        label: Yup.string().nullable(),
        value: Yup.string().nullable(),
      }),
      siblingNameThird: Yup.string().nullable(),
      siblingAgeThird: Yup.string().nullable(),
      siblingIsFatherThird: Yup.object().shape({
        label: Yup.string().nullable(),
        value: Yup.string().nullable(),
      }),
      siblingIsMotherThird: Yup.object().shape({
        label: Yup.string().nullable(),
        value: Yup.string().nullable(),
      }),
      siblingNameFourth: Yup.string().nullable(),
      siblingAgeFourth: Yup.string().nullable(),
      siblingIsFatherFourth: Yup.object().shape({
        label: Yup.string().nullable(),
        value: Yup.string().nullable(),
      }),
      siblingIsMotherFourth: Yup.object().shape({
        label: Yup.string().nullable(),
        value: Yup.string().nullable(),
      }),
      image: Yup.mixed(),
    }),
    newPatientNoDiagnostics: Yup.object().shape({
      name: Yup.string()
        .matches(/^\S/, errors[language].noSpace[0])
        .typeError(errors[language].name[0])
        .required(errors[language].required as unknown as Message),
      userId: Yup.string().required(
        errors[language].required as unknown as Message,
      ),
      surname: Yup.string()
        .matches(/^\S/, errors[language].noSpace[0])
        .typeError(errors[language].name[0])
        .required(errors[language].required as unknown as Message),
      dateOfBirth: Yup.string().required(errors[language].requiredDate[0]),
      gender: Yup.object().shape({
        label: Yup.string().required(
          errors[language].required as unknown as Message,
        ),
        value: Yup.string().required(
          errors[language].required as unknown as Message,
        ),
      }),
      country: Yup.object().shape({
        id: Yup.number(),
        label: Yup.string().required(
          errors[language].required as unknown as Message,
        ),
        value: Yup.string().required(
          errors[language].required as unknown as Message,
        ),
      }),
      school: Yup.object().shape({
        id: Yup.number().nullable(),
        label: Yup.string().required(
          errors[language].required as unknown as Message,
        ),
        value: Yup.string().required(
          errors[language].required as unknown as Message,
        ),
      }),
      classRoom: Yup.object().shape({
        id: Yup.number().nullable(),
        label: Yup.string().required(
          errors[language].required as unknown as Message,
        ),
        value: Yup.string().required(
          errors[language].required as unknown as Message,
        ),
      }),
      motherName: Yup.string().nullable(),
      fatherName: Yup.string().nullable(),
      image: Yup.mixed(),
    }),
    newJourney: Yup.object().shape({
      journey: Yup.string()
        .typeError(errors[language].journey[0])
        .required(errors[language].required as unknown as Message),
      userId: Yup.string().required(
        errors[language].required as unknown as Message,
      ),
      image: Yup.mixed(),
      patientId: Yup.string().required(
        errors[language].required as unknown as Message,
      ),
      extraInfosForNotification: Yup.object().shape({
        typeEvaluation: Yup.string(),
        proccessEvaluationId: Yup.string(),
        patientId: Yup.string().nullable(),
        userId: Yup.string().nullable(),
      }),
    }),
    newCommentJourney: Yup.object().shape({
      comment: Yup.string()
        .matches(/^\S/, errors[language].noSpace[0])
        .typeError(errors[language].name[0])
        .required(errors[language].required as unknown as Message),
      userId: Yup.string().required(
        errors[language].required as unknown as Message,
      ),
      journeyId: Yup.string().required(
        errors[language].required as unknown as Message,
      ),
      extraInfosForNotification: Yup.object().shape({
        typeEvaluation: Yup.string(),
        proccessEvaluationId: Yup.string(),
        patientId: Yup.string().nullable(),
        userId: Yup.string().nullable(),
      }),
    }),
    newConsentForm: Yup.object().shape({
      userId: Yup.string().required(
        errors[language].required as unknown as Message,
      ),
      agreement: Yup.string().matches(/^(true|false)$/i),
    }),
    newProcessEvaluation: Yup.object().shape({
      profile: Yup.string().required(
        errors[language].required as unknown as Message,
      ),
      name: Yup.string()
        .matches(/^\S/, errors[language].noSpace[0])
        .required(errors[language].required as unknown as Message),
      userId: Yup.string().required(
        errors[language].required as unknown as Message,
      ),
      evaluationType: Yup.string().required(
        errors[language].required as unknown as Message,
      ),
      patientId: Yup.string().required(
        errors[language].required as unknown as Message,
      ),
    }),
    newEvaluation: Yup.object().nullable(),
    newSchool: Yup.object().shape({
      name: Yup.string()
        .matches(/^\S/, errors[language].noSpace[0])
        .required(errors[language].required as unknown as Message),
      countryId: Yup.string().required(
        errors[language].required as unknown as Message,
      ),
      userId: Yup.string().nullable(),
    }),
    newClass: Yup.object().shape({
      name: Yup.string()
        .matches(/^\S/, errors[language].noSpace[0])
        .typeError(errors[language].name[0])
        .required(errors[language].required as unknown as Message),
      schoolId: Yup.string().required(
        errors[language].required as unknown as Message,
      ),
    }),
    newComment: Yup.object().shape({
      comment: Yup.string()
        .matches(/^\S/, errors[language].noSpace[0])
        .typeError(errors[language].name[0])
        .required(errors[language].required as unknown as Message),
      processEvaluationId: Yup.string().required(
        errors[language].required as unknown as Message,
      ),
      userId: Yup.string().required(
        errors[language].required as unknown as Message,
      ),
      asAnswerTo: Yup.string().nullable(),
      idFamily: Yup.number().nullable(),
      extraInfosForNotification: Yup.object().shape({
        typeEvaluation: Yup.string(),
        proccessEvaluationId: Yup.string(),
        patientId: Yup.string().nullable(),
        userId: Yup.string().nullable(),
        replyToUserId: Yup.string().nullable(),
      }),
    }),
    updatePreconisationMetrics: Yup.object().shape({
      id: Yup.string().nullable(),
      percentageView: Yup.string().required(
        errors[language].required as unknown as Message,
      ),
      userId: Yup.string(),
      numberOfWatch: Yup.string(),
      isThePreconisationUsefull: Yup.boolean().nullable(),
      review: Yup.string().nullable(),
    }),
    newUserToProcessEvaluation: Yup.object().shape({
      name: Yup.string()
        .matches(/^\S/, errors[language].noSpace[0])
        .typeError(errors[language].name[0])
        .required(errors[language].required as unknown as Message),
      email: Yup.string()
        .matches(/^\S/, errors[language].noSpace[0])
        .email(errors[language].email[0])
        .required(errors[language].required as unknown as Message),
      processEvaluationId: Yup.string().required(
        errors[language].required as unknown as Message,
      ),
      patientId: Yup.string().required(
        errors[language].required as unknown as Message,
      ),
      language: Yup.string().required(
        errors[language].required as unknown as Message,
      ),
      userEmail: Yup.string().required(
        errors[language].required as unknown as Message,
      ),
      roleOfTheNewUser: Yup.object().shape({
        label: Yup.string().required(
          errors[language].required as unknown as Message,
        ),
        value: Yup.string().required(
          errors[language].required as unknown as Message,
        ),
      }),
      userInvitedPrivilege: Yup.mixed().required(
        errors[language].required as unknown as Message,
      ),
      isUserAlreadyRegistered: Yup.bool(),
    }),
    characteristicAvatar: Yup.object().shape({
      age: Yup.string()
        .matches(/^\S/, errors[language].noSpace[0])
        .typeError(errors[language].name[0])
        .required(errors[language].required as unknown as Message),
      hairStyle: Yup.string()
        .matches(/^\S/, errors[language].noSpace[0])
        .typeError(errors[language].name[0])
        .required(errors[language].required as unknown as Message),
      gender: Yup.string()
        .matches(/^\S/, errors[language].noSpace[0])
        .typeError(errors[language].name[0])
        .required(errors[language].required as unknown as Message),
      userId: Yup.string().required(
        errors[language].required as unknown as Message,
      ),
      additionalInformations: Yup.string().required(
        errors[language].required as unknown as Message,
      ),
    }),
    requestImageApiGenerator: Yup.object().shape({
      requestApi: Yup.string()
        .matches(/^\S/, errors[language].noSpace[0])
        .typeError(errors[language].name[0])
        .required(errors[language].required as unknown as Message),
      typeGenerator: Yup.string().required(
        errors[language].required as unknown as Message,
      ),
      userId: Yup.string().required(
        errors[language].required as unknown as Message,
      ),
    }),
    saveDataGeneratorImage: Yup.object().shape({
      userId: Yup.string().required(
        errors[language].required as unknown as Message,
      ),
      typeGenerator: Yup.string().required(
        errors[language].required as unknown as Message,
      ),
      image: Yup.mixed(),
    }),
  };
  return schemasForm[form];
};
const isFormValidated = async (typeForm: TFormsType, body: any) => {
  try {
    const schema = getValidationSchema(typeForm, 'eng');
    await schema.validate(body);
    // if the schema not validated  an error will be thrown.
    return true;
  } catch (error) {
    logMessage(`${logErrorAsyncMessage(
      'services/form/function',
      'isFormValidated',
    )},
			${error}`);
    // schema not validated
    return false;
  }
};
const handleNotification = (typeForm: TFormsType, language: TLanguages) => {
  switch (typeForm) {
    case 'newPatient':
      return staticData[language].forms.divers[14];
    case 'newUserToProcessEvaluation':
      return staticData[language].forms.divers[15];
    case 'newComment':
      return staticData[language].forms.divers[13];
    default:
      return staticData[language].forms.divers[4];
  }
};
const doWeResizeImage = (typeForm: TFormsType) => {
  const listOfTypeformNotToResize = ['saveDataGeneratorImage'];
  return Boolean(!listOfTypeformNotToResize.includes(typeForm));
};
export {
  getValidationSchema,
  isFormValidated,
  handleNotification,
  doWeResizeImage,
};
