import React from 'react';
import { motion } from 'framer-motion';
import { ContextProviderProps } from '../../services/data';
import { animationTransitionTime } from '../../services/style/constant';

interface OpacityAnimationProps extends ContextProviderProps {
  isOpen: boolean;
}

const opacityAnimation = {
  open: {
    display: 'block',
    opacity: 1,
    transition: {
      duration: animationTransitionTime.primary,
    },
  },
  closed: {
    display: 'none',
    opacity: 0, // Adjust the opacity value according to your requirement
    transition: {
      duration: animationTransitionTime.primary,
    },
  },
};

const OpacityAnimation: React.FC<OpacityAnimationProps> = ({
  children,
  isOpen,
}) => {
  return (
    <motion.div
      variants={opacityAnimation}
      animate={isOpen ? 'open' : 'closed'}
      className="opacityAnimationToggleLeftRight"
      initial={'closed'}
    >
      {children}
    </motion.div>
  );
};

export default OpacityAnimation;
