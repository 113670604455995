/** @jsxImportSource @emotion/react */
import * as React from 'react';
import Slider from 'react-slick';
import Util from './util';

type TSlider = {
  Components: React.ReactNode[];
};
const SliderComponent: React.FC<TSlider> = ({ Components }) => {
  const { sliderRef, settings } = Util();

  return (
    <div data-testid="slider-id">
      {/*//@ts-ignore */}
      <Slider ref={sliderRef} {...settings}>
        {/*//@ts-ignore */}
        {Components.map((ComponentHTML) => ComponentHTML)}
      </Slider>
    </div>
  );
};

export default SliderComponent;
