import { logErrorAsyncMessage, logMessage } from '../common';
import {
  createPresignedUrlWithClient,
  listObjectsInABucketAWS,
  uploadFileFromBackEnd,
  uploadFileToS3,
} from './AWSFunctions';
import { getDataForAWS, postDataForAWS } from './constant';
import { regex } from '../data/constant';

type TObjectAWS = {
  Key: string;
  LastModified: any;
  ETag: string;
  Size: number;
  StorageClass: string;
  Metadata: {};
};

const listPresignedUrlObject = async (objects: TObjectAWS[]) => {
  try {
    const bucketInfos = {
      bucket: process.env.AWS_NAME_BUCKET_GUIDANCE,
      key: '',
    };
    let link;

    const result = [];
    for (const obj of objects) {
      // console.log(obj, 'OBJ AWS');
      let extension = obj.Key.match(regex.extractExtension);
      if (!extension) continue;
      link = await getDataFromAWS(getDataForAWS.askPresignUrl, {
        ...bucketInfos,
        key: obj.Key,
      });
      if (!link) continue;
      let nameFile = obj.Key.match(regex.extractWordAfterLastSlash);
      let nameDiagnostic = obj.Key.match(regex.extractWordAfterFirstSlash);
      result.push({
        link,
        extension: extension[0],
        nameFile: nameFile[0],
        nameDiagnostic: nameDiagnostic[1],
        metaData: obj.Metadata,
        // nameDiagnostic is added to the ID as two similar video from different diagnostic have the same ETag.
        id: obj.ETag.replace(/^"|"$/g, '') + nameDiagnostic[1],
      });
    }

    return result;
  } catch (error) {
    logMessage(`${logErrorAsyncMessage(
      'amazon/function',
      'listPresignedUrlObject'
    )},
			${error}`);
  }
};

const getDataFromAWS = async (type: string, object: any) => {
  try {
    let result;
    switch (type) {
      case getDataForAWS.listObjects:
        result = await listObjectsInABucketAWS(object);
        return result;
      case getDataForAWS.askPresignUrl:
        result = await createPresignedUrlWithClient(object);
        return result;
      case getDataForAWS.returnAllPresinUrlFromALisObjects:
        result = await listPresignedUrlObject(object);
        return result;
      default:
        return null;
    }
  } catch (error) {
    logMessage(`${logErrorAsyncMessage(
      'amazon/function/getDataFromAWS',
      'getDataFromAWS'
    )},
			${error}`);
  }
};

const postDataToAWS = async (type: string, object: any) => {
  try {
    let result;
    switch (type) {
      case postDataForAWS.uploadImage:
        result = uploadFileToS3(object);
        return result;
      case postDataForAWS.uploadImageFromBackend:
        result = await uploadFileFromBackEnd(object);
        return result;
      default:
        break;
    }
  } catch (error) {
    logMessage(`${logErrorAsyncMessage(
      'amazon/function/postDataToAWS',
      'postDataToAWS'
    )},
			${error}`);
    throw new Error(error);
  }
};

export { getDataFromAWS, postDataToAWS };
