function getContrastColor(hexColor) {
  // Convert hex color to RGB
  const r = parseInt(hexColor.substr(1, 2), 16);
  const g = parseInt(hexColor.substr(3, 2), 16);
  const b = parseInt(hexColor.substr(5, 2), 16);

  // Calculate relative luminance using the formula from the WCAG
  const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;

  // Return either black or white based on luminance
  return luminance > 0.5 ? '#000000' : '#FFFFFF';
}

export { getContrastColor };
