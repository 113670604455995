import {
  returnNewFormatDate,
  returnAgeFromBirthDay,
  returnDifferenceTimeStamp,
  returnFormatDateOrdinal,
  returnActualYear,
  returnDate,
  returnDateFromDateDB,
  returnDateAndHoursFromDateDB,
  returnDateFromDateDBGB,
  reverseFormatDate,
  getFormattedDate,
  getDaysDifference,
  returnNewFormatDateSecond,
} from './functions/date';
import { resizeFile, urltoFile } from './functions/file';
import {
  getImageDataUrl,
  formatName,
  createMarkup,
  removeDuplicates,
  addSpaceBeforeCapital,
  combineNestedArrays,
  returnInitialNames,
  testFunction,
  logErrorAsyncMessage,
  logMessage,
} from './functions/function';

export {
  getImageDataUrl,
  formatName,
  urltoFile,
  resizeFile,
  createMarkup,
  removeDuplicates,
  addSpaceBeforeCapital,
  combineNestedArrays,
  returnInitialNames,
  testFunction,
  logErrorAsyncMessage,
  logMessage,
  returnNewFormatDate,
  returnAgeFromBirthDay,
  returnDifferenceTimeStamp,
  returnFormatDateOrdinal,
  returnActualYear,
  returnDate,
  returnDateFromDateDB,
  returnDateAndHoursFromDateDB,
  returnDateFromDateDBGB,
  reverseFormatDate,
  getFormattedDate,
  getDaysDifference,
  returnNewFormatDateSecond,
};
