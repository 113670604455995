import { css } from '@emotion/react';
import Image from 'next/image';
import { useRouter } from 'next/router';
import React, { useEffect } from 'react';
import { Controller } from 'react-hook-form';
import ImageUploading from 'react-images-uploading';

import { formsData } from '../..';
import IconsLogos from '../../../../components/elements/IconsLogos';
import { TFormField } from '../../type';
import { errorCSS } from './sharedCSS';

type TImageUpload = {
  data: TFormField;
  isMultiple?: boolean;
};
const componentCSS = {
  self: css({
    '& button': {
      border: 'none',
      background: 'none',
      borderRadius: '30px',
    },
  }),
  buttonDrop: css({
    border: 'none',
    background: 'none',
    borderRadius: '30px',
    gap: '10px',
    color: 'white',
    padding: '.8rem',
    paddingLeft: '1.3rem',
    paddingRight: '1.5rem',
    margin: 'auto',
    marginTop: '1.5rem',
  }),
  buttonUpload: css({ '& span': { textTransform: 'capitalize' } }),
  buttonRemove: css({
    border: 'none',
    background: 'none',
    borderRadius: '30px',
    gap: '5px',
    padding: '.8rem',
    margin: 'auto',
    '& span': { textTransform: 'capitalize' },

    '&:first-of-type': {
      display: 'none',
    },
  }),
};
const ImageUpload: React.FC<TImageUpload> = ({
  data: {
    label,
    errors,
    name,
    control,
    updateValue,
    defaultValue,
    secretField,
  },
  isMultiple,
}) => {
  const { locale } = useRouter();
  const { divers } = formsData[locale];
  const [images, setImages] = React.useState([]);
  const maxNumber = 69;
  const onChange = (imageList) => {
    // data for submit
    setImages(imageList);
  };
  useEffect(() => {
    if (defaultValue) {
      const data = {
        data_url: defaultValue.data_url ? defaultValue.data_url : defaultValue,
        file: defaultValue.file ? defaultValue.file : '',
        isAPrivateLink: defaultValue.isAPrivateLink
          ? defaultValue.isAPrivateLink
          : false,
      };
      updateValue('image', [
        {
          ...data,
        },
      ]);
    }
  }, [defaultValue]);
  if (secretField) return;

  return (
    <div className="image_upload" css={componentCSS.self}>
      <Controller
        name={name}
        render={({ field: { ref, ...field } }) => {
          return (
            <ImageUploading
              aria-labelledby={label}
              multiple={isMultiple ? true : false}
              value={images}
              onChange={(e) => {
                onChange(e);
              }}
              maxNumber={maxNumber}
              dataURLKey="data_url"
              //@ts-ignore
              inputRef={ref}
              {...field}
            >
              {({
                imageList,
                onImageUpload,
                // onImageRemoveAll,
                onImageUpdate,
                onImageRemove,
                isDragging,
                dragProps,
              }) => {
                return (
                  <div>
                    <div> {isDragging && 'hello'}</div>
                    <button
                      type="button"
                      className="image_upload_button_select flex_align_center_around"
                      css={componentCSS.buttonDrop}
                      style={isDragging ? { color: 'red' } : undefined}
                      aria-label="upload file"
                      onClick={(e) => {
                        e.stopPropagation();
                        onImageUpload();
                      }}
                      {...dragProps}
                    >
                      <IconsLogos type={'upload'} />
                      <span>{label}</span>
                    </button>
                    &nbsp;
                    {imageList && imageList.length > 0 && (
                      <div className="flex_align_center_justify_center_wrap">
                        {imageList.map((image, index) => (
                          <div
                            key={index}
                            className="image-item flex_column_center_center"
                            css={{
                              gap: '5px',

                              '& img': {
                                borderRadius: '10px',
                              },
                            }}
                          >
                            <Image
                              src={image['data_url']}
                              alt=""
                              width="50"
                              height="50"
                            />
                            <div className="image-item__btn-wrapper">
                              <button
                                aria-label="update file"
                                type="button" // to not trigger submit button form
                                onClick={(e) => {
                                  e.stopPropagation();
                                  onImageUpdate(index);
                                }}
                                css={componentCSS.buttonUpload}
                              >
                                <span> {divers[0]} </span>
                              </button>
                              <button
                                aria-label="remove file"
                                className="remove_button"
                                type="button" // to not trigger submit button form
                                onClick={(e) => {
                                  e.stopPropagation();
                                  onImageRemove(index);
                                }}
                                css={componentCSS.buttonRemove}
                              >
                                <span> {divers[1]}</span>
                              </button>
                            </div>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                );
              }}
            </ImageUploading>
          );
        }}
        control={control}
      />
      <span css={errorCSS}>{errors[name] ? errors[name].message : ''}</span>
    </div>
  );
};

export default ImageUpload;
