import { routesIn, routesOut, statusServer, dynamicRoute } from './constant';
import {
  ERoles,
  IErrorReturn,
  ILinkRole,
  RoutesIn,
  RoutesOut,
  TOptionsAxios,
  TResult,
  URLKey,
} from './type';

export { routesIn, routesOut, statusServer, dynamicRoute, ERoles };

export type {
  URLKey,
  RoutesOut,
  RoutesIn,
  ILinkRole,
  IErrorReturn,
  TOptionsAxios,
  TResult,
};
