const translateDiagnostic = {
  eng: {
    disattention: 'inattention',
    inattention: 'inattention',
    hyperactivity: 'hyperactivity',
    odd: 'opposition',
    cognition: 'cognition',
    interpersonal: 'interpersonal',
    dla: 'dla',
    autonomy: 'autonomy',
    movement: 'movement',
    communication: 'communication',
    functionality: 'functionality',
  },
  fr: {
    disattention: 'inattention',
    inattention: 'inattention',
    hyperactivity: 'hyperactivité',
    odd: 'opposition',
    cognition: 'cognition',
    interpersonal: 'interpersonnel',
    dla: 'dla',
    autonomy: 'autonomie',
    movement: 'mouvement',
    communication: 'communication',
    functionality: 'fonctionnalité',
  },
  ptbr: {
    disattention: 'desatenção',
    inattention: 'desatenção',
    hyperactivity: 'hiperatividade',
    odd: 'oposição',
    cognition: 'cognição',
    interpersonal: 'interpessoal',
    dla: 'dla',
    autonomy: 'autonomia',
    movement: 'movimento',
    communication: 'comunicação',
    functionality: 'funcionalidade',
  },
  es: {
    disattention: 'Inatención',
    inattention: 'Inatención',
    hyperactivity: 'hiperactividad',
    odd: 'oposición',
    cognition: 'cognición',
    interpersonal: 'interpersonal',
    dla: 'dla',
    autonomy: 'autonomía',
    movement: 'movimiento',
    communication: 'comunicación',
    functionality: 'funcionalidad',
  },
};

export { translateDiagnostic };
