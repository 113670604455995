import React, { useEffect, useState } from 'react';
import { TModes } from '../data/type';

interface IWebSiteTheme {
  mode: TModes;
  footerHeaderBackgroundColor?: string;
}
interface IWebsiteThemeContext {
  webSiteTheme: IWebSiteTheme;
  handleWebSiteTheme: any;
}
const WebSiteContext = React.createContext<IWebsiteThemeContext>({
  webSiteTheme: {
    mode: 'light',
    footerHeaderBackgroundColor: '',
  },
  handleWebSiteTheme: (a: IWebSiteTheme) => a,
});

export const UpdateWebSiteContext = () => {
  const [webSiteTheme, setWebSiteTheme] = useState<IWebSiteTheme>({
    mode: 'light',
    footerHeaderBackgroundColor: '',
  });
  useEffect(() => setWebSiteTheme({ ...webSiteTheme }), []);
  const handleWebSiteTheme = (webSiteTheme: IWebSiteTheme) =>
    setWebSiteTheme(webSiteTheme);
  return { webSiteTheme, handleWebSiteTheme };
};

export default WebSiteContext;
