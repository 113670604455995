import axios from 'axios';
import * as React from 'react';
import { Controller } from 'react-hook-form';
import { useMutation } from 'react-query';
import CreatableSelect from 'react-select/creatable';
import { routesOut, statusServer } from '../../../services/bridge';
import ComponentWithLogicDataFetching from '../../utils/ComponentWithLogicDataFetching';

import { useRouter } from 'next/router';
import { TOption } from './School';

import { IBodyNewClass } from '../../../backend/institution';
import { evaluationData } from '../../../features/evaluation';
import {
  TFormField,
  customStylesSelection,
  errorCSS,
  labelCSS,
} from '../../../features/forms';
import { getClasses } from '../../../features/user';
interface ISchoolHTML {
  props: {
    options: TOption[] | [];
    isLoading: boolean;
    errors: {};
    name: string;
    label: string;
    placeHolder: string;
    control: any;
    school: TOption;
    refetch: () => void;
    defaultValue?: { id: string | number };
    updateValue: Function;
  };
}
const ClassesHTML: React.FC<ISchoolHTML> = ({
  props: {
    options,
    isLoading,
    errors,
    name,
    label,
    placeHolder,
    control,
    school,
    refetch,
    defaultValue,
    updateValue,
  },
}) => {
  React.useEffect(() => {
    if (defaultValue && options && options.length)
      (() => {
        const classRoomDefault = options.filter(
          (opt: TOption) => opt.id == defaultValue.id
        );
        if (classRoomDefault.length)
          updateValue('classRoom', {
            id: classRoomDefault[0].id,
            ...classRoomDefault[0],
          });
      })();
  }, [defaultValue]);
  // const queryClient = useQueryClient();
  const newClass = useMutation(
    (newClass: IBodyNewClass) =>
      axios
        .post(routesOut.data.newClass.link, newClass)
        .then((result) => result.data),
    {
      onSuccess: () => {
        //! tofix all of my queries does not show up in devtool. form and react-query must be checked for more infos
        refetch();
        // queryClient.invalidateQueries(['classes', school.id]);
      },
    }
  );
  const handleCreate = (input: string) => {
    const data: IBodyNewClass = {
      name: input,
      schoolId: school.id,
    };

    newClass.mutate(data);
  };
  const { locale } = useRouter();
  return (
    <div className="flex_column" data-testid={`form-${name}-testID`}>
      <label htmlFor={label} css={labelCSS}>
        {label}
      </label>
      <Controller
        name={name}
        render={({ field }) => (
          <CreatableSelect
            formatCreateLabel={(inputText) =>
              `${evaluationData[locale].selects.create}: "${inputText}"`
            }
            aria-labelledby={label}
            styles={customStylesSelection}
            inputId={label}
            instanceId={label}
            onChange={field.onChange}
            placeholder={placeHolder}
            ref={field.ref}
            options={options}
            {...field}
            isLoading={isLoading}
            isClearable
            isSearchable
            onCreateOption={handleCreate}
          />
        )}
        control={control}
      />
      <span css={errorCSS}>
        {errors[name] && errors[name].value ? errors[name].value.message : ''}
      </span>
    </div>
  );
};
type TScreenThree = {
  data: TFormField;
};
export const ClassRoom: React.FC<TScreenThree> = ({
  data: {
    tractValuesForm,
    errors,
    name,
    label,
    placeHolder,
    control,
    defaultValue,
    updateValue,
    secretField,
  },
}) => {
  const school = tractValuesForm('school');

  const { data, isLoading, isError, refetch } = getClasses(
    school ? school.id : null
  );
  const options =
    data && data.status === statusServer.success
      ? data.data.map((classSchool: { id: number; name: string }) => {
          return {
            value: classSchool.name,
            label: classSchool.name,
            id: classSchool.id,
          };
        })
      : [];
  React.useEffect(() => {
    if (defaultValue && options && options.length && options.length > 0) {
      const isSchoolAlreadyRegisteredDB = options.filter(
        (opt) => opt.id == defaultValue.id && opt.name == defaultValue.name
      );
      if (isSchoolAlreadyRegisteredDB.length > 0) {
        return updateValue(name, {
          id: isSchoolAlreadyRegisteredDB[0].id,
          ...isSchoolAlreadyRegisteredDB[0],
        });
      } else {
        updateValue(name, {
          id: null,
          value: defaultValue.value,
          label: defaultValue.label,
        });
      }
    } else if (defaultValue) {
      updateValue(name, {
        id: null,
        value: defaultValue.value,
        label: defaultValue.label,
      });
    } else {
      updateValue(name, {
        id: null,
        value: null,
        label: null,
      });
    }
  }, [defaultValue, data && data.status, school]);
  if (secretField) return;
  const SelectClassHTML = ComponentWithLogicDataFetching(ClassesHTML);
  return (
    <div>
      <SelectClassHTML
        isErrorServer={isError && !data}
        isLoading={false}
        props={{
          options,
          isLoading,
          errors,
          name,
          label,
          placeHolder,
          control,
          school,
          refetch,
          defaultValue,
          updateValue,
        }}
      />
    </div>
  );
};
