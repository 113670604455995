import { css } from '@emotion/react';
import { useRouter } from 'next/router';
import IconsLogos from '../../../components/elements/IconsLogos';
import {
  returnDateFromDateDBGB,
  returnFormatDateOrdinal,
} from '../../../services/common';
import { TLanguages } from '../../../services/data';
import { ITheme } from '../../../services/style/type';
import evolutionsData from '../data/evolutions.json';
import { returnPercentagesFromDiagnosticObject } from '../service/function';
import HeaderEvolutionCard from './elements/HeaderEvolutionCard';
import PercentageDiagnostic from './elements/PercentageDiagnostic';
import { evaluationData } from '../../evaluation';

const componentCSS = {
  self: (props: ITheme) => css`
    gap: 5px;
    margin: auto;
    padding-bottom: 1rem;
    border-bottom: 1px solid #cfcfcf;
    span {
      text-transform: capitalize;
    }

    svg {
      fill: ${props.colors.fifth_second};
    }
    .progress-bar-container {
      height: 15px;
      background-color: #eee;
      border-radius: 10px;
    }

    .disattention .progress-bar {
      height: 100%;
      width: 90%;
      background-color: #fdc700;
    }

    .hyperactivity .progress-bar {
      height: 100%;
      width: 90%;
      background-color: #e65b9b;
    }

    .odd .progress-bar {
      height: 100%;
      width: 90%;
      background-color: #5bbdd7;
    }
  `,
};

const ListEvolutions = ({ props: { informationOfAnEvaluation } }) => {
  if (!informationOfAnEvaluation) return;
  const { locale } = useRouter();
  let areEvaluationsEmpty = false;
  const filterInformationOfAnEvaluation =
    informationOfAnEvaluation.evaluationsInfos &&
    informationOfAnEvaluation.evaluationsInfos.length > 0
      ? informationOfAnEvaluation.evaluationsInfos.filter((evaluation) => {
          if (evaluation.isUnderReview === null) areEvaluationsEmpty = true;

          return !evaluation.isUnderReview && evaluation.isUnderReview !== null;
        })
      : [];

  if (
    areEvaluationsEmpty &&
    filterInformationOfAnEvaluation &&
    filterInformationOfAnEvaluation.length == 0
  )
    return (
      <p className="font_size_22_light_italic">
        {evolutionsData[locale].noEvolutionAvailable[0]}
      </p>
    );

  if (
    filterInformationOfAnEvaluation &&
    filterInformationOfAnEvaluation.length == 0
  )
    return (
      <p className="font_size_22_light_italic">
        {evaluationData[locale].evaluationUndeReview}
      </p>
    );

  return (
    <div style={{ marginTop: '2.3rem', marginBottom: '5rem' }}>
      <h2
        className="font_size_28_medium"
        style={{
          textAlign: 'center',
        }}
      >
        {evolutionsData[locale].text[0]}{' '}
      </h2>
      {filterInformationOfAnEvaluation.map((evolotionDiagnostic, index) => {
        if (!evolotionDiagnostic.diagnostic) return;
        const keys = Object.keys(evolotionDiagnostic.diagnostic.symptom);
        return (
          <div
            key={index}
            css={[componentCSS.self, { marginTop: '40px', gap: '20px' }]}
            className="flex_column"
          >
            <HeaderEvolutionCard
              date={returnFormatDateOrdinal(
                returnDateFromDateDBGB(
                  evolotionDiagnostic.evaluation[0].createdAt,
                ),
                locale as TLanguages,
              )}
              name={evolotionDiagnostic.infosUser.name}
            />
            <div
              className="flex_align_center_no_wrap"
              style={{
                gap: '12px',
              }}
            >
              <IconsLogos type={'men'} />
              <span className="font_size_22_medium">
                {evolutionsData[locale].text[0]}{' '}
              </span>
            </div>
            <div>
              {keys.map((diagnostic, indice) => {
                const { studentPercentage, adhdPercentage } =
                  returnPercentagesFromDiagnosticObject(
                    evolotionDiagnostic.diagnostic.symptom[diagnostic],
                  );
                return (
                  <PercentageDiagnostic
                    key={indice}
                    diagnostic={diagnostic}
                    studentPercentage={studentPercentage}
                    adhdPercentage={adhdPercentage}
                  />
                );
              })}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export { ListEvolutions };
