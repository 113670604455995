import { css } from '@emotion/react';
import { useSession } from 'next-auth/react';
import { ReactNode, useContext, useEffect, useState } from 'react';
import { colorPaletteHeaderFooter } from '../../services/style/constant';
import { ITheme } from '../../services/style/type';
import { getUserDetails } from '../../features/user';
import HeaderProviderContext from '../../services/contexts/HeaderSizeContext';
import WebSiteContext from '../../services/contexts/WebSiteContext';
import BannerUser from '../BannerUser';
export interface IChildren {
  children: ReactNode;
}
const componentCSS = {
  self: (props: ITheme) =>
    css(`
	p, span, input, textarea, h1, h2 {
		 color: ${props.colorsText.primary};
	}

	display: flex;
	flex-direction: column;
  min-height: 100vh;

	.error_color {
		color: ${props.colorsText.error}
	}
		 .header_layout {
      padding-top: 45px;
    }
		.main_layout, .header_layout   {
		      padding-left: ${props.spacing.body_spacing_primary}px !important;
					      padding-right: ${props.spacing.body_spacing_primary}px !important;
		}
	.student_header_evaluation {
								 margin-left: -${props.spacing.body_spacing_primary}px !important;
					     margin-right: -${props.spacing.body_spacing_primary}px !important;
							 padding-left: 4rem;
							 padding-right: 4rem;
							  padding-top: 1.3dvh !important;
							 padding-bottom: 1.3dvh !important;
								}

  @media (max-width: 500px) {
      .main_layout, .header_layout   {
		      padding-left: 10px !important;
					      padding-right: 10px !important;
		}
								.student_header_evaluation {
								 margin-left: -10px !important;
					     margin-right: -10px !important;
							 padding-top:  10px !important;
							 padding-bottom: 10px !important;
							  padding-left: 2rem;
							 padding-right: 2rem;
								}
    }
  `),
  headerFooter: (color: string) => css`
    .default_header,
    .header_layout {
      background-color: ${color} !important;
    
    .main_footer {
      background-color: ${color} !important;
      color: ${
        colorPaletteHeaderFooter.find((element) => element.mainColor == color)
          .colorText
      } !important;
    }
  `,
};
const SuperLayout = ({ children }: IChildren) => {
  const { data: session } = useSession();
  const [loading, setLoading] = useState(true);
  const [colorPreference, setColorPreference] = useState('');
  const [banner, setBanner] = useState('');
  const handleSetLoading = () => setLoading(!loading);
  //@ts-ignore
  const userId = session && session.user ? session.user.id : null;
  const userDetails = getUserDetails(userId);
  const { webSiteTheme, handleWebSiteTheme } = useContext(WebSiteContext);
  useEffect(() => {
    if (userDetails && userDetails.data && userDetails.data.data) {
      if (
        userDetails.data.data.userPreference &&
        userDetails.data.data.userPreference.banner
      )
        setBanner(userDetails.data.data.userPreference.banner);
      if (
        userDetails.data.data.userPreference &&
        userDetails.data.data.userPreference.colourHeaderFooter
      ) {
        setColorPreference(
          userDetails.data.data.userPreference.colourHeaderFooter,
        );

        if (
          userDetails.data.data.userPreference.colourHeaderFooter !=
          webSiteTheme.footerHeaderBackgroundColor
        ) {
          const copyWebSiteTheme = { ...webSiteTheme };
          copyWebSiteTheme.footerHeaderBackgroundColor =
            userDetails.data.data.userPreference.colourHeaderFooter;
          handleWebSiteTheme(copyWebSiteTheme);
        }
      }
    }
  }, [userDetails]);

  return (
    <div
      css={
        colorPreference
          ? [componentCSS.self, componentCSS.headerFooter(colorPreference)]
          : [componentCSS.self]
      }
    >
      {banner && (
        <BannerUser
          banner={banner}
          loading={loading}
          handleSetLoading={handleSetLoading}
        />
      )}
      <HeaderProviderContext>
        <div css={[{ opacity: 0.95 }]}> {children}</div>
      </HeaderProviderContext>
    </div>
  );
};
export default SuperLayout;
