import { css } from '@emotion/react';
import { useRouter } from 'next/router';
import * as React from 'react';
import { buttonFormWithIcon } from '../../../components/footers/sharedCSS';
import { ITheme } from '../../../services/style/type';
import { getSessionData } from '../../../services/userSession';
import FormDefault from '../../forms/components/FormDefault';
import evaluationsData from '../data/evaluations.json';
import { evaluations } from '..';
import IconsLogos from '../../../components/elements/IconsLogos';
import { errorCSS, successCSS } from '../../forms';
const componentCSS = {
  self: (props: ITheme) =>
    css({
      paddingLeft: '1rem',
      paddingRight: '1rem',
      '& .multiselectionCheckBox_select__placeholder': {
        fontSize: '12px',
        color: '#707070',
        fontFamily: 'Book',
      },

      '& .multiselectionCheckBox_select__menu': {
        top: '-10rem',
        left: '-85%',
      },
      '& .multiselectionCheckBox_select__control': {
        width: '100%',
      },
      '& .input_form, .multiselection_form_field, .multiselectionCheckBox': {
        display: 'grid',
        gridTemplateColumns: '1fr 1.5fr' /* Two columns of equal width */,
        marginBottom: '20px',
      },
      '& .multiselection_class__menu-list': {
        display: 'relative',
        maxHeight: '100px',
      },
      '& .multiselection_class__menu': {
        top: '-20%',
      },

      '& .input_form input': {
        margin: 'unset',
        width: 'unset',
        paddingTop: '.4rem',
        paddingBottom: '.4rem',
        paddingLeft: '.5rem',
      },

      '& .multiselection_class__placeholder': {
        fontSize: '12px',
        fontFamily: 'Book',
      },

      '& .multiselection_class__container, .multiselection_class__control': {
        width: '100%',
      },

      '& .css-1ql81ze-placeholder': {
        fontFamily: '12px',
      },
      '& label': {
        whiteSpace: 'nowrap',
        fontSize: 'min(2.5vw, 22px)',
        fontFamily: 'Book',
        flex: '2',
        textAlign: 'left',
      },
      '& input': {
        border: 'none',
        borderBottom: '1px solid grey',
        padding: '0px',
      },
      '& .submit_button_wrapper': {
        display: 'none',
      },
      '& .calendar_button_select': {
        ...buttonFormWithIcon,
      },
      '& .image_upload': {
        '& span': {
          textTransform: 'capitalize',
        },
        '& .image_upload_button_select': {
          width: '50%',
          backgroundColor: '#4c4141',
          color: 'white',
        },
        '& .image-item': {
          '& img': {
            borderRadius: '50%',
            height: '4rem',
            marginTop: '.5rem',
            marginBottom: '.5rem',
          },
        },
        '& .image-item__btn-wrapper': {
          '& button': {
            '&:first-of-type': {
              display: 'none',
            },
            backgroundColor: '#4c4141',
          },
        },
      },
      '& .loader_component': {
        '& svg': {
          stroke: `${props.background.third} !important`,
        },
      },
    }),
};

type TNewPatient = {
  hideForm: () => void;
  processEvaluationId: string;

  refetch: Function;
  evaluationType: string;
  cancelNewUser: Function;
};
const AddUserProcessEvaluation: React.FC<TNewPatient> = ({
  hideForm,
  processEvaluationId,
  refetch,
  evaluationType,
  cancelNewUser,
}) => {
  const { locale, query } = useRouter();
  const [notification, setNotification] = React.useState({
    message: '',
    error: false,
  });
  const patientId = query.id;
  const { userId, userEmail } = getSessionData();
  const { newUserToAProcessEvaluation } = evaluationsData[locale];
  newUserToAProcessEvaluation.fields[2].defaultValue = patientId.toString();
  newUserToAProcessEvaluation.fields[3].defaultValue =
    processEvaluationId.toString();
  newUserToAProcessEvaluation.fields[4].defaultValue = locale;
  newUserToAProcessEvaluation.fields[5].defaultValue = userEmail;
  newUserToAProcessEvaluation.fields[6].defaultValue = {
    typeEvaluation: evaluationType,
    proccessEvaluationId: processEvaluationId,
    userId: userId,
  };
  const handleRefetch = (notification: { message: string; error: boolean }) => {
    setNotification(notification);
    setTimeout(() => {
      refetch();
      hideForm();
    }, 3500);
  };
  return (
    <div css={componentCSS.self} className="add_user_proccess_evaluation">
      <FormDefault {...newUserToAProcessEvaluation} refetch={handleRefetch} />
      <div className="flex_row_btw_center">
        <button className="button_no_decor" onClick={() => cancelNewUser()}>
          <span className="font_size_22_medium" style={{ color: '#93C530' }}>
            {evaluations[locale].divers[2]}
          </span>
        </button>
        <button
          className="button_no_decor flex_align_center_no_wrap "
          onClick={() => {
            const formElement = document.getElementById('submit-form');
            formElement.click();
          }}
          style={{ gap: '10px' }}
        >
          <span className="font_size_22_medium" style={{ color: '#93C530' }}>
            {evaluations[locale].send}
          </span>
          <IconsLogos type={'arrowV3'} />
        </button>
      </div>
      <p css={notification && notification.error ? errorCSS : successCSS}>
        {notification && notification.message.length > 0
          ? notification.message[0]
          : ''}{' '}
      </p>
    </div>
  );
};
export default AddUserProcessEvaluation;

//   React.useEffect(() => {
//     const inputForm = document.querySelectorAll('.multiselection_form_field');
//     // Define the HTML to insert
//     const htmlToInsert = `
//   <div class="flex_row_btw_center" style="margin-bottom: 1rem;">

// 	<span class="font_size_22_book css-13ona4d-InputUI" for="permisao" aria-labelledby="permisao">Permisao</span>
//     <div class="flex user-list-value" style="gap: 18px;">
//    <svg
//           xmlns="http://www.w3.org/2000/svg"
//           width="30"
//           height="20.455"
//           viewBox="0 0 30 20.455"
//         >
//           <path
//             id="Icon_material-outline-visibility"
//             data-name="Icon material-outline-visibility"
//             d="M16.5,8.727a13.323,13.323,0,0,1,12.027,7.5,13.394,13.394,0,0,1-24.055,0A13.323,13.323,0,0,1,16.5,8.727M16.5,6a16.127,16.127,0,0,0-15,10.227,16.114,16.114,0,0,0,30,0A16.127,16.127,0,0,0,16.5,6Zm0,6.818a3.409,3.409,0,1,1-3.409,3.409A3.409,3.409,0,0,1,16.5,12.818m0-2.727a6.136,6.136,0,1,0,6.136,6.136A6.146,6.146,0,0,0,16.5,10.091Z"
//             transform="translate(-1.5 -6)"
//             fill="#93c530"
//           />
//         </svg>
//       <span class="font_size_22_book"> Visualisar</span>
//     </div>
//     <div class="flex user-list-value" style="gap: 18px;">
// 		      <svg
//           xmlns="http://www.w3.org/2000/svg"
//           width="30"
//           height="30"
//           viewBox="0 0 30 30"
//         >
//           <g
//             id="Icon_material-twotone-mode-edit-outline"
//             data-name="Icon material-twotone-mode-edit-outline"
//             transform="translate(-4.5 -4.496)"
//           >
//             <path
//               id="Path_150"
//               data-name="Path 150"
//               d="M4.5,28.247V34.5h6.249L29.18,16.066,22.931,9.816Zm4.866,2.916H7.833V29.63l15.1-15.1,1.533,1.533ZM34.013,8.883l-3.9-3.9a1.66,1.66,0,0,0-2.35,0l-3.05,3.05,6.249,6.249,3.05-3.05a1.66,1.66,0,0,0,0-2.35Z"
//               transform="translate(0 0)"
// 							          fill="#93c530"
//             />
//           </g>
//         </svg>

//       <span class="font_size_22_book"> Responder</span>
//     </div>
//   </div>
// `;

//     if (inputForm && inputForm.length > 0)
//       inputForm[0].insertAdjacentHTML('afterend', htmlToInsert);
//   }, []);
