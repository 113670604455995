import { themeStyle } from '../../services/style';

export const footer = {
  backgroundColor: themeStyle.colors.fifth_third,
  width: '100%',
  zIndex: 10,
  bottom: 0,
  paddingTop: '1.3rem',
  paddingBottom: '1.3rem',
  color: themeStyle.colorsText.fourth,
  '& p': {
    fontWeight: 'bold',
    '&::first-letter': {
      textTransform: 'uppercase',
    },
  },
};

export const buttonFormWithIcon = {
  width: '50%',
  backgroundColor: '#4c4141',
  color: 'white',
  padding: '.5rem',
  margin: 'auto',
  marginTop: '.5rem',
};
