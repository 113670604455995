const getDataForAWS = {
  listObjects: 'listObjects',
  askPresignUrl: 'askPresignUrl',
  returnAllPresinUrlFromALisObjects: 'returnAllPresinUrlFromALisObjects',
};

const postDataForAWS = {
  uploadImage: 'uploadImage',
  uploadImageFromBackend: 'uploadImageFromBackend',
};

const expireTimeAWSLinkSeconds = Number(
  process.env.TIME_VALID_LINKS_PRECONISATIONS
);
export { getDataForAWS, postDataForAWS, expireTimeAWSLinkSeconds };
