import * as React from 'react';
import IconsLogos from '../elements/IconsLogos';
import { css } from '@emotion/react';
import { sizeIconLogoAddhere } from '../share';

const componentCSS = {
  imageWrapper: css({
    position: 'relative',
    backgroundColor: 'white',
    paddingBottom: '2rem',

    '& img': {
      margin: 'auto',
    },
  }),
};

const SubHeader: React.FC = () => {
  return (
    <div
      css={[componentCSS.imageWrapper, sizeIconLogoAddhere]}
      className="flex_row sub_header"
    >
      <IconsLogos type={'addhereHorizontal'} />
    </div>
  );
};
export default SubHeader;
