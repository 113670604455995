/**
 * Hook that alerts clicks outside of the passed ref
 */
import React, { useRef, ReactNode, useEffect, Validator } from 'react';
import PropTypes from 'prop-types';

type TProps = {
  children: ReactNode;
  functionToApply: any;
};

const OutsideAlerter: React.FC<TProps> = ({ children, functionToApply }) => {
  const wrapperRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event: MouseEvent) {
      if (
        wrapperRef.current &&
        !wrapperRef.current.contains(event.target as Node)
      ) {
        functionToApply();
      }
    }
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [wrapperRef, functionToApply]);

  return <div ref={wrapperRef}>{children}</div>;
};

export default OutsideAlerter;

OutsideAlerter.propTypes = {
  children: PropTypes.element.isRequired as Validator<ReactNode>,
};
