import * as React from 'react';
import IconsLogos from './IconsLogos';
import { css } from '@emotion/react';

type TIconAndText = {
  icon: string;
  text: string;
};

const componentCSS = {
  self: css({
    color: 'rgba(0, 0, 0, 0.54)',
    backgroundColor: 'transparent',
    paddingLeft: '1rem',
    paddingTop: '1rem',
    paddingBottom: '1rem',

    '&: hover': {
      cursor: 'pointer',
      // backgroundColor: '#d6d6d6',
      transition: 'backgroundColor 500ms',
    },

    '& span': {
      marginLeft: '1.5rem',
      '&::first-letter': {
        textTransform: 'uppercase',
      },
    },
  }),
};
const IconAndText: React.FC<TIconAndText> = ({ icon, text }) => {
  return (
    <div
      css={componentCSS.self}
      className="flex_align_center_no_wrap icon_text"
    >
      <div className="icon_text_svg_wrapper">
        <IconsLogos type={icon} />
      </div>
      <span className="font_size_26_book"> {text}</span>
    </div>
  );
};
export default IconAndText;
