import _ from 'lodash';
import { Symptom, TLanguages } from '../../../services/data';
import { translateDiagnostic } from '../../listEvolutions';
import { questionsEvaluation } from '../data/constant';
import {
  Diagnostic,
  Functionality,
  SymptomDiagnostic,
  TEvaluationType,
} from '../type';

export const extractQuestions = (evaluation) => {
  return (
    Object.entries(evaluation)
      .filter(([key]) => key.startsWith('question_'))
      // eslint-disable-next-line no-unused-vars
      .map(([key, value]) => value)
  );
};

export const returnClassAndIconFromPercentage = (
  percent: number,
  isSymptom: boolean
): { classCSS: string; icon: string } => {
  if (!isSymptom) return { classCSS: 'circle_4', icon: 'cloudWindy' };
  if (50 > percent) return { classCSS: 'circle_3', icon: 'cloud' };
  if (percent > 50 && percent <= 75)
    return { classCSS: 'circle_2', icon: 'opposition' };
  if (percent > 75) return { classCSS: 'circle_1', icon: 'lightStorm' };
};
export const constructEvaluation = (
  evaluation: any,
  evalType: TEvaluationType,
  language: TLanguages
) => {
  let result = _.cloneDeep(questionsEvaluation[language][evalType]);
  result.map((r) => {
    for (const key in evaluation) {
      // If the key starts with the current apiKey, add it to the newObj's answers object
      if (key.startsWith(r.keyApi)) {
        if (evaluation[key]) {
          r.answers[key] = evaluation[key];
        } else {
          break;
        }
      }
    }
  });
  return result;
};
export const extractCategoriesThatNeedPreconisation = (
  data: SymptomDiagnostic
): { [key: string]: string[] | [] } => {
  const firstLevelKeys = Object.keys(data);
  const arrays = {};
  for (const key of firstLevelKeys) {
    arrays[key] = [];
  }
  let category;
  for (const categoryKey in data) {
    category = data[categoryKey];
    for (const subKey in category) {
      const subObject = category[subKey];
      if (subObject.patient > subObject.adhd)
        arrays[categoryKey].push(
          subKey === 'disattention' ? 'inattention' : subKey
        );
    }
  }
  return arrays;
};
const returnDataChart = (arr, language: TLanguages) => {
  const percent = (a, max) => Number(((a / max) * 100).toFixed(2));

  const obj = [];
  for (let key in arr) {
    obj.push({
      original: key === 'disattention' ? 'inattention' : key,
      type: translateDiagnostic[language][key],
      patient: percent(
        arr[key].patient ? arr[key].patient : 1.4,
        arr[key].max ? arr[key].max : 2
      ),
      adhd: percent(
        arr[key].adhd ? arr[key].adhd : 1.2,
        arr[key].max ? arr[key].max : 2
      ),
      ...returnClassAndIconFromPercentage(
        percent(arr[key].patient, arr[key].max),
        arr[key].adhd < arr[key].patient
      ),
    });
  }
  return obj;
};
export function calculateSNAPPercentage(obj, keyArg) {
  // Get all keys of the object
  const keys = Object.keys(obj);
  const snapKeys = keys.filter((key) => key.startsWith(keyArg));
  const totalSNAPFields = snapKeys.length;
  const nonZeroSNAPFields = snapKeys.filter((key) => obj[key] !== '0').length;
  const percentageTrue = (nonZeroSNAPFields / totalSNAPFields) * 100;

  return percentageTrue;
}
export const generateDataForChart = (
  diagnosticEvaluation: Diagnostic,
  language: TLanguages
): { symptoms: Symptom[]; functionality: Functionality[] } => {
  const symptoms = returnDataChart(diagnosticEvaluation.symptom, language);
  const functionality = returnDataChart(
    diagnosticEvaluation.functionality,
    language
  );

  return { symptoms, functionality };
};
