import { css } from '@emotion/react';
import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  Title,
  Tooltip,
} from 'chart.js';
import * as React from 'react';
// eslint-disable-next-line import/no-unresolved
import { useRouter } from 'next/router';
import { secondarySpaceMarginAsGapFlex } from '../../../components/share';
import SliderComponent from '../../../components/slider/Slider';
import { IInfosProcessEvaluation, Symptom } from '../../../services/data';
import { ITheme } from '../../../services/style/type';
import { Functionality } from '../../evaluation/type';
import { IJourney } from '../../journey';
import resultEvaluationData from '../data/resultEvaluation.json';
import Chart from './Chart';
import ChartHeader from './ChartHeader';
import Description from './Description';
import DescriptionChartResultWithText from './DescriptionChartResultWithText';
import { getSessionData } from '../../../services/userSession';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
);
const componentCSS = {
  self: (props: ITheme) =>
    css({
      '& .slick-dots li.slick-active button:before': {
        color: `${props.colors.fifth_second} !important`,
      },
      '& .on_print': {
        //using display: none does not work with print for chart
        maxHeight: ' 0px',
        overflow: 'hidden',
      },
      '@media print': {
        '& .on_print': {
          maxHeight: 'none',
        },
        '& .background_slider_interpretation': {
          display: 'none',
        },
        /* styles here */
      },

      '& .background_slider_interpretation': {
        '@media (max-width: 850px)': {
          '& .slick-next, .slick-prev': {
            top: '400px !important',
          },
          '& .slick-next': {
            right: '-10px',
          },
          '& .slick-prev': {
            left: '-10px',
          },
        },

        '& svg': {
          color: '#b4b4b4',
          '@media (max-width: 700px)': {
            color: '#b4b4b4 !important',
          },
        },
      },
    }),

  key: (props: ITheme) =>
    css({
      color: props.colorsText.primary,
      fontWeight: 'bold',
      textAlign: 'justify',
      textTransform: 'capitalize',
    }),
};

export type TInterpretationChart = {
  props: {
    infosProcessEvaluation: {
      symptoms: Symptom;
      functionality?: Functionality;
    };
  };
};

const InterprationChart: React.FC<{
  props: {
    infosProcessEvaluation: IInfosProcessEvaluation[];
    journeysInformation: IJourney[];
    diagnosticsQuestions: boolean;
    handleScrollingCSS;
  };
}> = ({
  props: { infosProcessEvaluation, diagnosticsQuestions, handleScrollingCSS },
}) => {
  const { query } = useRouter();
  React.useEffect(() => {
    if (
      query.cssId &&
      handleScrollingCSS &&
      !handleScrollingCSS.avoidScrollCss
    ) {
      const element = document.getElementById(`${query.cssId}`);
      if (element) {
        element.scrollIntoView();
        handleScrollingCSS.handleSetAvoidScrollCss();
      }
    }
  }, []);
  const { locale } = useRouter();
  const { divers } = resultEvaluationData[locale];
  const filterInfosProcessEvaluation = infosProcessEvaluation.filter(
    (infos) => !infos.isUnderReview,
  );

  const { userId } = getSessionData();
  const charts = filterInfosProcessEvaluation.map((r, index) => {
    if (r.infosUser.id !== userId) return;
    return (
      <div key={index}>
        <DescriptionChartResultWithText
          diagnostics={r.interpretationResult.symptoms}
        />
        <ChartHeader informationUser={r.infosUser} />

        <Chart
          informationDiagnostic={r.interpretationResult}
          diagnosticsQuestions={diagnosticsQuestions}
          roleUser={r.infosUser.role}
        />
      </div>
    );
  });

  return (
    <div css={componentCSS.self}>
      <h2
        className="font_size_26_medium"
        style={{ marginTop: 'min(4rem, 54px)', marginBottom: '22px' }}
      >
        {divers[5]}
      </h2>
      <div className="background_slider_interpretation">
        <SliderComponent Components={charts} />
      </div>
      <div
        className="on_print flex_column"
        css={[
          secondarySpaceMarginAsGapFlex,
          { marginBottom: 'min(4rem, 75px)' },
        ]}
      >
        {/* showing only when on print */}
        {charts}
      </div>
      <Description />
      {/* <div id="comments_section">
        <CommentsCollected journeysInformation={journeysInformation},  />
      </div> */}
    </div>
  );
};
export default InterprationChart;
