import React, { useEffect } from 'react';
import errors from '../../services/data/json/errors.json';
import { useRouter } from 'next/router';
import DefaultHeader from '../headers/DefaultHeader';
import SubHeader from '../headers/SubHeader';
import MainLayout from '../layouts/mainLayout';
import { css } from '@emotion/react';
import { ITheme } from '../../services/style/type';

type Props = {
  type?: string;
};

const componentCSS = {
  self: (props: ITheme) =>
    css({
      position: 'absolute',
      top: '50%' /* position the top  edge of the element at the middle of the parent */,
      left: '50%' /* position the left edge of the element at the middle of the parent */,
      transform: 'translate(-50%, -50%)',
      '& p': {
        color: props.colorsText.primary,
        width: 'max-content',
      },
    }),
};
const ErrorComponentBoundary: React.FC<Props> = ({ type }) => {
  const { locale } = useRouter();
  const [notification, setNotification] = React.useState([]);
  useEffect(() => {
    const errorsSentence = errors[locale][type ? type : 'server'];

    setNotification(errorsSentence);
  }, [type]);

  return (
    <div>
      <DefaultHeader />
      <SubHeader />
      <MainLayout>
        <div css={componentCSS.self}>
          {notification.map((not, index) => (
            <p key={index}> {not}</p>
          ))}
        </div>
      </MainLayout>
    </div>
  );
};
export default ErrorComponentBoundary;
