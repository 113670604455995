import { css } from '@emotion/react';
import { useRouter } from 'next/router';
import React, { useState } from 'react';
import ExpendAnimationHeight from '../../../components/animations/ExpendAnimationHeight';
import CloseButton from '../../../components/buttons/CloseButton';
import CircleImage from '../../../components/elements/CircleImage';
import IconsLogos from '../../../components/elements/IconsLogos';
import NameAndAge from '../../../components/headers/elements/NameAndAge';
import FormWrapper from '../../../components/wrappers/FormWrapper';
import { returnNewFormatDateSecond } from '../../../services/common';
import { ITheme } from '../../../services/style/type';
import { getSessionData } from '../../../services/userSession';
import { evolutionsData } from '../../listEvolutions';
import evaluationsData from '../data/evaluations.json';
import AddUserProcessEvaluation from './AddUserProcessEvaluation';
import EvaluationDate from './elements/EvaluationDate';
import EvaluationProgress from './elements/EvaluationProgress';
import ShareUserInfoOnEvaluation from './elements/ShareUserInfosOnEvaluation';

const componentCSS = {
  self: (props: ITheme) => css`
    .input_form input {
      border: 1px solid #939191b8;
    }
    .formWrapper {
      max-height: 98%;
    }

    .evaluation_date {
      gap: 0.2rem !important;
      margin-left: -2rem;
    }
    .evaluation_date .separator {
      margin-left: 0rem;
      margin-right: 0rem;
    }

    @media (min-width: 400px) {
      .evaluation_date .separator {
        margin-left: 0.5rem;
        margin-right: 0.5rem;
      }
    }

    @media (max-width: 400px) {
      .evaluation_date {
        gap: 0.5rem !important;
        margin-left: -1rem;
      }
    }

    p {
      margin: 0;
    }
    .evaluation_header_share {
      border-bottom: 1px solid #e3e3e3;
      padding-bottom: 20px;
      padding-top: 20px;
    }

    .formWrapper {
      max-width: min(95%, 800px);
    }

    svg {
      width: min(3.47vw, 25px);
      height: min(3.47vw, 25px);
    }
    .circle_image {
      height: 40px;
      width: 40px;
    }
    .two_men_svg svg path {
      fill: ${props.background.third};
    }
    .CircularProgressbar {
      width: max-content;
    }
    .evaluation_progress {
      justify-content: flex-end;
    }
    .men_v2_svg svg path {
      fill: grey;
    }
    .close_button {
      border: none;
      background-color: transparent;
      font-size: 1.8rem;
    }
  `,
  title: css`
    text-align: center;
    padding: 2rem;
    background-color: #ff8f56;
    color: white !important;
    margin-top: -2.2rem;
    margin-left: -2rem;
    margin-right: -2rem;
  `,
  buttonShare: (props: ITheme) => css`
    color: ${props.colors.fourth_first};
    gap: 21px;
    svg,
    span {
      fill: ${props.colors.fourth_first};
      color: ${props.colors.fourth_first};
    }
    margin-right: 25px;
  `,
  body: css`
    align-items: flex-start;
  `,
};
const ShareEvaluation: React.FC<any> = ({
  handleDisplayNewClient,
  refetch,
  processEvaluationId,
  date,
  evaluationType,
  listUsers,
  resultGetStudentProfile,
}) => {
  const [doWeAdd, setDoWeAdd] = useState(false);
  const { locale } = useRouter();
  const { userId } = getSessionData();
  const actualUserInfos = listUsers.filter((user) => user.id === userId);
  const cancelNewUser = () => setDoWeAdd(false);
  return (
    <div css={componentCSS.self}>
      <FormWrapper>
        <h1 className="font_size_22_medium" css={componentCSS.title}>
          {evaluationsData[locale].text[8]}{' '}
        </h1>
        <CloseButton functionToClose={handleDisplayNewClient} color={''} />
        <div className="wrapper_sub">
          <div className="evaluation_header_share">
            <div className="flex_row_btw_center" css={componentCSS.body}>
              <div
                className="part_1 flex_align_center_no_wrap"
                style={{ gap: '10px' }}
              >
                <CircleImage
                  src={
                    resultGetStudentProfile.data.image
                      ? resultGetStudentProfile.data.image
                      : ''
                  }
                  width={null}
                  height={null}
                  alt={''}
                />

                <NameAndAge
                  name={resultGetStudentProfile.data.name}
                  surname={resultGetStudentProfile.data.surname}
                  dateOfBirth={returnNewFormatDateSecond(
                    resultGetStudentProfile.data.dateOfBirth,
                  )}
                  ageLabel={evolutionsData[locale].header.age}
                />
              </div>
              <EvaluationDate
                date={date ? date : ''}
                evaluationType={evaluationType}
              />
            </div>
            <EvaluationProgress
              percentage={
                actualUserInfos &&
                actualUserInfos.length &&
                actualUserInfos[0].percentageEval
                  ? 100
                  : 0
              }
            />
          </div>
          <ShareUserInfoOnEvaluation listUsers={listUsers} refetch={refetch} />
          <div className="flex_end">
            <button
              className="button_no_decor hover_cursor flex_align_center_no_wrap"
              onClick={() => setDoWeAdd(!doWeAdd)}
              css={componentCSS.buttonShare}
            >
              <span className="font_size_22_medium share_word">
                {evaluationsData[locale].text[13]}
              </span>
              <IconsLogos type={'share'} />
            </button>
          </div>

          <ExpendAnimationHeight isOpen={doWeAdd}>
            <AddUserProcessEvaluation
              hideForm={handleDisplayNewClient}
              refetch={refetch}
              processEvaluationId={processEvaluationId}
              evaluationType={evaluationType}
              cancelNewUser={cancelNewUser}
            />
          </ExpendAnimationHeight>
        </div>
      </FormWrapper>
    </div>
  );
};
export default ShareEvaluation;
