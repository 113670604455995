import { css } from '@emotion/react';
import * as React from 'react';
import PerceptionCircle from './PerceptionCircle';
import { IPerceptionCircle } from '../type';

const columnsMobile = 2;
const columnsDesktop = 5;
const componentCSS = {
  circles: css(` 
  display: flex;
  align-items: center;
  justify-content: center;
	`),
  grid: (elem) => css`
    border-left: 1px solid #cccaca;
    border-bottom: 1px solid #cccaca;
    display: grid;
    grid-template-columns: repeat(${columnsMobile}, 1fr);
    grid-template-rows: repeat(${elem}, 1fr);
    gap: 5px;
    @media (min-width: 500px) {
      grid-template-columns: repeat(${columnsDesktop}, 1fr);
    }
  `,
  childGrid: (row: number) => css`
    grid-column: ${row == 0
      ? 2
      : Math.floor(Math.random() * columnsMobile + 1)};
    grid-row: ${row + 1};
    padding: 0.5rem;
    @media (min-width: 500px) {
      grid-column: ${Math.floor(Math.random() * columnsDesktop + 1)};
    }
  `,
};
type TProps = {
  props: {
    perception: IPerceptionCircle[];
  };
};

const PerceptionsCircles: React.FC<TProps> = ({ props: { perception } }) => {
  if (!perception) return;
  return (
    <div css={componentCSS.circles} id={'perception_circles'}>
      <div css={componentCSS.grid(perception.length)}>
        {perception.map((per, index) => (
          <div
            key={index}
            css={componentCSS.childGrid(index)}
            className="perception"
          >
            <PerceptionCircle
              icon={per.original}
              type={per.type}
              classCSS={per.classCSS}
              original={per.original}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default PerceptionsCircles;
