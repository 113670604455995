import { css } from '@emotion/react';
import _ from 'lodash';
import Image from 'next/image';
import React, { useState } from 'react';
import FormDefault from '../../forms/components/FormDefault';
import CardLoading from '../../../components/utils/CardLoading';
import customiseData from '../data/customiseData.json';

type Props = {
  image: { data_url: string };
  userId: string;
  locale: string;
  handleReturnSaveImageAvatar: () => void;
  handleDisplayLaptop: () => void;
};

const componentCSS = {
  self: css`
     min-height: 600px;
      img {
				width: 100%;
				flex: 1;
			}
			.image_container {
				 position: relative;
      min-height: 600px;
				width: 100%;
				height:100%;
			}
				.image_container img {
					position: absolute;
					height: 100%;
					width: 100%;
				}
      }
			
    `,
};

const Banners: React.FC<Props> = ({
  image,
  userId,
  locale,
  handleReturnSaveImageAvatar,
  handleDisplayLaptop,
}) => {
  const [loading, setLoading] = useState(true);
  let copySaveDataGeneratorImage = _.cloneDeep(
    customiseData[locale].saveDataGeneratorImage
  );
  copySaveDataGeneratorImage.fields[0].defaultValue = userId;
  copySaveDataGeneratorImage.fields[1].defaultValue = 'banner';
  copySaveDataGeneratorImage.fields[2].defaultValue = {
    ...image,
    isAPrivateLink: true,
  };

  return (
    <div data-testid="banners" css={componentCSS.self} className="flex_column">
      {image && (
        <>
          <div className="image_container" style={{ position: 'relative' }}>
            <Image
              src={image ? image.data_url : ''}
              alt={''}
              fill={true}
              onLoadingComplete={() => {
                setLoading(false);
                handleDisplayLaptop();
              }}
            />
            <div
              style={{
                width: '100%',
                height: '100%',
                position: 'absolute',
                top: 0,
                left: 0,
              }}
            >
              {loading && <CardLoading />}
            </div>
          </div>
          <FormDefault
            {...copySaveDataGeneratorImage}
            refetch={handleReturnSaveImageAvatar}
          />
        </>
      )}
    </div>
  );
};
export default Banners;
