import { css } from '@emotion/react';
import { useRouter } from 'next/router';
import * as React from 'react';
import { useState } from 'react';
import { formsData } from '../..';
import { routesIn } from '../../../../services/bridge';
import { ITheme } from '../../../../services/style';
import { TFormField } from '../../type';
import { errorCSS } from './sharedCSS';

type Props = {
  data: TFormField;
};

const componentCSS = {
  label: (props: ITheme) =>
    css({
      color: props.colorsText.fourth,
      '& p': {
        '&::first-letter': {
          textTransform: 'capitalize',
        },
      },
    }),
  input: (props: ITheme) =>
    css({
      marginTop: '.2rem',
      borderRadius: `${props.extra.border_radius}px`,
      border: '1px solid grey',
      padding: '.5rem',
    }),
};

const CheckBox: React.FC<Props> = ({
  data: {
    register,
    name,
    errors,
    placeHolder,
    secretField,
    defaultValue,
    updateValue,
  },
}) => {
  const { locale } = useRouter();
  const { agreement } = formsData[locale];
  React.useEffect(() => {
    setChecked(defaultValue as boolean);
    updateValue('authorisationEmailing', defaultValue);
  }, [defaultValue]);

  const [checked, setChecked] = useState(false);
  function handleChange(event) {
    setChecked(event.target.checked);
  }

  return (
    <div
      className="flex_column check_box_wrapper"
      css={{ display: secretField ? 'none' : 'flex' }}
    >
      <div className="flex_align_center checkbox_input" css={{ gap: '5px' }}>
        <input
          className="font_size_26_bold"
          aria-labelledby={'checkbox'}
          type="checkbox"
          checked={checked}
          name={name}
          {...register}
          onChange={(event) => {
            register.onChange(event);
            handleChange(event);
          }}
          css={componentCSS.input}
          data-testid={`form-${name}-testID`}
          aria-invalid={errors[name] ? errors[name].message : ''}
          placeholder={placeHolder}
        />
        {name === 'authorisationEmailing' ? (
          <p> {agreement.text[4]}</p>
        ) : name === 'agreement' ? (
          <p> {agreement.text[5]}</p>
        ) : (
          <label css={componentCSS.label} key={name}>
            <p>
              {agreement.consentForm.paragraph[3]}&nbsp;
              <a
                target="_blank"
                style={{ color: 'blue', textDecoration: 'underline' }}
                href={routesIn.eng.politicOfPrivacy[locale].link}
                rel="noopener noreferrer"
              >
                {agreement.text[4]}.
              </a>
            </p>
          </label>
        )}
      </div>
      <span css={errorCSS} className="font_size_22_light">
        {errors[name] ? errors[name].message : ''}
      </span>
    </div>
  );
};
export default CheckBox;
