import { css } from '@emotion/react';
import * as React from 'react';
import IconsLogos from '../elements/IconsLogos';

type TButtonOriginal = {
  icon: string;
  functionToApply?: () => void;
};
const componentCSS = {
  self: css`
    position: fixed;
    width: max-content;

    bottom: 6rem;
    right: 2vw;
  `,
  button: css(
    `
position: relative;
.plus_character {
font-size: 90px !important;
position: absolute;
top: 50%;
left: 50%;
transform: translate(-50%, -50%);

color: white;
}


svg {

	width: 5rem;
height: 5rem;

 @media (max-width: 400px) {
	width: 3rem;
height: 3rem;
	}
}

`,
  ),
};
const ButtonOriginal: React.FC<TButtonOriginal> = ({
  icon,
  functionToApply,
}) => {
  return (
    <div css={componentCSS.self} className="self_button_original">
      <button
        onClick={() => {
          if (functionToApply) functionToApply();
        }}
        css={componentCSS.button}
        className="button_no_decor plus_button"
        aria-labelledby="button plus"
      >
        <IconsLogos type={icon} />
      </button>
    </div>
  );
};
export default ButtonOriginal;
