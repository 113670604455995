import { useQuery } from 'react-query';

import axios from 'axios';
import { routesOut } from '../../../services/bridge';
import { TEvaluationType } from '../type';

export const getEvaluation = (
  patientId: String,
  processEvaluationId: string,
  evalType: TEvaluationType,
  userId: string | null,
) => {
  return useQuery(
    ['getEvaluation', patientId],
    async () => {
      const response = await axios.get(
        routesOut.data.getEvaluation(
          processEvaluationId as string,
          evalType,
          userId,
        ).link,
      );

      return response.data;
    },
    { enabled: !!patientId && !!processEvaluationId && !!userId },
  );
};

export const getInformationsOfAProcessEvaluation = (
  processEvaluationId: string,
  evaluationType: TEvaluationType,
  userId: string,
  doWeReturnAllEvaluation: boolean,
) => {
  return useQuery(
    ['evaluations', processEvaluationId],
    async () => {
      const response = await axios.get(
        routesOut.data.getInformationsOfAProcessEvaluation(
          processEvaluationId,
          evaluationType,
          userId,
          doWeReturnAllEvaluation,
        ).link,
      );
      return response.data;
    },
    { enabled: !!processEvaluationId && !!evaluationType },
  );
};
