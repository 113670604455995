import {
  returnAgeFromBirthDay,
  returnInitialNames,
} from '../../../services/common';

const NameAndAge = ({ name, surname, dateOfBirth, ageLabel }) => {
  return (
    <div className="flex_column_start_align name_and_age">
      <h4 className="font_size_26_medium" style={{ margin: '.2rem' }}>
        {returnInitialNames(name, surname)}
      </h4>
      <span
        className="font_size_22_light nameAndAge_age"
        style={{ paddingRight: '.2rem', whiteSpace: 'nowrap' }}
      >
        {returnAgeFromBirthDay(dateOfBirth)} {ageLabel}
      </span>
    </div>
  );
};

export default NameAndAge;
