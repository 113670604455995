import { css, useTheme } from '@emotion/react';
import * as React from 'react';
import { Bar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { colorDiagnostic } from '../../evaluation/components/PerceptionCircle';
import { ITheme } from '../../../services/style/type';
import { IInformationDiagnostic } from '../type';
import { options } from './Options';
import resultEvaluationData from '../data/resultEvaluation.json';
import { useRouter } from 'next/router';

const componentCSS = {
  self: css`
    @media print {
      .bars_wrapper {
        max-width: 600px !important;
      }
    }
  `,
};

const Chart: React.FC<{
  informationDiagnostic: IInformationDiagnostic;
  diagnosticsQuestions: boolean;
  roleUser?: string;
}> = ({ informationDiagnostic, diagnosticsQuestions }) => {
  const keys = ['functionality', 'symptoms'];
  let datas = [];
  if (diagnosticsQuestions == undefined) return;
  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
  );
  for (let i = 0; i < keys.length; i++) {
    if (!diagnosticsQuestions && keys[i] === 'functionality') continue;
    datas.push({
      labels: informationDiagnostic[keys[i]].map(
        (da) =>
          da.type.charAt(0).toUpperCase() + da.type.slice(1).toLowerCase(),
      ),
      datasets: [
        {
          label: '',
          data: informationDiagnostic[keys[i]].map((da) => da.patient),
          backgroundColor: informationDiagnostic[keys[i]].map((da) =>
            da.patient > da.adhd
              ? colorDiagnostic[da.original]
              : colorDiagnostic.neutral,
          ),
          barThickness: 60,
          order: 2,
        },
        {
          label: '',
          order: 1,
          data: informationDiagnostic[keys[i]].map((da) => da.adhd),
          barThickness: 80,
          borderColor: 'grey',
          backgroundColor: 'transparent',
          borderWidth: 1,
        },
      ],
    });
  }
  //@ts-expect-error
  const theme: ITheme = useTheme();
  const { locale } = useRouter();

  return (
    <div css={componentCSS.self}>
      {process.env.NEXT_PUBLIC_DISPLAY_FUNCTIONALITY_BAR === 'true' ? (
        datas.map((data, index) => (
          <div
            key={index}
            style={{
              height: '40vh',
              maxHeight: '300px',
              maxWidth: '730px',
              margin: 'auto',
            }}
          >
            <Bar options={options(theme.background.fifth, index)} data={data} />
            <p className="font_size_24_light_italic">
              {resultEvaluationData[locale].diagnostic[10]}{' '}
            </p>
          </div>
        ))
      ) : (
        <div
          className="bars_wrapper"
          style={{
            maxWidth: '730px',
            margin: 'auto',
          }}
        >
          <div
            style={{
              height: '40vh',
              maxHeight: '300px',
              maxWidth: '630px',
              margin: 'auto',
            }}
          >
            <Bar options={options(theme.background.fifth, 0)} data={datas[0]} />
          </div>
          {resultEvaluationData[locale].howToUnderstandThisChart.map(
            (text, index) => (
              <p
                className={
                  index === 0 ? 'font_size_24_medium' : 'font_size_24_light'
                }
                key={index}
              >
                {text}
              </p>
            ),
          )}
          <p className="font_size_24_bold" style={{ fontStyle: 'italic' }}>
            {resultEvaluationData[locale].diagnostic[10]}
          </p>
        </div>
      )}
    </div>
  );
};

export default Chart;
