import { useSession } from 'next-auth/react';
import Loader from 'react-spinners/CircleLoader';
import React, { ReactElement } from 'react';
import { dataTestID } from '../../../services/data/constant';

export interface ChildrenComponent {
  children: ReactElement;
}
const Auth: React.FC<ChildrenComponent> = ({ children }) => {
  const { status } = useSession({ required: true });
  if (status === 'loading') {
    return (
      <div data-testid={dataTestID.loader}>
        <Loader />
      </div>
    );
  }

  return children;
};

export default Auth;
