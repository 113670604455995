import { css } from '@emotion/react';
import { ITheme, themeStyle } from '../../../../services/style';

const labelCSS = (props: ITheme) =>
  css({
    color: props.colorsText.primary,
    marginTop: '0.5rem',
    marginBottom: '.2rem',
    '&::first-letter': {
      textTransform: 'uppercase',
    },
  });

const errorCSS = (props: ITheme) =>
  css({
    color: `${props.colorsText.error} !important`,
    '&::first-letter': {
      textTransform: 'uppercase',
    },
  });

const successCSS = (props: ITheme) =>
  css({
    color: `${props.colors.fourth_first} !important`,
    fontWeight: 'bold',
    '&::first-letter': {
      textTransform: 'uppercase',
    },
  });
const inputLogin = {
  backgroundColor: 'white',
  borderRadius: '10px',
  padding: '.3rem',
  paddingTop: '.5rem',
  paddingBottom: '.5rem',
  marginTop: '.5rem',
  marginBottom: '.3rem',
  width: '100%',
};

const windowPopUpCSS = (props: ITheme) =>
  css({
    position: 'absolute',
    top: '0',
    backgroundColor: 'white',
    bottom: 0,
    margin: 'auto',
    left: 0,
    right: 0,
    width: 'max-content',
    height: 'max-content',
    padding: '2rem',
    minWidth: '17rem',
    zIndex: 400,
    boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px',
    '& h2': {
      color: props.colorsText.fourth,
      textAlign: 'center',
      '&::first-letter': {
        textTransform: 'uppercase',
      },
    },
  });

// !refactor  => single source of truth for width of Calendar, input, multiSelection
// If width is modified, do not forget to modify: Calendar input, multiSelection.
const inputCSS = (props: ITheme) =>
  css({
    marginTop: '.2rem',
    borderRadius: `${props.extra.border_radius}px`,
    border: '1px solid',
    borderColor: ' hsl(0, 0%, 80%)',
    padding: '1rem',
    minWidth: 'max-content',
    width: '80%',
    margin: 'auto',
    color: ` ${props.colorsText.primary} !important`,
    '@media (min-width: 550px)': {
      minWidth: 'max-content',
    },
  });

const customStylesSelection = {
  container: (base) => ({
    ...base,
    justifyContent: 'center',
    display: 'flex',
    position: 'relative',
  }),
  singleValue: (provided) => {
    return {
      ...provided,
      '&::first-letter': {
        textTransform: 'uppercase',
      },
      fontFamily: 'Light',
      fontSize: '22px',
      minWidth: 'max-content',
      width: '80%',
      margin: 'auto',
      color: themeStyle.colorsText.primary,
      '@media (min-width: 550px)': {
        minWidth: 'max-content',
      },
    };
  },
  option: (provided, state) => {
    return {
      ...provided,
      backgroundColor: state.isSelected ? '#bcbcbc' : 'white',
      color: state.isSelected ? 'white' : themeStyle.colorsText.primary,
      '&::first-letter': {
        textTransform: 'uppercase',
      },
    };
  },
  menu: (base) => ({
    ...base,
    minWidth: 'max-content',
    width: '80%',
    textAlign: 'center',
    display: 'block',
    justifyContent: 'center',
    position: 'absolute',
    '@media (min-width: 550px)': {
      minWidth: 'max-content',
    },
  }),
  control: (base) => ({
    ...base,
    minHeight: 10,
    borderRadius: '5px',
    width: '80%',
    minWidth: 'max-content',
    margin: 'auto',
    '@media (min-width: 550px)': {
      minWidth: 'max-content',
    },
    '& input': {
      color: 'red',
      '&::first-letter': {
        textTransform: 'uppercase',
      },
    },
  }),

  placeholder: (defaultStyles) => {
    return {
      ...defaultStyles,
      fontFamily: 'Light',
      fontSize: '18px',

      color: themeStyle.colorsText.primary,
      '&::first-letter': {
        textTransform: 'uppercase',
      },
    };
  },
};

const roleStyleSelect = {
  option: (provided, state) => {
    return {
      ...provided,
      backgroundColor: state.isSelected ? '#bcbcbc' : 'white',

      color: state.isSelected ? 'white' : 'black',
      '&::first-letter': {
        textTransform: 'uppercase',
      },
    };
  },
  menu: (base) => ({
    ...base,
    minWidth: 'max-content',
    width: '80%',

    textAlign: 'center',
    display: 'block',
    justifyContent: 'center',
    '@media (min-width: 550px)': {
      minWidth: 'max-content',
    },
  }),
  singleValue: (provided) => {
    return {
      ...provided,
    };
  },
  indicatorSeparator: () => ({ display: 'none' }),
  control: (base) => ({
    ...base,
    border: 'none',
    background: 'transparent',
    color: 'white',
    minHeight: 10,
    borderRadius: '5px',
    width: '80%',
    minWidth: 'max-content',
    margin: 'auto',
    padding: '1rem',
    '@media (min-width: 550px)': {
      minWidth: 'max-content',
    },
    '& input': {
      '&::first-letter': {
        textTransform: 'uppercase',
      },
    },
  }),
};

export {
  labelCSS,
  errorCSS,
  successCSS,
  inputLogin,
  windowPopUpCSS,
  inputCSS,
  customStylesSelection,
  roleStyleSelect,
};
