/* eslint-disable no-unused-vars */

import { TEvaluationType } from '../../features/evaluation';

type TResult = {
  state: boolean;
  data: any;
  status: number;
  error: boolean;
};
type TOptionsAxios = {
  time: number;
  cancelToken: any;
  params?: any;
};

interface IErrorReturn {
  status?: number;
  error: boolean;
  message: string;
}

enum ERoles {
  admin = 'admin',
  guest = 'guest',
  teacher = 'teacher',
  parent = 'parent',
  professional = 'professional',
  restricted = 'restricted',
  student = 'student',
  gp = 'gp',
  family = 'family',
  parentGuardian = 'parent/guardian',
  partner = 'partner',
  specialist = 'specialist',
  teacherAssistant = 'teacher assistance',
  other = 'other',
}
type ERolesArray = Array<keyof typeof ERoles>;

interface ILinkRole {
  link: string;
  rolesAuthorised: Partial<ERoles>[];
}
type TRoutesIn = {
  login: ILinkRole;
  home: ILinkRole;
  students: ILinkRole;
  contact: ILinkRole;
  logOut: ILinkRole;
  signIn: ILinkRole;
  tutorial: ILinkRole;
  customise: {
    rolesAuthorised: Partial<ERoles>[];
    link: string;
    path: string;
    avatar: ILinkRole;
    banners: ILinkRole;
    strap: ILinkRole;
  };
  student: {
    rolesAuthorised: Partial<ERoles>[];
    path: string;
    id: (id) => any;
    evaluation: (
      patientId: string,
      evaluationId: string,
      evalType: string,
    ) => any;
    journey: (patientId: string, evaluationId: string, evalType: string) => any;
    interpretation: (
      patientId: string,
      evaluationId: string,
      evalType: TEvaluationType,
    ) => any;
    preconisation: (
      studentId: string,
      processEvaluationId: string,
      evalType: TEvaluationType,
    ) => any;
    evolution: (
      studentId: string,
      processEvaluationId: string,
      evalType: TEvaluationType,
    ) => any;
  };
  admin: ILinkRole;
  notification: ILinkRole;
  agreement: ILinkRole;
  form: ILinkRole;
  securityCheck: ILinkRole;
  politicOfPrivacy: {
    fr: ILinkRole;
    eng: ILinkRole;
    ptbr: ILinkRole;
    es: ILinkRole;
    zh: ILinkRole;
  };

  glossary: {
    fr: ILinkRole;
    eng: ILinkRole;
    ptbr: ILinkRole;
    es: ILinkRole;
    zh: ILinkRole;
  };
};
interface RoutesIn {
  roles: ERoles[];
  eng: TRoutesIn;
}
interface RoutesOut {
  roles: ERoles[];
  data: {
    path: string;
    rolesAuthorised: Partial<ERoles>[];
    isUserAlreadyRegistered: (email: string) => ILinkRole;
    getStudentEvolutionDiagnostics: (studentId: string) => ILinkRole;
    getAdminDataTable: (pageIndex: string, pageSize: string) => ILinkRole;
    getPatients: (userId: string) => ILinkRole;
    getNotifications: (userId: string) => ILinkRole;
    getUserDetails: (userId: string) => ILinkRole;
    getListProcessEvaluationsOfAPatientWithUsersLinked: (
      id: string,
      userId: string,
    ) => ILinkRole;
    getPatientProfile: (id: string, userId: string) => ILinkRole;
    getJourney: (patientId: string, userId: string) => ILinkRole;
    getComments: (userId: string, processEvaluationId: string) => ILinkRole;
    getPreconisationsEndPointURL: (
      type: string,
      role: string,
      language: string,
    ) => ILinkRole;
    getSpecificPatients: (id: string) => ILinkRole;
    getEvaluation: (
      id: string,
      typeEvaluation: TEvaluationType,
      userId: string,
    ) => ILinkRole;
    getSchools: (
      countriesID: (number | string)[] | null,
      userId: string | null,
    ) => ILinkRole;
    getClasses: (
      schoolID: (number | string)[] | null,
      userId: string,
    ) => ILinkRole;
    getUrlS3: (typeFile: string, fileName: string) => ILinkRole;
    getCountries: ILinkRole;
    postEvaluationProcess: ILinkRole;
    getInformationsOfAProcessEvaluation: (
      processEvaluationId: string,
      evaluationType: string,
      userId: string,
      doWeReturnAllEvaluation: boolean,
    ) => ILinkRole;
    test: ILinkRole;
    newPatient: ILinkRole;
    updateJourneyPrivilege: ILinkRole;
    updatePrivileges: ILinkRole;
    updateDataBase: ILinkRole;
    saveColorHeaderFooter: ILinkRole;
    submitStrap: ILinkRole;
    characteristicAvatar: ILinkRole;
    saveDataGeneratorImage: ILinkRole;
    updateNotificationView: ILinkRole;
    requestImageApiGenerator: ILinkRole;
    updatePreconisationMetrics: ILinkRole;
    newCommentJourney: ILinkRole;
    newProcessEvaluation: ILinkRole;
    newSchool: ILinkRole;
    newClass: ILinkRole;
    newUserToProcessEvaluation: ILinkRole;
    newComment: ILinkRole;
    newJourney: ILinkRole;
    newRestrictedUser: ILinkRole;
    newConsentForm: ILinkRole;
    userInscription: ILinkRole;
    updateUserDetails: ILinkRole;
    updateAuthorisationEmailing: ILinkRole;
    confirmAccount: ILinkRole;
    askForANewPassowrd: ILinkRole;
    defineTheNewPassword: ILinkRole;
  };
  moreInfo: ILinkRole;
  aws: {
    buckets: {
      images: string;
      videosPreconisation: string;
    };
  };
}

type URLKey = 'forms/newUser';

export { ERoles };
export type {
  URLKey,
  RoutesOut,
  RoutesIn,
  ILinkRole,
  IErrorReturn,
  TOptionsAxios,
  TResult,
};
