import * as React from 'react';
import { ITheme } from '../../services/style/type';
import { css } from '@emotion/react';

const componentCSS = (colorPage: string) => {
  return {
    self: (props: ITheme) =>
      css({
        borderRadius: `${props.extra.border_radius}px`,

        '& button': {
          width: 'max-content ',
          backgroundColor: colorPage,
        },
      }),
  };
};
type TBannerTwo = {
  text: string[];
  colorPage: string;
};

const BannerTwo: React.FC<TBannerTwo> = ({ text, colorPage }) => {
  return (
    <div
      css={componentCSS(colorPage).self}
      className="flex_column_center_center"
    >
      <h3>{text[0]}</h3>
    </div>
  );
};
export default BannerTwo;
