import { css } from '@emotion/react';
import { signOut } from 'next-auth/react';
import { useRouter } from 'next/router';
import * as React from 'react';
import OpacityAnimation from '../../../components/animations/Opacity';
import IconsLogos from '../../../components/elements/IconsLogos';
import OutsideAlerter from '../../../components/utils/ComponentVisible';
import { ITheme } from '../../../services/style/type';
import userData from '../data/user.json';
import UpdateUserDetails from './UpdateUserDetails';
const componentCSS = {
  self: css`
    position: relative;
    gap: 10px;
    svg {
      font-size: 2.5rem;
      color: white;
    }
  `,
};
const componentCSSSubMenu = {
  self: (props: ITheme) =>
    css(
      `
	gap: min(4dvh, 33px);
	 padding-top: 37px;
	 padding-bottom: 37px;
	 padding-left:33px;
	 padding-right:33px;
	 position: absolute;
	 min-width:272px;
   top: 4rem;
   right: -30px;
   background-color: white;
   color: ${props.colorsText.primary}; /* replace with props.colorsText.primary */
   border-radius: 0 0 0 ${props.extra.border_radius}px; /* replace with props.extra.border_radius */
   overflow: hidden;
   box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
   z-index: 1000;

  p {
   font-weight: 600 !important;
 }

  .icon_text {
   padding: 0 !important;
 }

  svg {
   color: rgba(0, 0, 0, 0.54);
   fill: rgba(0, 0, 0, 0.54);
 }`,
    ),
  menu: (props: ITheme) =>
    //@ts-ignore
    css(`
  width: 100%;


span::hover {
  cursor: pointer;
  background-color: ${props.background.fourth}; /* replace with props.background.fourth */
}

span {
  margin: 0;
  text-align: center;
  color: rgba(0, 0, 0, 0.54);
  text-transform: initial !important;
  font-weight: bold;
	white-space: nowrap; 
}

span::first-letter {
  text-transform: uppercase !important;
}`),
};

interface MenuHandler {
  // eslint-disable-next-line no-unused-vars
  (val: number): void;
}

const SubMenuUser: React.FC<{ handleSetMenu: MenuHandler }> = ({
  handleSetMenu,
}) => {
  const { locale } = useRouter();
  const { text, menus } = userData[locale];

  const menusComponent = [
    <div key={0} className="flex_align_center_no_wrap" style={{ gap: '25px' }}>
      <div style={{ marginRight: 'auto' }}>
        <IconsLogos type={'edit'} />
      </div>
      <span
        className="font_size_26_book"
        style={{ marginLeft: 0, marginRight: 'auto', fontWeight: 'normal' }}
      >
        {text[2]}
      </span>
    </div>,
    <div
      key={1}
      onKeyDown={(event) => {
        if (event.key === 'Enter') signOut();
      }}
      className="flex_align_center_no_wrap"
      style={{ gap: '25px' }}
      role="button"
      tabIndex={0}
      onClick={() => signOut()}
    >
      <div>
        <IconsLogos type={'exit'} />
      </div>

      <span
        className="font_size_26_book"
        style={{ marginLeft: 0, marginRight: 'auto', fontWeight: 'normal' }}
      >
        {menus[8].text}
      </span>
    </div>,

    // <LogOut key={1} />,
  ];
  return (
    <div css={componentCSSSubMenu.self} className="flex_column">
      {menusComponent.map((menu, index) => (
        <div
          key={index}
          css={componentCSSSubMenu.menu}
          onKeyDown={(event) => {
            if (event.key === 'Enter') {
              handleSetMenu(index);
            }
          }}
          role="button"
          tabIndex={0}
          onClick={() => {
            handleSetMenu(index);
          }}
        >
          {menu}
        </div>
      ))}
    </div>
  );
};
const MenuUserConnected: React.FC = () => {
  const [displayMenu, setDisplayMenu] = React.useState(false);
  // const { data } = useSession();
  const [menuComponent, setMenu] = React.useState(-1);
  const handleSetMenu = (val: number) => setMenu(val);
  // @ts-ignore
  // const userId = data && data.user ? data.user.id : null;
  // const userDetails = getUserDetails(userId);
  // const user = returnDetailsUser(data, userDetails);

  const handleA = () => {
    setDisplayMenu(false);
  };
  const components = [
    <UpdateUserDetails
      key={0}
      hideForm={() => {
        setDisplayMenu(false);
        setMenu(-1);
      }}
    />,
  ];

  return (
    <div css={componentCSS.self} data-testid="menu-user-connected">
      <button
        className="button_no_decor"
        onKeyDown={(event) => {
          if (event.key === 'Enter') setDisplayMenu(!displayMenu);
        }}
        onClick={() => {
          setDisplayMenu(!displayMenu);
        }}
      >
        <IconsLogos type={'dots'} />
      </button>
      <OutsideAlerter functionToApply={handleA}>
        <OpacityAnimation isOpen={displayMenu && menuComponent == -1}>
          <SubMenuUser handleSetMenu={handleSetMenu} />
        </OpacityAnimation>
      </OutsideAlerter>

      <div> {components[menuComponent]}</div>
    </div>
  );
};

export default MenuUserConnected;
