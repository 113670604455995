import React, { useEffect } from 'react';
import { Controller } from 'react-hook-form';
import Select, { components } from 'react-select';
import { TFormField } from '../../type';
import { errorCSS, labelCSS } from './sharedCSS';
import { evaluationData } from '../../../evaluation';
import { useRouter } from 'next/router';

type Props = {
  data: TFormField;
};
// const transformSelectedOptions = (options, selectedOptions) => {
//   const transformed = {};
//   options.forEach((option) => {
//     transformed[option.value] = selectedOptions.some(
//       (selected) => selected.value === option.value,
//     );
//   });
//   return transformed;
// };
const CustomOption = (props) => {
  return (
    <components.Option {...props}>
      <div>
        <input
          type="checkbox"
          checked={props.isSelected}
          onChange={() => null}
        />{' '}
        <label className="font_size_16_book">{props.label}</label>
      </div>
    </components.Option>
  );
};

const MultiselectionCheckbox: React.FC<Props> = ({
  data: { label, options, errors, name, control, defaultValue, updateValue },
}) => {
  useEffect(() => {
    if (defaultValue) {
      updateValue(name, {
        label: options[defaultValue].label,
        value: options[defaultValue].value,
        index: defaultValue,
      });
    }
  }, []);

  const ValueContainer = ({ children, getValue, ...props }) => {
    var values = getValue();
    var valueLabel = '';

    if (values.length > 0)
      valueLabel += props.selectProps.getOptionLabel(values[0]);
    if (values.length > 1) valueLabel += ` & ${values.length - 1} more`;

    // Keep standard placeholder and input from react-select
    var childsToRender = React.Children.toArray(children).filter(
      (child) =>
        //@ts-ignore
        ['Input', 'DummyInput', 'Placeholder'].indexOf(child.type.name) >= 0,
    );

    return (
      //@ts-ignore
      <components.ValueContainer {...props}>
        {!props.selectProps.inputValue && valueLabel}
        {childsToRender}
      </components.ValueContainer>
    );
  };
  const { locale } = useRouter();
  return (
    <div className="multiselectionCheckBox">
      <label className="font_size_26_medium" htmlFor={label} css={labelCSS}>
        {label}
      </label>
      <Controller
        name={name}
        render={({ field }) => {
          if (!field.value && defaultValue) {
            if (/^\d+$/.test(defaultValue)) {
              field.value = {
                label: options[defaultValue].label,
                value: options[defaultValue].value,
                index: defaultValue,
              };
            } else {
              field.value = { label: defaultValue, value: defaultValue };
            }
          }
          const handleChange = (selectedOptions) => {
            if (!selectedOptions) {
              field.onChange(null);
            } else {
              // Transform selected options array into object format
              const transformedValue = selectedOptions.map((option) => ({
                label: option.label,
                value: option.value,
                index: options.findIndex((o) => o.value === option.value),
              }));
              field.onChange(transformedValue);
            }
          };

          return (
            <div className="flex_column">
              <Select
                closeMenuOnSelect={false}
                hideSelectedOptions={false}
                isMulti
                options={options}
                menuPlacement="top"
                components={{ Option: CustomOption, ValueContainer }}
                onChange={handleChange}
                ref={field.ref}
                // menuIsOpen={true}
                menuPortalTarget={document.body}
                menuPosition={'fixed'}
                classNamePrefix="multiselectionCheckBox_select"
                placeholder={evaluationData[locale].selects.default}
              />
              <span
                className="font_size_22_light"
                css={errorCSS}
                style={{ textAlign: 'left' }}
              >
                {errors[name] ? errors[name].message : ''}
              </span>
            </div>
          );
        }}
        control={control}
      />
    </div>
  );
};
export default MultiselectionCheckbox;
