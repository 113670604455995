import { IComment } from '../../features/journey/type';
import { ERoles } from '../bridge';
import { rules } from './constant';

const extractId = (comments: IComment[]) => {
  // We use object "map"
  // E.g: const objectMap = {1:0, 2:1} where 1,2 represents id and value 0,1 represent the index of the array to push all comments with the same id.
  // If a comment has "idFamily" => it's a reply to the a comment. If not =>  It the original comment where the id will be used as idFamily for others.
  const objectMap = new Map();
  let index = 0;
  comments.map((comment) => {
    if (!comment.idFamily) {
      objectMap.set(comment.id, index);
      index++;
    }
  });
  return objectMap;
};

const attachUserName = (comments: IComment[][]) => {
  comments = comments.map((group) =>
    group.map((item) => {
      // We compare all comments to see if we have a match between the "id" and "asAnswerTo" where "asAnswerTo" represents the id of the comment replied.
      // If we found one match we then, retrieve the user name to attach to object.
      const asAnswerToUser = group.find((i) => i.id === item.asAnswerTo);
      return {
        ...item,
        answerToUserName:
          asAnswerToUser && asAnswerToUser.user && asAnswerToUser.user.name
            ? asAnswerToUser.user.name
            : asAnswerToUser && asAnswerToUser.user && asAnswerToUser.user.email
            ? asAnswerToUser.user.email
            : null,
      };
    })
  );
  return comments;
};

const isRoleAllowed = (session) =>
  session &&
  session.user &&
  session.user.role &&
  session.user.role !== ERoles.restricted
    ? true
    : false;
// !improve  include orderComment in groupComments same of attachUserName instead of more operation.

const returnDetailsUser = (session, userDetails) => {
  return {
    authorisationEmailing:
      userDetails &&
      userDetails.data &&
      userDetails.data.data &&
      userDetails.data.data.authorisationEmailing
        ? userDetails.data.data.authorisationEmailing
        : null,
    name:
      userDetails &&
      userDetails.data &&
      userDetails.data.data &&
      userDetails.data.data.name
        ? userDetails.data.data.name
        : session && session.user && session.user.name
        ? session.user.name
        : session && session.user && session.user.email
        ? session.user.email.match(/^([^@]+)/)[0]
        : null,
    image:
      userDetails &&
      userDetails.data &&
      userDetails.data.data &&
      userDetails.data.data.image,
    role:
      userDetails &&
      userDetails.data &&
      userDetails.data.data &&
      userDetails.data.data.role,
  };
};

const handleRuleDislpayFilter = (role: ERoles) => {
  if (role === ERoles.student) return false;
  return true;
};

const handleRuleDislpayTitle = (role: ERoles) => {
  if (role === ERoles.student) return false;
  return true;
};

const handleRuleAddANewStudentRule = (role: ERoles, object) => {
  if (role === ERoles.student && object && object.length > 0) return false;
  return true;
};

const handleRulesRole = (rule: string, role: ERoles, object?: any) => {
  switch (rule) {
    case rules.displayFilter:
      return handleRuleDislpayFilter(role);
    case rules.displayTitle:
      return handleRuleDislpayTitle(role);
    case rules.addANewStudent:
      return handleRuleAddANewStudentRule(role, object);

    default:
      return true;
  }
};

export {
  handleRulesRole,
  returnDetailsUser,
  isRoleAllowed,
  attachUserName,
  extractId,
};
