import { sizeCircleImage } from '../../components/share';

type TLanguages = 'eng' | 'fr' | 'ptbr' | 'es';
const months: { [key in TLanguages]: readonly string[] } = {
  eng: [
    'jan',
    'feb',
    'mar',
    'apr',
    'may',
    'jun',
    'jul',
    'aug',
    'sep',
    'oct',
    'nov',
    'dec',
  ],
  fr: [
    'jan',
    'fév',
    'mar',
    'avr',
    'mai',
    'jun',
    'jul',
    'aoû',
    'sep',
    'oct',
    'nov',
    'déc',
  ],
  ptbr: [
    'jan',
    'fev',
    'mar',
    'abr',
    'mai',
    'jun',
    'jul',
    'ago',
    'set',
    'out',
    'nov',
    'dez',
  ],
  es: [
    'Ene',
    'Feb',
    'Mar',
    'Abr',
    'May',
    'Jun',
    'Jul',
    'Ago',
    'Sep',
    'Oct',
    'Nov',
    'Dic',
  ],
};
const mappingLanguages = {
  eng: 'en-gb',
  fr: 'fr',
  ptbr: 'pt-br',
  es: 'es',
};
const days: { [key in TLanguages]: readonly string[] } = {
  eng: ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'],
  fr: ['lun', 'mar', 'mer', 'jeu', 'ven', 'sam', 'dim'],
  ptbr: ['seg', 'ter', 'qua', 'qui', 'sex', 'sáb', 'dom'],
  es: ['Dom', 'Lun', 'Mar', 'Mié', 'Jue', 'Vie', 'Sáb'],
};
const dayName = {
  eng: ['day', 'days'],
  fr: ['jour', 'jours'],
  ptbr: ['dia', 'dias'],
  es: ['día', 'días'],
};
const ordinal = {
  eng: {
    '1': 'st',
    '2': 'nd',
    '21': 'st',
    '22': 'nd',
    '23': 'rd',
    '31': 'st',
    default: 'th',
  },
  fr: {
    '1': 'st',
    '2': 'nd',
    '3': 'rd',
    '21': 'st',
    '22': 'nd',
    '23': 'rd',
    '31': 'st',
    default: 'th',
  },
  ptbr: {
    '1': '',
    '2': '',
    '3': '',
    '21': '',
    '22': '',
    '23': '',
    '31': '',
    default: '',
  },
  es: {
    '1': 'er',
    '2': 'do',
    '3': 'er',
    '4': 'to',
    '5': 'to',
    '6': 'to',
    '7': 'mo',
    '9': 'no',
    '10': 'mo',
    '21': 'er',
    '22': 'do',
    '23': 'er',
    '24': 'to',
    '25': 'to',
    '26': 'to',
    '27': 'mo',
    '29': 'no',
    '30': 'mo',
    '31': 'er',
    default: 'vo',
  },
};

const optionsLanguages = [
  { value: 'eng', label: 'eng' },
  { value: 'fr', label: 'fr' },
  { value: 'ptbr', label: 'pt-br' },
  { value: 'es', label: 'es' },
];

const dataTestID = {
  routeProtected: 'routeProtected',
  loader: 'loader',
};

const imagePropsTeacherDefault = {
  src: '/assets/png/teacher.png',
  alt: '',
  width: sizeCircleImage.high,
  height: sizeCircleImage.high,
};

const regex = {
  extractExtension: /\.[0-9a-z]+$/i,
  extractWordAfterLastSlash: /[^/]+$/i,
  extractWordAfterFirstSlash: /\/([^/]+)/i,
  checkFormatDate: /^(0[1-9]|[1-2][0-9]|3[0-1])\/(0[1-9]|1[0-2])\/\d{4}$/,
};

const roles = {
  eng: {
    admin: 'admin',
    guest: 'guest',
    teacher: 'teacher',
    parent: 'parent',
    professional: 'professional',
    restricted: 'restricted',
    student: 'student',
  },
  fr: {
    admin: 'administrateur',
    guest: 'invité',
    teacher: 'enseignant',
    parent: 'parent',
    professional: 'professionnel',
    restricted: 'restreint',
    student: 'étudiant',
  },
  es: {
    admin: 'administrador',
    guest: 'invitado',
    teacher: 'profesor',
    parent: 'padre',
    professional: 'profesional',
    restricted: 'restringido',
    student: 'estudiante',
  },
  ptbr: {
    admin: 'administrador',
    guest: 'convidado',
    teacher: 'professor',
    parent: 'pai',
    professional: 'profissional',
    restricted: 'restrito',
    student: 'aluno',
  },
};
const languages = ['fr', 'es', 'eng', 'ptbr'];

export {
  languages,
  roles,
  regex,
  imagePropsTeacherDefault,
  dataTestID,
  optionsLanguages,
  ordinal,
  dayName,
  days,
  mappingLanguages,
  months,
};
