import { css } from '@emotion/react';
import { useSession } from 'next-auth/react';
import { useRouter } from 'next/router';
import * as React from 'react';
import { evaluations } from '..';
import { wrapperButtonsShared } from '../../../components/buttons/sharedCSS';
import BannerTwo from '../../../components/elements/BannerTwo';
import {
  returnFormatDateOrdinal,
  returnNewFormatDate,
} from '../../../services/common';
import { TLanguages } from '../../../services/data';
import { themeStyle } from '../../../services/style/constant';
import AddNewProcessEvaluation from './AddNewProcessEvaluation';
import ProcessEvaluationCard from './ProcessEvaluationCard';

const componentCSS = {
  self: css({
    ...wrapperButtonsShared,
    marginBottom: '40px',
  }),
  evaluationDisplay: css({
    width: '100%',
    marginTop: '1.5rem',
    marginBottom: '2rem',
  }),
};

export const Evaluations: React.FC<{
  listProcessEvaluationsOfAPatientWithUsersLinked;
  resultGetStudentProfile;
}> = ({
  listProcessEvaluationsOfAPatientWithUsersLinked,
  resultGetStudentProfile,
}) => {
  const { locale } = useRouter();
  const session = useSession();
  const [isEvaluationAlreadyAdded, setIsEvaluationAlreadyAdded] =
    React.useState(false);
  const newEvaluationAddedPosition = document.getElementById('0');
  const handleNewEvaluationAdd = () => {
    setIsEvaluationAlreadyAdded(true);
    if (newEvaluationAddedPosition) {
      window.scrollTo({
        top: newEvaluationAddedPosition.clientHeight - 150,
      });
    }
    listProcessEvaluationsOfAPatientWithUsersLinked.refetch();
  };
  React.useEffect(() => {
    if (
      listProcessEvaluationsOfAPatientWithUsersLinked &&
      listProcessEvaluationsOfAPatientWithUsersLinked.data &&
      listProcessEvaluationsOfAPatientWithUsersLinked.data.length
    ) {
      const actualDateFormatted = returnFormatDateOrdinal(
        returnNewFormatDate(new Date()),
        locale as TLanguages,
      );
      if (
        listProcessEvaluationsOfAPatientWithUsersLinked.data[0].createdAt ==
        actualDateFormatted
      )
        setIsEvaluationAlreadyAdded(true);
    }
  }, []);

  return (
    <div css={componentCSS.self} className={'flex_column_center_center'}>
      <div css={componentCSS.evaluationDisplay}>
        {listProcessEvaluationsOfAPatientWithUsersLinked &&
        listProcessEvaluationsOfAPatientWithUsersLinked.data &&
        listProcessEvaluationsOfAPatientWithUsersLinked.data.length ? (
          listProcessEvaluationsOfAPatientWithUsersLinked.data.map(
            (processEvaluationsOfAPatientWithUsersLinked, index: number) => (
              <div
                key={index}
                className="process_evaluation"
                id={index.toString()}
                style={{ marginTop: '1rem' }}
              >
                <ProcessEvaluationCard
                  date={processEvaluationsOfAPatientWithUsersLinked.createdAt}
                  profile={processEvaluationsOfAPatientWithUsersLinked.profile}
                  name={
                    session.data && session.data.user
                      ? session.data.user.name
                      : ''
                  }
                  evaluationType={
                    processEvaluationsOfAPatientWithUsersLinked.evaluationType
                  }
                  processEvaluationId={
                    processEvaluationsOfAPatientWithUsersLinked.processEvaluationId
                  }
                  patientId={
                    processEvaluationsOfAPatientWithUsersLinked.patientId
                  }
                  listUsers={
                    processEvaluationsOfAPatientWithUsersLinked.listUsers
                  }
                  refetch={
                    listProcessEvaluationsOfAPatientWithUsersLinked.refetch
                  }
                  resultGetStudentProfile={resultGetStudentProfile}
                />
              </div>
            ),
          )
        ) : (
          <BannerTwo
            text={evaluations[locale].text}
            colorPage={themeStyle.background.third}
          />
        )}
      </div>
      <AddNewProcessEvaluation
        refetch={handleNewEvaluationAdd}
        isEvaluationAlreadyAdded={isEvaluationAlreadyAdded}
      />
    </div>
  );
};
