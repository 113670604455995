import {
  themeStyle,
  breakingPoints,
  configSizeImage,
  animationTransitionTime,
  templateSizes,
} from './constant';
import { getContrastColor } from './function';
import {
  ITheme,
  ConfigSizeImage,
  ConfigSizeImageMap,
  TTypeConfigSizeImage,
} from './type';

export {
  themeStyle,
  breakingPoints,
  configSizeImage,
  animationTransitionTime,
  templateSizes,
  getContrastColor,
};
export type {
  ITheme,
  ConfigSizeImage,
  ConfigSizeImageMap,
  TTypeConfigSizeImage,
};
