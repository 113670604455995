const requestsApiImageGenerator = {
  avatar: 'Avatar',
  characteristicAvatar: 'characteristicAvatar',
  banner: 'Banner',
  sticker: 'Sticker',
  strap: 'Strap',
};

const localStorageKeys = {
  intervalKeyLocaleStorage: 'intervalApiImageGenerator',
  incrementKeyLocalStorage: 'incrementApiImageGenerator',
};

export { localStorageKeys, requestsApiImageGenerator };
