import { css } from '@emotion/react';
import { useSession } from 'next-auth/react';
import { useRouter } from 'next/router';
import React, { useEffect, useRef, useState } from 'react';
import { ITheme } from '../../services/style/type';
import { postAuthorisationEmailing } from '../../features/user';
import { useHeaderSizeContext } from '../../services/contexts/userHeaderSizeContext';
import { generalData } from '../../services/data';
import IconsLogos from '../elements/IconsLogos';
import { primarySpacePadding } from '../share';
import MenuBurger from './MenuBurger';
import LanguageSelection from './elements/LanguageSelection';

const componentCSS = {
  self: (props: ITheme) =>
    css({
      '@media print': {
        //using display: none does not work with print for chart

        display: 'none',
      },
      backgroundColor: props.background.primary,
      color: props.colorsText.third,

      '& .header': {
        '& .burger_wrapper svg': {
          width: '45px',
          height: '45px',
        },
        '& .deft_header_select': {
          display: 'flex !important',
          alignItems: 'center',
          color: props.colorsText.primary,
          '& svg': {
            '& path': {
              stroke: 'white',
            },
          },
        },
        '& svg': {
          '&: hover': {
            cursor: 'pointer',
          },
        },
        '& p': {
          textTransform: 'uppercase',
          fontWeight: 'bold',
        },
      },
      '& .shadow_screen': {
        height: '100vh',

        backgroundColor: '#0000008a',
        position: 'absolute',
        top: 0,
        zIndex: 3,
      },
    }),
};

const BurgerAndMenuBurger = () => {
  const [burgerClicked, setBurgerClicked] = useState(false);
  const handleClick = () => setBurgerClicked(!burgerClicked);
  const { updateHeaderSize, headerSize } = useHeaderSizeContext();
  const headerRef = useRef(null);
  useEffect(() => {
    const updateHeaderSizeIfNeeded = () => {
      if (headerRef.current) {
        const { offsetWidth, offsetHeight } = headerRef.current;
        if (
          offsetWidth !== headerSize.width ||
          offsetHeight !== headerSize.height
        ) {
          updateHeaderSize({ width: offsetWidth, height: offsetHeight });
        }
      }
    };

    updateHeaderSizeIfNeeded();
    window.addEventListener('resize', updateHeaderSizeIfNeeded);

    return () => {
      window.removeEventListener('resize', updateHeaderSizeIfNeeded);
    };
  }, []);
  return (
    <>
      <div
        tabIndex={0}
        ref={headerRef}
        onKeyDown={(event) => {
          if (event.key === 'Enter') setBurgerClicked(!burgerClicked);
        }}
        role={'button'}
        onClick={() => setBurgerClicked(!burgerClicked)}
        data-testid="test-home-burger"
        aria-label={'menu burger'}
      >
        <div className="burger_wrapper">
          <IconsLogos type={'burger'} />
        </div>
        <div className={`${burgerClicked && 'shadow_screen'}`}></div>
        <MenuBurger
          onBurgerClicked={burgerClicked}
          functionToApply={handleClick}
        />
      </div>
    </>
  );
};
const DefaultHeader: React.FC = () => {
  const { data: session, status } = useSession();
  const { locale } = useRouter();
  const [isClientSide, setIsClientSide] = useState(false);
  const mutateAuthorisationEmailing = postAuthorisationEmailing();
  const [language, setLanguage] = useState<any>('');

  useEffect(() => {
    let isMounted = true;
    setIsClientSide(true);
    async function fetchData() {
      if (status === 'authenticated' && isMounted) {
        const doesTheUserAuthoriseEmail = localStorage.getItem(
          'authorisationEmailing',
        );
        if (
          session &&
          session.user &&
          //@ts-ignore
          session.user.authorisationEmailing !== 'undefined' &&
          //@ts-ignore
          doesTheUserAuthoriseEmail !== session.user.authorisationEmailing
        ) {
          mutateAuthorisationEmailing.mutate({
            //@ts-ignore
            userId: session.user.id,
            authorisationEmailing: doesTheUserAuthoriseEmail === 'true',
          });
        }
      }
    }

    fetchData();
    return () => {
      isMounted = false;
    };
  }, [status]);

  useEffect(() => {
    function getCookie(name) {
      if (!isClientSide) return;
      const value = `; ${document.cookie}`;
      const parts = value.split(`; ${name}=`);
      if (parts.length === 2) return parts.pop().split(';').shift();
    }
    const languagePreference = getCookie('selectedLanguage');
    if (languagePreference !== locale) setLanguage(languagePreference);
  }, [isClientSide]);

  return (
    <div
      css={[componentCSS.self, primarySpacePadding]}
      className="default_header"
    >
      <div className="flex_row_btw_center header" css={{ flexWrap: 'wrap' }}>
        <BurgerAndMenuBurger />
        <h2
          className="font_size_28_book"
          css={{
            color: 'white !important',
          }}
        >
          {generalData[locale].home.header.text[0]}{' '}
        </h2>

        <LanguageSelection languagePreference={language} />
      </div>
    </div>
  );
};

export default DefaultHeader;
