import { css } from '@emotion/react';
import { signOut, useSession } from 'next-auth/react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import * as React from 'react';
import { routesIn } from '../../services/bridge';
import { administration } from '../../services/common/constant';
import { TMenuLink, generalData } from '../../services/data';
import { ITheme } from '../../services/style/type';
import IconAndText from '../elements/IconAndText';
import IconsLogos from '../elements/IconsLogos';

type TMenuBurger = {
  onBurgerClicked: boolean;
  functionToApply: () => void;
};

const componentCSS = {
  self: (props: ITheme) =>
    css(`
			
.menus_wrapper {
 padding-left: min(15.33vw, 60px);
	padding-top: min(7.8125vh, 100px);
  gap: 10px;


}

.menus_wrapper svg {
  height: 35px !important;
  width: 35px !important;

}
  @media (max-width: 400px) {

	.menus_wrapper {
padding-left: min(12.33vw, 60px);
padding-top: min(7.8125vh, 100px);
}

	.menus_wrapper svg {
   height: 25px !important;
  width: 25px !important;
		}
    }
.header_burger_menu {
  background-color: ${props.colors.fifth_fourth};
  gap: 20px;
  font-style: bold;
	padding-top: 30px;
	padding-bottom: 30px;
	padding-left: min(4.16%, 30px);
}
.header_burger_menu svg path {
color: white !important;
fill: white !important;
}
.menu_burger {
  position: fixed;
  top: 0;
  left: 0;
  transform: translateX(-100%);
  transition: transform ${props.extra.transition_time}ms;
  height: 100vh;
  min-width: 23rem;
  background-color: white;
	overflow:auto;
	z-index:30000;
}

.menu_burger .link_burger svg {
  fill:  ${props.colorsText.primary};
  color:  ${props.colorsText.primary};
}

.menu_burger .link_burger svg path {
  fill:  ${props.colorsText.primary};
}

.menu_burger .link_burger:last-child {
  position: relative;
  margin-top: 1rem;
}

.menu_burger .link_burger:last-child::before {
  content: "";
  height: 1px;
  background-color: #c0baba;
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
}

.menu_burger_appear {
  transform: translateX(0%);
  transition: transform ${props.extra.transition_time};
}

.menu_burger_appear .cross {
  padding: .5rem;
  text-align: right;
  font-size: 1.5rem;
}

.menu_burger_appear .cross svg {
  color: ${props.background.primary};
}
.icon_text {
padding: 0rem;
margin-bottom: min(5.08vh, 65px); 
margin-right: 10px;


}

@media (max-width: 550px) {
.icon_text {
margin-bottom: min(4dvh, 65px); 
		}
      };
.icon_text span {
margin-left: 40px;
margin-right: 100px;

.menu_burger_appear .cross svg:hover {
  cursor: pointer;
}
`),
};

const filterMenus = (menus, role) => {
  if (role !== 'admin') {
    menus = menus.filter((menu) => menu.link !== 'admin');
  }
  return menus;
};

const MenuBurger: React.FC<TMenuBurger> = ({
  onBurgerClicked,
  functionToApply,
}) => {
  const { locale } = useRouter();
  const { status, data: session } = useSession();

  //@ts-ignore
  const role = session && session.user ? session.user.role : null;
  const datas =
    // @ts-ignore
    status === 'authenticated' && session.user.emailVerified
      ? [...filterMenus(generalData[locale].home.header.menus, role)]
      : // @ts-ignore
        status === 'authenticated' && !session.user.emailVerified
        ? generalData[locale].home.header.menus.filter(
            (menu) =>
              menu.link !== 'students' &&
              menu.link !== 'customise' &&
              menu.link !== 'admin',
          )
        : generalData[locale].home.header.menus.filter(
            (menu) =>
              menu.link !== 'students' &&
              menu.link !== 'logOut' &&
              menu.link !== 'customise' &&
              menu.link !== 'admin',
          );

  const handleDisplayMenu = (menu) => {
    switch (menu.link) {
      case 'logOut':
        return (
          <div
            key={menu.text}
            onClick={() => signOut()}
            tabIndex={0}
            onKeyDown={(event) => {
              if (event.key === 'Enter') signOut();
            }}
            role="button"
            aria-label={'log out'}
          >
            <IconAndText icon={'exit'} text={menu.text} />
          </div>
        );
      case 'politicOfPrivacy':
        return (
          <a
            className="font_size_26_book"
            key={menu.text}
            target="_blank"
            href={routesIn.eng.politicOfPrivacy[locale].link}
            rel="noopener noreferrer"
            aria-labelledby={menu.text}
          >
            <IconAndText icon={'document'} text={menu.text} />
          </a>
        );
      case 'email':
        return (
          <a
            className="font_size_26_book"
            key={menu.text}
            href={`mailto:${administration.emailContact}`}
          >
            {' '}
            <IconAndText icon={'envelope'} text={menu.text} />
          </a>
        );
      case 'students':
        return (
          <Link
            key={menu.text}
            href={routesIn.eng[menu.link].link}
            className=" link_burger font_size_26_book"
            locale={locale}
            aria-label={menu.text}
          >
            <IconAndText icon={'kid'} text={menu.text} />
          </Link>
        );
      case 'customise':
        return (
          <Link
            key={menu.text}
            href={routesIn.eng[menu.link].link}
            className=" link_burger font_size_26_book"
            locale={locale}
            aria-label={menu.text}
          >
            <IconAndText icon={'customise'} text={menu.text} />
          </Link>
        );
      case 'home':
        return (
          <Link
            key={menu.text}
            href={routesIn.eng[menu.link].link}
            className=" link_burger font_size_26_book"
            locale={locale}
            aria-label={menu.text}
          >
            <IconAndText icon={'home'} text={menu.text} />
          </Link>
        );
      case 'tutorial':
        return (
          <a
            key={menu.text}
            href={routesIn.eng[menu.link].link}
            aria-label={menu.text}
            target="_blank"
            rel="noopener noreferrer"
            className="font_size_26_book"
          >
            <IconAndText icon={'tutorial'} text={menu.text} />
          </a>
        );

      case 'glossary':
        return (
          <a
            key={menu.text}
            target="_blank"
            href={routesIn.eng.glossary[locale].link}
            rel="noopener noreferrer"
            aria-labelledby={menu.text}
            className="font_size_26_book"
          >
            <IconAndText icon={'book'} text={menu.text} />
          </a>
        );
      case 'admin':
        return (
          <a
            key={menu.text}
            target="_blank"
            href={routesIn.eng[menu.link].link}
            rel="noopener noreferrer"
            aria-labelledby={menu.text}
            className="font_size_26_book"
          >
            <IconAndText icon={'edit'} text={menu.text} />
          </a>
        );
      default:
        return;
    }
  };
  const [isClientSide, setIsClientSide] = React.useState(false);

  React.useEffect(() => {
    setIsClientSide(true);
  }, []);
  if (!isClientSide) return;

  return (
    <div css={componentCSS.self}>
      <div className={`menu_burger ${onBurgerClicked && 'menu_burger_appear'}`}>
        <div
          tabIndex={0}
          onKeyDown={(event) => {
            if (event.key === 'Enter') functionToApply();
          }}
          role={'button'}
          onClick={() => functionToApply()}
          aria-label={'close menu'}
          className="header_burger_menu flex_align_center_no_wrap"
        >
          <IconsLogos type={'burger'} />
          <span className="font_size_26_bold" style={{ color: 'white' }}>
            {' '}
            MENU
          </span>
        </div>
        <div className="menus_wrapper">
          {datas.map((menu: TMenuLink) => handleDisplayMenu(menu))}
        </div>
      </div>
    </div>
  );
};
export default MenuBurger;
