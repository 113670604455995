import axios from 'axios';
import { useMutation, useQuery } from 'react-query';
import { routesOut } from '../../../services/bridge';

export const getUserDetails = (userId: string) => {
  const { data, isLoading, isError, refetch } = useQuery(
    ['getUserDetail', userId],
    async () =>
      await axios.get(routesOut.data.getUserDetails(userId as string).link),
    { enabled: !!userId, refetchOnWindowFocus: false }
  );
  return { data, isLoading, isError, refetch };
};

export const postAuthorisationEmailing = () => {
  const mutation = useMutation(
    (details: { id: string; authorisationEmailing: boolean }) => {
      return axios.post(
        routesOut.data.updateAuthorisationEmailing.link,
        details
      );
    }
  );

  return mutation;
};
export const getSchools = (
  countriesID: (number | string)[] | null,
  userId?: string
) =>
  useQuery(
    ['schools', countriesID],
    async () =>
      await axios.get(
        routesOut.data.getSchools(
          countriesID as number[] | null,
          userId ? userId : null
        ).link
      ),
    { enabled: !!countriesID || !!userId }
  );
export const getClasses = (
  classesID: (number | string)[] | null,
  userId?: string
) =>
  useQuery(
    ['classes', classesID],
    async () =>
      await axios.get(
        routesOut.data.getClasses(classesID, userId ? userId : null).link
      ),
    { enabled: !!classesID }
  );
export const getCountries = (countries: string[]) =>
  useQuery(
    'countries',
    async () => await axios.get(routesOut.data.getCountries.link),
    {
      // @ts-ignore
      initialData: (() => {
        const data = [];
        // make sure those countries exist in the DB with the adequate "id".
        countries.map((country: string, index: number) =>
          data.push({ id: index + 1, name: country })
        );
        return data;
      })(),
    }
  );
