import { css } from '@emotion/react';
import axios from 'axios';
import { useState } from 'react';
import { routesOut, statusServer } from '../../../services/bridge';

import { useSession } from 'next-auth/react';
import { useRouter } from 'next/router';
import ButtonOriginal from '../../../components/buttons/ButtonOriginal';
import {
  ElementColorsPalette,
  Palette,
} from '../../customise/components/elements/Elements';
import Loader from '../../../components/utils/Loader';
import { colorError } from '../../../components/utils/constantCSS';
import errorsData from '../../../services/data/json/errors.json';
import successData from '../../../services/data/json/success.json';
import { colorPaletteHeaderFooter } from '../../../services/style/constant';
import userData from '../data/user.json';
const componentCSS = {
  self: css`
    .color-rectangle {
      width: 22%;
      max-width: 188px;
      height: 40px;
      border-radius: 10%;
    }
    .button_original {
      text-transform: capitalize;
      background: linear-gradient(to right, #e85c9e, #47adce);
      font-size: 1em;
      text-transform: capitalize;
      font-weight: bold;
      height: 33px;
    }
    .button_original span {
      color: white;
    }
  `,
};
const ColorSelectorHeaderFooter = () => {
  const { locale } = useRouter();
  const [loading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [notification, setNotification] = useState(null);
  const { data: session } = useSession();
  const [selectedColor, setSelectedColor] = useState<{
    mainColor: string;
  } | null>(null);

  const handleColorSelection = (color) => {
    setSelectedColor(color);
  };
  //@ts-ignore
  const userId = session && session.user ? session.user.id : null;
  const handlePostColor = () => {
    // Example Axios post request
    setIsLoading(true);
    setNotification(null);
    setError(null);
    axios
      .post(routesOut.data.saveColorHeaderFooter.link, {
        color: selectedColor,
        userId,
      })
      .then((response) => {
        if (response && response.status === statusServer.success)
          setNotification(successData[locale].success);
        window.location.reload();
      })
      .catch((error) => {
        const errorNotification = error.response.data.message
          ? errorsData[locale][error.response.data.message][0]
          : errorsData[locale].server[0];
        setError(errorNotification);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <div css={componentCSS.self}>
      <h2 className="font_size_26_medium">
        {userData[locale].palette.title[3]}
      </h2>
      <Palette
        returnColor={handleColorSelection}
        colorPalette={colorPaletteHeaderFooter}
      />
      {selectedColor && (
        <div>
          <p>{userData[locale].palette.title[4]}</p>
          <ElementColorsPalette
            returnColor={() => null}
            element={selectedColor}
          />
          <div style={{ marginTop: '1rem' }}>
            <ButtonOriginal
              text={'Post Color'}
              functionToApply={handlePostColor}
              icon={''}
            />
          </div>
          {notification && (
            <p style={{ color: '#93C530' }} className="font_size_24_light">
              {' '}
              {notification}
            </p>
          )}
          {error && (
            <p className="font_size_24_light" css={colorError}>
              {' '}
              {error}
            </p>
          )}
          {loading && <Loader />}
        </div>
      )}
    </div>
  );
};

export default ColorSelectorHeaderFooter;
