import { useRef } from 'react';
import IconsLogos from '../elements/IconsLogos';

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      css={{
        ...style,
        display: 'block',
        height: '50px',
        right: '-10vw',
        '@media (max-width: 700px)': {
          right: '40px',
          zIndex: '10',
        },
        '& svg': {
          color: '#b4b4b4',
          '@media (max-width: 700px)': {
            color: 'white',
          },
        },
        '&::before': {
          display: 'none',
        },
      }}
      onClick={onClick}
      onKeyDown={(event) => {
        if (event.key === 'Enter') onClick();
      }}
      role="button"
      tabIndex={0}
    >
      <IconsLogos type={'arrow2'} />
    </div>
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      css={{
        ...style,
        transform: 'rotate(180deg)',
        display: 'block',
        left: '-10vw',
        '@media (max-width: 700px)': {
          left: '40px',
          zIndex: '10',
          height: '25px',
        },
        '& svg': {
          color: '#b4b4b4',
          '@media (max-width: 700px)': {
            color: 'white',
          },
        },
        '&::before': {
          display: 'none',
        },
      }}
      onClick={onClick}
      onKeyDown={(event) => {
        if (event.key === 'Enter') onClick();
      }}
      role="button"
      tabIndex={0}
    >
      <IconsLogos type={'arrow2'} />
    </div>
  );
}
const Util = () => {
  const sliderRef: any = useRef(null);
  const settings = {
    dots: true,
    fade: true,
    infinite: true,
    speed: 300,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };

  return { sliderRef, settings };
};

export default Util;
