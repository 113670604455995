import { useTheme } from '@emotion/react';
import * as React from 'react';
import { RotatingLines } from 'react-loader-spinner';
import { ITheme } from '../../services/style/type';

const Loader: React.FC<{ typeLoader?: string }> = ({ typeLoader }) => {
  const theme: ITheme | any = useTheme();
  const [isVisible, setIsVisible] = React.useState(true);

  React.useEffect(() => {
    if (typeLoader !== 'v2') return;
    const timer = setTimeout(() => {
      setIsVisible(false);
    }, 30000);

    return () => {
      clearTimeout(timer);
    };
  }, []);

  return (
    <>
      {typeLoader === 'v2' ? (
        <div
          data-testid="loaderComponent-id"
          style={{
            height: '100vh',
            position: 'fixed',
            top: 0,
            width: '100%',
            left: 0,
            backgroundColor: '#1c1c1c85',
            zIndex: 1000,
            display: isVisible ? 'flex' : 'none',
          }}
        >
          <div
            css={{
              margin: 'auto',
              '& svg': {
                stroke: 'white !important',
              },
            }}
          >
            <RotatingLines
              strokeColor={theme.background.fourth}
              strokeWidth="5"
              animationDuration="0.75"
              width="50"
              visible={true}
            />
          </div>
        </div>
      ) : (
        <div style={{ height: '100%', margin: 'auto' }}>
          <div
            data-testid="loaderComponent-id"
            style={{ margin: 'auto', width: 'max-content', marginTop: '1rem' }}
            className="loader_component"
          >
            <RotatingLines
              strokeColor={theme.background.primary}
              strokeWidth="5"
              animationDuration="0.75"
              width="50"
              visible={true}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default Loader;
