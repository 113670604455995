import { useRouter } from 'next/router';
import resultEvaluation from '../data/resultEvaluation.json';
export const options = (color: string, index: number) => {
  const { locale } = useRouter();
  return {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      y: {
        min: 0,
        max: 100,
        ticks: {
          color: '#B6B5B5',
          // format: {
          //   style: 'percent',
          // },
          font: {
            size: 16,
            family: 'Book',
          },
          callback: function (value) {
            return value;
          },
        },
        beginAtZero: true,
      },
      x: {
        stacked: true,
        beginAtZero: true,

        ticks: {
          color: '#707070',
          font: {
            size: 18,
            family: 'Book',
          },
        },
      },
    },
    plugins: {
      legend: {
        display: false,
        position: 'top' as const,

        title: {
          display: true,
          text:
            index == 0
              ? resultEvaluation[locale].legend.title[0]
              : resultEvaluation[locale].legend.title[1],
        },
      },
    },
  };
};
