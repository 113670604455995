import { css } from '@emotion/react';
import { useRouter } from 'next/router';
import React from 'react';
import { evaluationData } from '..';
import PrintButtonv2 from '../../../components/buttons/PrinterButtonv2';
import { ITheme } from '../../../services/style/type';
import { ResultatEvaluation } from '../../resultEvaluation';
import PerceptionsCircles from './PerceptionsCircles';

const componentCSS = {
  self: (props: ITheme) => css`
    h2 {
      color: ${props.colorsText.secondary};
      font-weight: normal;
    }
    .print_button {
      margin-top: 2rem;
      margin-bottom: 2rem;
      text-align: right;
    }
    .print_button .button_original {
      background-color: transparent;
    }

    .print_button .button_original span {
      display: none;
    }
    .PrintButtonv2 {
      text-align: right;
    }
    @media print {
      margin-top: -350px !important;

      .print_button {
        display: none;
      }
    }
  `,
};
const AnalyseEvaluation: React.FC<{
  props: {
    perception;
    patientInfos;
    handleScrollingCSS;
    isUnderReview;
  };
}> = ({
  props: { perception, patientInfos, handleScrollingCSS, isUnderReview },
}) => {
  const { locale } = useRouter();

  if (isUnderReview)
    return (
      <p className="font_size_22_light_italic">
        {evaluationData[locale].evaluationUndeReview}
      </p>
    );
  return (
    <>
      <div css={componentCSS.self}>
        <h2 className="font_size_26_medium" style={{ marginTop: '0' }}>
          {' '}
          {evaluationData[locale].analyseEvaluation.title}
        </h2>
        {perception ? (
          <>
            <p className="font_size_22_medium" style={{ fontStyle: 'italic' }}>
              {evaluationData[locale].analyseEvaluation.description}{' '}
            </p>
            <div
              style={{
                maxWidth: '90%',
              }}
            >
              <PerceptionsCircles
                props={{
                  perception: perception,
                }}
              />
            </div>
            <ResultatEvaluation
              patientInfos={patientInfos}
              handleScrollingCSS={handleScrollingCSS}
            />{' '}
            <div className="print_button">
              <PrintButtonv2 />
            </div>
          </>
        ) : (
          <p> {evaluationData[locale].noAnalyse}</p>
        )}
      </div>
    </>
  );
};
export default AnalyseEvaluation;
