import React from 'react';
import { motion } from 'framer-motion';
import { ContextProviderProps } from '../../services/data';
import { animationTransitionTime } from '../../services/style/constant';

interface Fadding extends ContextProviderProps {
  isOpen: boolean;
}

const fadeAnimation = {
  open: {
    height: 'auto',
    transition: {
      duration: animationTransitionTime.primary,
    },
  },
  closed: {
    height: 0,
    transition: {
      duration: animationTransitionTime.primary,
    },
  },
};

const ExpendAnimationHeight: React.FC<Fadding> = ({ children, isOpen }) => {
  return (
    <motion.div
      variants={fadeAnimation}
      animate={isOpen ? 'open' : 'closed'}
      initial={'closed'}
      className="fadingToggleLeftRight"
      style={{ overflow: 'hidden' }}
    >
      {children}
    </motion.div>
  );
};

export default ExpendAnimationHeight;
