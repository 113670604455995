import { css } from '@emotion/react';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { SketchPicker } from 'react-color';
import CloseButton from '../../../../components/buttons/CloseButton';
import IconAndText from '../../../../components/elements/IconAndText';
import {
  primarySpacePadding,
  thirdSpaceMargin,
} from '../../../../components/share';
import { ITheme } from '../../../../services/style/type';

import Link from 'next/link';
import { customiseData } from '../..';
import BackButton from '../../../../components/elements/BackButton';
import { routesIn } from '../../../../services/bridge';

export const colorPalette = [
  {
    gradient: 'linear-gradient(to right, #ff7e5f, #feb47b)',
    mainColor: '#ff7e5f',
  },
  {
    gradient: 'linear-gradient(to right, #4facfe, #00f2fe)',
    mainColor: '#4facfe',
  },
  {
    gradient: 'linear-gradient(to right, #12c2e9, #c471ed)',
    mainColor: '#12c2e9',
  },
  {
    gradient: 'linear-gradient(to right, #56ab2f, #a8e063)',
    mainColor: '#56ab2f',
  },
  {
    gradient: 'linear-gradient(to right, #ffcc33, #ff9900)',
    mainColor: '#ffcc33',
  },
  {
    gradient: 'linear-gradient(to right, #2b5876, #4e4376)',
    mainColor: '#2b5876',
  },
  {
    gradient: 'linear-gradient(to right, #0575e6, #021b79)',
    mainColor: '#0575e6',
  },
  {
    gradient: 'linear-gradient(to right, #f12711, #f5af19)',
    mainColor: '#f12711',
  },
  {
    gradient: 'linear-gradient(to right, #E85C9E, #47ADCE)',
    mainColor: '#E85C9E',
  },
  {
    gradient: 'linear-gradient(to right, #F36B21, #FEC90A)',
    mainColor: '#F36B21',
  },
  {
    gradient: 'linear-gradient(to right, #FED672, #94C63D)',
    mainColor: '#FED672',
  },
  {
    gradient: 'linear-gradient(to right, #4e4376, #94C63D)',
    mainColor: '#4e4376',
  },
];

const componentCSS = {
  self: css`
    gap: 10px;
    .color-rectangle {
      width: 22%;
      max-width: 188px;
      height: 40px;
      border-radius: 10%;
    }
  `,
};

export const ElementColorsPalette = ({ returnColor, element }) => {
  return (
    <button
      onClick={() => returnColor(element.mainColor)}
      className="color-rectangle button_no_decor"
      css={[
        `background-color:${element.mainColor} !important;
				 box-shadow:rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
              &:hover {
                transform: scale(1.02);
                transition: transform 0.5s ease;
              }
            `,
      ]}
    ></button>
  );
};
export const Palette = ({ returnColor, colorPalette }) => {
  const [initialRenderComplete, setInitialRenderComplete] = useState(false);
  useEffect(() => {
    // Updating a state causes a re-render
    setInitialRenderComplete(true);
  }, []);
  const { locale } = useRouter();
  return (
    <div className="color-palette flex_row_center_wrap" css={componentCSS.self}>
      {initialRenderComplete &&
        colorPalette.map((element, index) => (
          <ElementColorsPalette
            key={index}
            returnColor={() => returnColor(element)}
            element={element}
          />
        ))}

      <button
        className="color-rectangle button_no_decor hover_cursor"
        onClick={() =>
          returnColor({
            colorText: '',
            mainColor: '',
          })
        }
        css={[
          [
            `	box-shadow:rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;   
            `,
          ],
        ]}
      >
        {customiseData[locale].default}
      </button>
    </div>
  );
};
export const PickColor = ({ colorDefault, returnColor }) => {
  const { locale } = useRouter();
  // The color default is a gradient so watch out.
  const [color, setColor] = useState(colorDefault);
  const handleChangeComplete = (color) => setColor(color.hex);

  return (
    <>
      <SketchPicker color={color} onChangeComplete={handleChangeComplete} />
      <button
        className="save_color"
        css={{ backgroundColor: '#463986', color: 'white' }}
        onClick={() => returnColor(color)}
      >
        {customiseData[locale].texts[1]}
      </button>
    </>
  );
};
export const DisplayColorsSelected = ({
  listColorSelected,
  removeColorFromTheList,
}) => {
  const componentCSS = {
    self: css`
      overflow: auto;
      gap: 5px;
      .close_button {
        border: none;
        width: max-content;
        font-size: 0.8rem;
        background: transparent;
      }
      .color-rectangle {
        width: 5rem;
        height: 1rem;
        box-shadow:
          rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
          rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
      }
    `,
  };
  return (
    <div css={componentCSS.self} className="flex">
      {listColorSelected.map((color, index) => (
        <div key={index} className="flex_colum_end color_selected">
          <CloseButton
            functionToClose={() => removeColorFromTheList(color)}
            color={''}
          />
          <div
            className="color-rectangle"
            style={{ backgroundColor: color }}
          ></div>
        </div>
      ))}
    </div>
  );
};

export const HeaderCustomise = () => {
  const componentCSS = {
    self: (props: ITheme) => css`
      margin-top: 2rem;
      img {
        width: 10rem;
        height: 2rem;
      }
      .header_customise_child_one {
        flex-grow: 1;
      }
      .header_customise_child_second {
        flex-grow: 1;
      }
      .header_customise_child_one svg path {
        fill: ${props.background.fifth};
      }
      @media (min-width: 500px) {
        /* Styles for screens up to 768px width */
        img {
          width: 14rem;
          height: 3rem;
        }
      }
    `,
  };

  return (
    <div
      css={componentCSS.self}
      className="flex_start_align_center header_customise"
    >
      <div className="header_customise_child_one">
        <BackButton />
      </div>
      <div className="header_customise_child_second">
        <IconAndText icon={'addhereHorizontal'} text={''} />
      </div>
    </div>
  );
};
export const TitlePara = () => {
  const { locale } = useRouter();
  return (
    <div>
      <h2 className="font_size_26_medium">
        {' '}
        {customiseData[locale].introduction.title[0]}{' '}
      </h2>
      <p className="font_size_24_light">
        {customiseData[locale].introduction.paragraph[0]}
      </p>
    </div>
  );
};
export const ButtonV1 = ({ iconType, text, gradient }) => {
  const componentCSS = {
    self: css`
      border-radius: 20px;
      color: white;
      text-transform: capitalize;
      font-weight: bold;
      background: ${gradient};
      svg {
        color: white;
      }
    `,
  };

  return (
    <div
      className="flex_row_btw_center"
      css={[componentCSS.self, primarySpacePadding]}
    >
      <div className="flex_start_align_center">
        <IconAndText icon={iconType} text={''} />
        <h3 className="font_size_24_medium">{text}</h3>
      </div>
      <IconAndText icon={'arrow2'} text={''} />
    </div>
  );
};
export const ButtonsLink = () => {
  const { locale } = useRouter();

  const gradients = [
    'linear-gradient(to right, #E85C9E, #47ADCE)',
    'linear-gradient(to right, #F36B21, #FEC90A)',
    'linear-gradient(to right, #FED672, #94C63D)',
  ];

  return (
    <>
      {customiseData[locale].buttons.map((button, index) => (
        <div
          key={index}
          css={[
            thirdSpaceMargin,
            {
              boxShadow:
                'rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px',
              borderRadius: '20px',
            },
            `&:hover {
        transform: scale(1.02);
				transition: transform 0.5s ease; 
      }`,
          ]}
          className="button_customise"
        >
          <Link
            href={routesIn.eng.customise[button.iconType].link}
            locale={locale}
            aria-label="link to customise"
          >
            <ButtonV1
              iconType={button.iconType}
              text={button.text}
              gradient={gradients[index]}
            />
          </Link>
        </div>
      ))}
    </>
  );
};
export const NotificatioCustomise = () => {
  const { locale } = useRouter();
  return <p className="font_size_24_light">{customiseData[locale].texts[0]}</p>;
};
