import { css, keyframes } from '@emotion/react';
import React from 'react';

const loadingAnimation = keyframes`
    0% {
        background-position-x: 180%;
    }
    100% {
        background-position-x: -20%;
    }
`;

const skeletonLoading = css`
  background: linear-gradient(
      100deg,
      rgba(255, 255, 255, 0) 40%,
      rgba(255, 255, 255, 0.5) 50%,
      rgba(255, 255, 255, 0) 60%
    )
    #ededed;
  background-size: 200% 100%;
  background-position-x: 180%;
  animation: ${loadingAnimation} 1s ease-in-out infinite;
`;

const componentCSS = {
  self: css`
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 330;
  `,
  imageWrapper: [skeletonLoading],
};

const CardLoading: React.FC = () => {
  return (
    <div
      css={[componentCSS.self]}
      className="flex_column_align_center_btw card_car card_card_loading"
    >
      <div css={skeletonLoading} style={{ width: '100%', height: '100%' }}>
        <span style={{ opacity: 0 }}>ok </span>
      </div>
    </div>
  );
};

export default CardLoading;
