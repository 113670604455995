import { optionsLanguages } from '../data/constant';
import { TLanguages } from '../data/type';

const findIndexOptionsLanguages = (language: TLanguages) => {
  const indexOfLocale = optionsLanguages.findIndex(
    (item) => item.value === language || item.label === language
  );
  return indexOfLocale;
};
const returnTitleCapitalise = (title: string) => {
  return title.charAt(0).toUpperCase() + title.slice(1);
};
export { findIndexOptionsLanguages, returnTitleCapitalise };
