import React from 'react';
import ProgressBar from '../../../../components/elements/ProgressAnimation';
import { translateDiagnostic } from '../../data/constant';
import { useRouter } from 'next/router';
import { css } from '@emotion/react';

const componentCSS = {
  self: css`
    margin-bottom: 0.3rem;
    .bar_wrapper {
      position: relative;
    }
  `,
  tdah_percentage: (adhdPercentage: number) => css`
    position: absolute;
    background-color: grey;
    height: 15px;
    width: 15px;
    top: 0;
    left: ${adhdPercentage}px;
    border-radius: 50%;
  `,
};
const PercentageDiagnostic: React.FC<{
  diagnostic;
  studentPercentage;
  adhdPercentage;
}> = ({ diagnostic, studentPercentage, adhdPercentage }) => {
  const { locale } = useRouter();

  return (
    <div
      className={`${diagnostic} flex_row_btw_center`}
      style={{ gap: '10px' }}
      css={componentCSS.self}
    >
      <div className="bar_wrapper" style={{ flex: '1.8' }}>
        <div css={componentCSS.tdah_percentage(adhdPercentage)}></div>
        <ProgressBar percentage={studentPercentage} />
      </div>
      <div
        className={'flex_align_center_no_wrap'}
        style={{ gap: '5px', flex: 1 }}
      >
        <span className="font_size_23_medium">
          {' '}
          {Math.round(studentPercentage) + '%'}
        </span>
        <span className="font_size_23_light">
          {' '}
          {translateDiagnostic[locale][diagnostic]}
        </span>
      </div>
    </div>
  );
};
export default PercentageDiagnostic;
