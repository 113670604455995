/* eslint-disable */

import { IPatientDetail } from '../../../features/evaluation/type';
import { TLanguages } from '../type';

// eslint-disable-next-line no-unused-vars
const patientDetails: { [key in TLanguages]: IPatientDetail } = {
  eng: {
    diagnosticsQuestions: true,
    userId: 'clgyz2fbt00079k71wr3ywsin',
    patientId: '4',

    evaluationType: 'tdah',
    isUnderReview: false,
    processEvaluationId: '14',
    infosProcessEvaluation: null,
    dateEvaluation: null,
  },
  fr: {
    diagnosticsQuestions: true,
    userId: 'clgyz2fbt00079k71wr3ywsin',
    patientId: '4',
    evaluationType: 'tdah',
    isUnderReview: false,
    processEvaluationId: '',
    infosProcessEvaluation: null,
    dateEvaluation: null,
  },
  ptbr: {
    diagnosticsQuestions: true,
    userId: 'clgyz2fbt00079k71wr3ywsin',
    patientId: '4',
    evaluationType: 'tdah',
    isUnderReview: false,
    processEvaluationId: '2',
    infosProcessEvaluation: null,
    dateEvaluation: null,
  },
  es: {
    diagnosticsQuestions: true,
    userId: 'clgyz2fbt00079k71wr3ywsin',
    patientId: '4',
    evaluationType: 'tdah',
    isUnderReview: false,
    processEvaluationId: '2',
    infosProcessEvaluation: null,
    dateEvaluation: null,
  },
};
const getPatientDetailsUseCase = (language: TLanguages): IPatientDetail =>
  patientDetails[language];

let mockSession = {
  data: {
    accessToken: 'ya29.a0AeTM1ic4oI_IHq2t0tWOm2bPsQ1fY76oYnsO5FOVefQ0165',
    expires: '2023-01-10T14:35:57.450Z',
    user: {
      name: 'nabil libre',
      email: 'etranger926@gmail.com',
      image:
        'https://lh3.googleusercontent.com/a/AEdFTp7ZhYqjnCX7pgJiddWU4Bx2mg5cL12Z4S0B-Tnu=s96-c',
      role: 'teacher',
      emailVerified: true,
      id: 'clccl31js0004pfa68lgj7mx1',
    },
  },
  status: 'authenticated',
};

export { getPatientDetailsUseCase, mockSession };
