import { css } from '@emotion/react';
import _ from 'lodash';
import { useSession } from 'next-auth/react';
import Image from 'next/image';
import { useRouter } from 'next/router';
import * as React from 'react';
import { useEffect, useState } from 'react';
import ImageUploading from 'react-images-uploading';
import IconsLogos from '../../../components/elements/IconsLogos';
import FormDefault from '../../forms/components/FormDefault';
import {
  checkAuthorisationToRequireImage,
  checkIfWeRestoreAuthorisationToRequireImage,
  initVariablesLocaleStorage,
  updateOrCreatesVariablesLocalStorage,
} from '../../../services/api/generator/function';
import errors from '../../../services/data/json/errors.json';
import { ITheme } from '../../../services/style/type';
import customiseData from '../data/customiseData.json';
import { errorCSS } from '../../forms';

const UploadOwnPicture: React.FC<{
  // eslint-disable-next-line no-unused-vars
  returnImagesArray: (x: any, b: boolean) => void;
  isUploadedPicture: boolean;
}> = ({ returnImagesArray, isUploadedPicture }) => {
  const componentCSS = {
    self: (props: ITheme) => css`
      button {
        text-transform: capitalize;
        p {
          color: #503f95 !important;
          text-align: center;
        }
      }

      svg {
        font-size: 4rem;
        color: ${props.background.fifth};
      }
      .image-item {
        margin-top: 1rem;
      }
    `,
  };
  const { locale } = useRouter();
  const [images, setImages] = useState([]);
  const maxNumber = 69;
  useEffect(() => {
    if (!isUploadedPicture && images && images.length > 0) setImages([]);
  });
  const onChange = (imageList) => {
    setImages(imageList);
    const result = {
      statusCode: 200,
      image_url:
        imageList && imageList.length > 0 ? imageList[0]['data_url'] : null,
    };
    returnImagesArray(result, true);
  };

  return (
    <div className="App" css={componentCSS.self}>
      <h2 className="font_size_26_medium">
        {customiseData[locale].introduction.title[1]}
      </h2>
      <p className="font_size_22_book">
        {customiseData[locale].introduction.paragraph[1]}
      </p>
      <div className="flex_align_center_justify_center">
        <ImageUploading
          multiple={false}
          value={images}
          onChange={onChange}
          maxNumber={maxNumber}
          dataURLKey="data_url"
        >
          {({
            imageList,
            onImageUpload,
            // onImageUpdate,
            onImageRemove,
            isDragging,
            dragProps,
          }) => (
            <div>
              <button
                className="button_no_decor"
                style={isDragging ? { color: 'red' } : undefined}
                onClick={onImageUpload}
                {...dragProps}
              >
                <IconsLogos type={'upload'} />
              </button>
              &nbsp;
              {imageList.map((image, index) => (
                <div key={index} className="image-item">
                  <Image
                    src={image['data_url']}
                    alt=""
                    width="80"
                    height="80"
                  />
                  <div
                    className="image-item__btn-wrapper"
                    style={{ textAlign: 'center' }}
                  >
                    {/* <button
                      className="button_no_decor"
                      onClick={() => onImageUpdate(index)}
                    >
                      Update
                    </button> */}
                    <button
                      className="button_no_decor"
                      onClick={() => onImageRemove(index)}
                    >
                      <p className="font_size_22_book">
                        {customiseData[locale].divers[0]}
                      </p>
                    </button>
                  </div>
                </div>
              ))}
            </div>
          )}
        </ImageUploading>
      </div>
    </div>
  );
};
const ApiPromptGenerator = ({
  title,
  text,
  typeForm,
  functionToApply,
  isUploadedPicture,
}) => {
  const [authorisationToRequieAnImage, setAuthorisationToRequireAnImage] =
    useState(true);
  const componentCSS = {
    self: (props: ITheme) => css`
      color: ${props.background.fifth};
      textarea {
        height: 5rem;
        border-radius: 5px !important;
        padding: 0.5rem;
      }
      .submit_form {
        text-transform: capitalize;
        background: linear-gradient(to right, #e85c9e, #47adce);
        font-size: 1.6em;
        text-transform: capitalize;
        font-weight: bold;
        color: white !important;
      }
    `,
  };
  useEffect(() => {
    initVariablesLocaleStorage();
    setAuthorisationToRequireAnImage(checkAuthorisationToRequireImage());
  }, []);

  const handleOnRefetch = (result) => {
    updateOrCreatesVariablesLocalStorage();
    checkIfWeRestoreAuthorisationToRequireImage();
    const aut = checkAuthorisationToRequireImage();
    setAuthorisationToRequireAnImage(aut);
    functionToApply(result, aut);
  };
  const { data: session } = useSession();
  // @ts-ignore
  const userId = session && session.user ? session.user.id : null;

  const { locale } = useRouter();
  const copyForm = _.cloneDeep(customiseData[locale].requestImageApiGenerator);
  copyForm.fields[1].defaultValue = typeForm;
  copyForm.fields[2].defaultValue = userId;

  return (
    <div css={componentCSS.self} data-testid="description-api">
      <h2 className="font_size_26_medium"> {title}</h2>
      <p className="font_size_22_book">{text}</p>
      {authorisationToRequieAnImage ? (
        <FormDefault {...copyForm} functionToApply={handleOnRefetch} />
      ) : (
        <p className="font_size_22_book" css={errorCSS}>
          {' '}
          {errors[locale].maximumCallsReached}
        </p>
      )}
      <UploadOwnPicture
        returnImagesArray={(result) => functionToApply(result, true, true)}
        isUploadedPicture={isUploadedPicture}
      />
    </div>
  );
};

export default ApiPromptGenerator;
