import React, { useEffect, useState } from 'react';
import { motion, useAnimation } from 'framer-motion';

const ProgressBar = ({ percentage }) => {
  const [width, setWidth] = useState(0);
  const controls = useAnimation();

  useEffect(() => {
    controls.start({ width: `${percentage}%` });
  }, [controls, percentage]);

  useEffect(() => {
    setWidth(percentage);
  }, [percentage]);

  return (
    <div className="progress-bar-container">
      <motion.div
        data-testid={'progress-bar'}
        className="progress-bar"
        style={{ width: width + '%', borderRadius: '10px' }}
        // animate={controls}
      />
    </div>
  );
};

export default ProgressBar;
