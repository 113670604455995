/** @jsxImportSource @emotion/react */
import React from 'react';

import { errorCSS, inputCSS, labelCSS } from './sharedCSS';
import { TFormField } from '../../type';

type Props = {
  data: TFormField;
};

const componentCSS = {
  input: inputCSS,
};
const InputUI: React.FC<Props> = ({
  data: {
    register,
    name,
    errors,
    type,
    placeHolder,
    label,
    secretField,
    defaultValue,
    updateValue,
  },
}) => {
  React.useEffect(() => {
    updateValue(name, defaultValue);
  }, [defaultValue]);

  return (
    <div
      className="flex_column input_form"
      css={{ display: secretField ? 'none !important' : 'flex' }}
    >
      <label
        className="font_size_26_medium"
        htmlFor={label}
        css={labelCSS}
        key={name}
        aria-labelledby={label}
      >
        {label}
      </label>
      <div className="flex_column">
        <input
          className="font_size_26_light"
          name="inputUI"
          css={componentCSS.input}
          type={type}
          defaultValue={defaultValue}
          data-testid={`form-${name}-testID`}
          {...register}
          aria-invalid={errors[name] ? errors[name].message : ''}
          placeholder={placeHolder}
        />
        <span className="font_size_22_light" css={errorCSS}>
          {errors[name] ? errors[name].message : ''}
        </span>
      </div>
    </div>
  );
};

export default InputUI;
