import AnalyseEvaluation from './components/AnalyseEvaluation';
import { componentCSS } from './components/EvaluationSubmitButton';
import NewProcessEvaluation from './components/NewProcessEvaluation';
import { evaluationsType } from './data/constant';
import evaluationData from './data/evaluation.json';
import { constructEvaluation, generateDataForChart } from './service/function';
import {
  getEvaluation,
  getInformationsOfAProcessEvaluation,
} from './service/util';
import { IEvaluation, TEvaluationType } from './type';

export {
  AnalyseEvaluation,
  evaluationData,
  getEvaluation,
  getInformationsOfAProcessEvaluation,
  constructEvaluation,
  generateDataForChart,
  evaluationsType,
  componentCSS,
  NewProcessEvaluation,
};
export type { TEvaluationType, IEvaluation };
