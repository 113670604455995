import { useRouter } from 'next/router';
import IconsLogos from '../../../../components/elements/IconsLogos';
import { addSpaceBeforeCapital } from '../../../../services/common';
import { themeStyle } from '../../../../services/style/constant';

export const EvaluationDateComponent = ({ date }) => {
  return (
    <div className="flex_align_center_no_wrap evaluation_date">
      <IconsLogos type="calandar" />
      <p
        className="process_eval_h3 font_size_25_medium"
        style={{ margin: 0, whiteSpace: 'nowrap', marginLeft: '1vw' }}
      >
        {date}
      </p>
    </div>
  );
};
export const EvalTypeComponent = ({ evaluationType }) => {
  return (
    <div className="flex_align_center_no_wrap evaluation_type">
      <IconsLogos type="search" />
      <p
        className="process_eval_h3 font_size_25_medium"
        style={{ textTransform: 'uppercase', margin: 0, marginLeft: '.5rem' }}
      >
        {evaluationType}
      </p>
    </div>
  );
};
const EvaluationDate = ({ date, evaluationType }) => {
  const { locale } = useRouter();
  const formatEvaluationType = (evaluationType, locale) => {
    if (evaluationType === 'tdah' && locale !== 'ptbr' && locale !== 'fr') {
      return 'adhd';
    }
    return addSpaceBeforeCapital(evaluationType);
  };

  return (
    <div
      className="evaluation_date flex_align_center_no_wrap"
      style={{ gap: 'min(5.7%, 20px' }}
    >
      {date && <EvaluationDateComponent date={date} />}
      {date && (
        <span
          style={{
            width: '2px',
            height: '1.9dvh',
            border: `1px solid ${themeStyle.colorsText.primary}`,
          }}
          className="separator"
        ></span>
      )}
      <EvalTypeComponent
        evaluationType={formatEvaluationType(evaluationType, locale)}
      />
    </div>
  );
};

export default EvaluationDate;
