import { ThemeProvider } from '@emotion/react';
import { SessionProvider } from 'next-auth/react';
import type { AppProps } from 'next/app';
import Router from 'next/router';
import NProgress from 'nprogress';
import { ErrorBoundary } from 'react-error-boundary';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import SuperLayout from '../components/layouts/SuperLayout';
import ErrorComponentBoundary from '../components/utils/ErrorComponentBoundary';
import Auth from '../features/authentification/components/Auth';
import WebSiteContext, {
  UpdateWebSiteContext,
} from '../services/contexts/WebSiteContext';
import { themeStyle } from '../services/style/constant';
import '../styles/globals.css';
Router.events.on('routeChangeStart', () => NProgress.start());
Router.events.on('routeChangeComplete', () => NProgress.done());
Router.events.on('routeChangeError', () => NProgress.done());

export default function App({
  Component,
  pageProps: { session, ...pageProps },
}: AppProps) {
  const { webSiteTheme, handleWebSiteTheme } = UpdateWebSiteContext();

  // useEffect(() => {
  //   if ('serviceWorker' in navigator) {
  //     navigator.serviceWorker
  //       .register('/worker.js', { scope: '/' })
  //       .then((registration) => {
  //         console.log(
  //           'Service Worker registered with scope:',
  //           registration.scope
  //         );
  //       })
  //       .catch((error) => {
  //         console.error('Service Worker registration failed:', error);
  //       });
  //   }
  // }, []);
  return (
    <ThemeProvider theme={themeStyle}>
      <WebSiteContext.Provider value={{ webSiteTheme, handleWebSiteTheme }}>
        <QueryClientProvider client={new QueryClient()}>
          <SessionProvider
            session={session} // Re-fetch session every 5 minutes
            refetchInterval={5 * 60}
            // Re-fetches session when window is focused
            refetchOnWindowFocus={false}
          >
            <SuperLayout>
              {/* @ts-ignore:next-line */}
              {Component.auth ? (
                <Auth>
                  <ErrorBoundary
                    FallbackComponent={() => (
                      <ErrorComponentBoundary type={'appError'} />
                    )}
                    onReset={() => {
                      // reset the state of your app so the error doesn't happen again
                    }}
                  >
                    {/*//@ts-ignore */}
                    <Component {...pageProps} />
                  </ErrorBoundary>
                </Auth>
              ) : (
                <ErrorBoundary
                  FallbackComponent={() => (
                    <ErrorComponentBoundary type={'appError'} />
                  )}
                  onReset={() => {
                    // reset the state of your app so the error doesn't happen again
                  }}
                >
                  {/*//@ts-ignore */}
                  <Component {...pageProps} />
                </ErrorBoundary>
              )}
            </SuperLayout>
          </SessionProvider>
          <ReactQueryDevtools
            initialIsOpen={process.env.NEXT_PUBLIC_DEVELOPMENT == 'true'}
          />
        </QueryClientProvider>
      </WebSiteContext.Provider>
    </ThemeProvider>
  );
}
