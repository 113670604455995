import React, { useEffect } from 'react';

// import draftToHtml from 'draftjs-to-html';
import { css } from '@emotion/react';
import { convertToRaw } from 'draft-js';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { TFormField, formsData, errorCSS } from '../../features/forms';

// import htmlToDraft from 'html-to-draftjs';
const Editor = dynamic<any>(
  () => import('react-draft-wysiwyg').then((mod) => mod.Editor),
  { ssr: false }
);
type Props = {
  data: {
    editorState: { getCurrentContent: Function };
    handleEditorState: Function;
  };
  formDataControl: TFormField;
};

const componentCSS = {
  self: css({
    '& .demo-wrapper': {
      padding: '1rem',
      '@media (min-width: 550px)': {
        padding: '2rem',
      },
      borderRadius: '5px',
      border: '1px solid #d2d2d2',
      minHeight: '300px',
    },
  }),
};

const RichEditor: React.FC<Props> = ({
  data: { editorState, handleEditorState },
  formDataControl,
}) => {
  const { locale } = useRouter();
  const toSaveToDB = JSON.stringify(
    convertToRaw(editorState.getCurrentContent())
  );
  const isContentText = editorState.getCurrentContent().getPlainText().trim();
  useEffect(() => {
    formDataControl.updateValue('journey', isContentText ? toSaveToDB : null);
  }, [isContentText]);

  return (
    <div css={componentCSS.self}>
      {/*//@ts-ignore */}
      <Editor
        placeholder={formsData[locale].editor.placeholder}
        editorState={editorState}
        id="editor_id"
        toolbarClassName="toolbar-class"
        wrapperClassName="demo-wrapper"
        editorClassName="demo-editor"
        onEditorStateChange={handleEditorState}
        toolbar={{
          options: [
            'inline',
            'blockType',
            'fontSize',
            'fontFamily',
            'colorPicker',
            // 'link',
            // 'embedded',
            'emoji',
            // 'image',
            // 'remove',
            'history',
          ],
          inline: {
            options: [
              'bold',
              'italic',
              'underline',
              // 'strikethrough',
              // 'monospace',
              // 'superscript',
              // 'subscript',
            ],
          },

          blockType: {
            inDropdown: true,
            options: [
              'Normal',
              'H1',
              'H2',
              'H3',
              // 'H4',
              // 'H5',
              // 'H6',
              // 'Blockquote',
              // 'Code',
            ],
            className: 'font_size_26_bold',
            component: undefined,
            dropdownClassName: undefined,
          },
          fontSize: {
            // icon: fontSize,
            options: [8, 9, 10, 11, 12, 14, 16, 18, 24, 30, 36, 48, 60, 72, 96],
            className: undefined,
            component: undefined,
            dropdownClassName: undefined,
          },
          fontFamily: {
            options: [
              'Arial',
              'Georgia',
              'Impact',
              'Tahoma',
              'Times New Roman',
              'Verdana',
            ],
            className: undefined,
            component: undefined,
            dropdownClassName: undefined,
          },
        }}
      />
      <span css={errorCSS} className="font_size_22_light">
        {formDataControl.errors.journey && !isContentText
          ? formDataControl.errors.journey.message
          : ''}
      </span>
    </div>
  );
};
export default RichEditor;
