import axios from 'axios';
import * as React from 'react';
import { Controller } from 'react-hook-form';
import { useMutation } from 'react-query';
import CreatableSelect from 'react-select/creatable';
import filteringData from '../data/filteringData.json';
import { useRouter } from 'next/router';
import { routesOut, statusServer } from '../../../../services/bridge';
import ComponentWithLogicDataFetching from '../../../../components/utils/ComponentWithLogicDataFetching';
import {
  TMultiSelection,
  TOption,
} from '../../../../components/forms/selectSchoolClass/School';
import { IBodyNewSchool } from '../../../../backend/institution';
import { getSchools } from '../../../user';
import { customStylesSelection, errorCSS, labelCSS } from '../../../forms';

interface ISchoolsHTML {
  props: {
    label: string;
    name: string;
    control: any;
    options: TOption[];
    countrySelectedId: number;
    isLoading: boolean;
    placeHolder: string;
    errors: any;
    updateValue: any;
    refetch: any;
    tractValuesForm: any;
  };
}

const SchoolsHTML: React.FC<ISchoolsHTML> = ({
  props: {
    label,
    name,
    control,
    options,
    isLoading,
    placeHolder,
    errors,
    refetch,
    tractValuesForm,
  },
}) => {
  const { locale } = useRouter();
  const country = tractValuesForm('country');
  const newSchool = useMutation(
    (newSchool: IBodyNewSchool) =>
      axios
        .post(routesOut.data.newSchool.link, newSchool)
        .then((result) => result.data),
    {
      onSuccess: () => {
        //! tofix all of my queries does not show up in devtool. form and react-query must be checked for more infos
        refetch();
        // queryClient.invalidateQueries(['schools', generalValue.school.id]);
      },
    }
  );

  const handleCreate = (input: string) => {
    const data: IBodyNewSchool = {
      name: input,
      countryId: country.id,
    };
    newSchool.mutate(data);
  };
  const IDSelect = React.useId();
  return (
    <div className="flex_column" data-testid={`form-${name}-testID`}>
      <label className="font_size_26_bold" htmlFor={IDSelect} css={labelCSS}>
        {label}
      </label>
      <Controller
        name={name}
        render={({ field }) => (
          <CreatableSelect
            styles={customStylesSelection}
            formatCreateLabel={(inputText) =>
              `${filteringData[locale].selects.create}: "${inputText}"`
            }
            aria-labelledby={IDSelect}
            inputId={IDSelect}
            instanceId={IDSelect}
            onChange={field.onChange}
            placeholder={placeHolder}
            ref={field.ref}
            options={options}
            {...field}
            isLoading={isLoading}
            isClearable
            isSearchable
            onCreateOption={handleCreate}
          />
        )}
        control={control}
      />
      <span css={errorCSS} className="font_size_22_light">
        {errors[name] && errors[name].value ? errors[name].value.message : ''}
      </span>
    </div>
  );
};

interface IScreenTwo extends TMultiSelection {
  isDefaultValue: any;
}
export const ScreenTwo: React.FC<IScreenTwo> = ({
  data: {
    label,
    name,
    control,
    placeHolder,
    errors,
    updateValue,
    tractValuesForm,
    secretField,
  },
  isDefaultValue,
}) => {
  const country = tractValuesForm('country');
  const { data, isLoading, isError, refetch } = getSchools(
    country ? country.id : null
  );
  const options =
    data && data.data && data.status === statusServer.success
      ? data.data.map((school) => {
          return {
            id: school.id,
            label: school.name,
            value: school.name,
            countryId: country ? country.id : null,
          };
        })
      : [];
  React.useEffect(() => {
    if (isDefaultValue && options && options.length && options.length > 0) {
      const isSchoolAlreadyRegisteredDB = options.filter(
        (opt) => opt.id == isDefaultValue.id && opt.name == isDefaultValue.name
      );
      if (isSchoolAlreadyRegisteredDB.length > 0) {
        return updateValue(name, {
          id: isSchoolAlreadyRegisteredDB[0].id,
          ...isSchoolAlreadyRegisteredDB[0],
        });
      } else {
        updateValue(name, {
          id: null,
          value: isDefaultValue.value,
          label: isDefaultValue.label,
        });
      }
    } else if (isDefaultValue) {
      updateValue(name, {
        id: null,
        value: isDefaultValue.value,
        label: isDefaultValue.label,
      });
    } else {
      updateValue(name, {
        id: null,
        value: null,
        label: null,
      });
    }
  }, [isDefaultValue, data && data.status, country]);
  if (secretField) return;
  const SelectSchoolHTML = ComponentWithLogicDataFetching(SchoolsHTML);

  return (
    <SelectSchoolHTML
      isErrorServer={isError && !data}
      isLoading={false}
      props={{
        label,
        name,
        control,
        options,
        isLoading,
        placeHolder,
        errors,
        refetch,
        tractValuesForm,
      }}
    />
  );
};
