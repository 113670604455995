import { TEvaluationType } from '../../features/evaluation';
import { ERoles, RoutesIn, RoutesOut } from './type';

const routesIn: RoutesIn = {
  eng: {
    home: { link: '/', rolesAuthorised: [] },
    admin: { link: '/admin', rolesAuthorised: [ERoles.admin] },
    login: {
      link: '/',
      rolesAuthorised: [],
    },
    students: {
      link: '/students',
      rolesAuthorised: [
        ERoles.teacher,
        ERoles.professional,
        ERoles.parent,
        ERoles.restricted,
        ERoles.student,
        ERoles.admin,
        ERoles.gp,
        ERoles.family,
        ERoles.partner,
        ERoles.specialist,
        ERoles.teacher,
        ERoles.teacherAssistant,
        ERoles.other,
      ],
    },
    logOut: { link: '/logOut', rolesAuthorised: [] },
    signIn: { link: '/logOut', rolesAuthorised: [] },
    student: {
      rolesAuthorised: [
        ERoles.teacher,
        ERoles.professional,
        ERoles.parent,
        ERoles.restricted,
        ERoles.student,
        ERoles.admin,
        ERoles.student,
        ERoles.admin,
        ERoles.gp,
        ERoles.family,
        ERoles.partner,
        ERoles.specialist,
        ERoles.teacher,
        ERoles.teacherAssistant,
        ERoles.other,
      ],
      path: '/student/',
      id: (ID: string) => {
        return { link: `/student/${ID}` };
      },
      evaluation: (
        patientId: string,
        processEvaluationId: string,
        evalType: string,
      ) => {
        return {
          link: `/student/${patientId}/evaluation/${processEvaluationId}/details/${evalType}`,
          // !refactor
          rolesAuthorised: [],
        };
      },
      interpretation: (
        patientId: string,
        evaluationId: string,
        evalType: TEvaluationType,
      ) => {
        return {
          link: `/student/${patientId}/evaluation/${evaluationId}/interpration/${evalType}`,
          rolesAuthorised: [],
        };
      },
      journey: (
        patientId: string,
        processEvaluationID: string,
        evalType: string,
      ) => {
        return {
          link: `/student/${patientId}/evaluation/${processEvaluationID}/journey/${evalType}`,
          rolesAuthorised: [],
        };
      },
      preconisation: (
        studentId: string,
        evaluationId: string,
        evalType: TEvaluationType,
      ) => {
        return {
          rolesAuthorised: [
            ERoles.teacher,
            ERoles.professional,
            ERoles.parent,
            ERoles.restricted,
            ERoles.student,
            ERoles.admin,
            ERoles.student,
            ERoles.admin,
            ERoles.gp,
            ERoles.family,
            ERoles.partner,
            ERoles.specialist,
            ERoles.teacher,
            ERoles.teacherAssistant,
            ERoles.other,
          ],
          link: `/student/${studentId}/evaluation/${evaluationId}/preconisation/${evalType}`,
        };
      },
      evolution: (
        studentId: string,
        processEvaluationId: string,
        evalType: TEvaluationType,
      ) => {
        return {
          link: `/student/${studentId}/evaluation/${processEvaluationId}/evolution/${evalType}`,
          rolesAuthorised: [],
        };
      },
    },
    customise: {
      rolesAuthorised: [
        ERoles.teacher,
        ERoles.professional,
        ERoles.parent,
        ERoles.restricted,
        ERoles.student,
        ERoles.admin,
        ERoles.student,
        ERoles.admin,
        ERoles.gp,
        ERoles.family,
        ERoles.partner,
        ERoles.specialist,
        ERoles.teacher,
        ERoles.teacherAssistant,
        ERoles.other,
      ],
      link: '/customise',
      path: '/customise/',
      avatar: {
        link: '/customise/avatar',
        rolesAuthorised: [],
      },
      banners: {
        link: '/customise/banners',
        rolesAuthorised: [],
      },
      strap: {
        link: '/customise/strap',
        rolesAuthorised: [],
      },
    },

    notification: {
      link: '/notification',
      rolesAuthorised: [],
    },
    tutorial: {
      link: process.env.NEXT_PUBLIC_LINK_TUTORIAL,
      rolesAuthorised: [],
    },
    securityCheck: { link: '/newUser/securityCheck', rolesAuthorised: [] },
    contact: { link: '/', rolesAuthorised: [] },
    agreement: { link: '/agreement', rolesAuthorised: [] },
    form: { link: '/newUser', rolesAuthorised: [] },
    glossary: {
      eng: {
        link: 'https://www.addhere.co.uk/glossary',
        rolesAuthorised: [],
      },
      fr: {
        link: ' https://www.addhere.co.uk/privacy',
        rolesAuthorised: [],
      },
      ptbr: {
        link: 'https://addhere.com.br/glossario/',
        rolesAuthorised: [],
      },
      es: {
        link: 'https://addhere.com.br/glossario/',
        rolesAuthorised: [],
      },
      zh: {
        link: 'https://www.addhere.co.uk/glossary',
        rolesAuthorised: [],
      },
    },
    politicOfPrivacy: {
      eng: {
        link: ' https://www.addhere.co.uk/privacy',
        rolesAuthorised: [],
      },
      fr: {
        link: ' https://www.addhere.co.uk/privacy',
        rolesAuthorised: [],
      },
      ptbr: {
        link: 'https://addhere.com.br/politica-privacidade/',
        rolesAuthorised: [],
      },
      es: {
        link: ' https://www.addhere.co.uk/privacy',
        rolesAuthorised: [],
      },
      zh: {
        link: ' https://www.addhere.co.uk/privacy',
        rolesAuthorised: [],
      },
    },
  },
  roles: [
    ERoles.admin,
    ERoles.guest,
    ERoles.teacher,
    ERoles.professional,
    ERoles.parent,
    ERoles.restricted,
    ERoles.student,
    ERoles.student,
    ERoles.admin,
    ERoles.gp,
    ERoles.family,
    ERoles.partner,
    ERoles.specialist,
    ERoles.teacher,
    ERoles.teacherAssistant,
    ERoles.other,
  ],
};
const routesOut: RoutesOut = {
  roles: [ERoles.admin, ERoles.guest],
  data: {
    path: '/api/data',
    rolesAuthorised: [
      ERoles.teacher,
      ERoles.professional,
      ERoles.parent,
      ERoles.restricted,
      ERoles.student,
      ERoles.student,
      ERoles.admin,
      ERoles.gp,
      ERoles.family,
      ERoles.partner,
      ERoles.specialist,
      ERoles.teacher,
      ERoles.teacherAssistant,
      ERoles.other,
    ],
    test: {
      link: '/api/data/protected/postDataExample',
      rolesAuthorised: [],
    },
    saveDataGeneratorImage: {
      link: '/api/data/protected/saveDataGeneratorImage',
      rolesAuthorised: [],
    },
    characteristicAvatar: {
      link: '/api/data/protected/characteristicAvatar',
      rolesAuthorised: [],
    },
    updateJourneyPrivilege: {
      link: '/api/data/protected/updateJourneyPrivilege',
      rolesAuthorised: [],
    },
    saveColorHeaderFooter: {
      link: '/api/data/protected/saveColorHeaderFooter',
      rolesAuthorised: [],
    },
    requestImageApiGenerator: {
      link: '/api/data/protected/requestImageApiGenerator',
      rolesAuthorised: [],
    },
    submitStrap: {
      link: '/api/data/protected/submitStrap',
      rolesAuthorised: [],
    },
    newPatient: {
      link: '/api/data/protected/newPatient',
      rolesAuthorised: [],
    },
    updateDataBase: {
      link: '/api/data/protected/updateDataBase',
      rolesAuthorised: [],
    },
    updatePreconisationMetrics: {
      link: '/api/data/protected/updatePreconisationMetrics',
      rolesAuthorised: [],
    },
    newJourney: {
      link: '/api/data/protected/newJourney',
      rolesAuthorised: [],
    },
    defineTheNewPassword: {
      link: '/api/data/protected/defineTheNewPassword',
      rolesAuthorised: [],
    },
    userInscription: {
      link: '/api/data/userInscription',
      rolesAuthorised: [],
    },
    newComment: {
      link: '/api/data/protected/newComment',
      rolesAuthorised: [],
    },
    newConsentForm: {
      link: '/api/data/protected/newConsentForm',
      rolesAuthorised: [],
    },
    newCommentJourney: {
      link: '/api/data/protected/newCommentJourney',
      rolesAuthorised: [],
    },
    updateUserDetails: {
      link: '/api/data/protected/updateUserDetails',
      rolesAuthorised: [],
    },
    updateAuthorisationEmailing: {
      link: '/api/data/protected/updateAuthorisationEmailing',
      rolesAuthorised: [],
    },
    newProcessEvaluation: {
      link: '/api/data/protected/newProcessEvaluation',
      rolesAuthorised: [],
    },

    newSchool: {
      link: '/api/data/newSchool',
      rolesAuthorised: [],
    },
    newUserToProcessEvaluation: {
      link: '/api/data/protected/newUserToProcessEvaluation',
      rolesAuthorised: [],
    },
    newClass: {
      link: '/api/data/newClass',
      rolesAuthorised: [],
    },
    newRestrictedUser: {
      link: '/api/data/newRestrictedUser',
      rolesAuthorised: [],
    },
    updatePrivileges: {
      link: '/api/data/updatePrivileges',
      rolesAuthorised: [],
    },
    updateNotificationView: {
      link: '/api/data/updateNotificationView',
      rolesAuthorised: [],
    },
    confirmAccount: {
      link: '/api/data/confirmAccount',
      rolesAuthorised: [],
    },
    askForANewPassowrd: {
      link: '/api/data/protected/askForANewPassowrd',
      rolesAuthorised: [],
    },
    isUserAlreadyRegistered: (email: string) => {
      return {
        link: `/api/data/protected/isUserAlreadyRegistered/?email=${email}`,
        rolesAuthorised: [],
      };
    },
    getStudentEvolutionDiagnostics: (studentId: string) => {
      return {
        link: `/api/data/protected/getStudentEvolutionDiagnostics/?studentId=${studentId}`,
        rolesAuthorised: [],
      };
    },
    getAdminDataTable: (pageIndex: string, pageSize: string) => {
      return {
        link: `/api/data/protected/getAdminDataTable/?pageIndex=${pageIndex}&pageSize=${pageSize}`,
        rolesAuthorised: [ERoles.admin],
      };
    },
    getPatientProfile: (id: string, userId: string) => {
      return {
        link: `/api/data/protected/getPatientProfile/?patientId=${id}&userId=${userId}`,
        rolesAuthorised: [],
      };
    },
    getJourney: (patientId: string, userId: string) => {
      return {
        link: `/api/data/protected/getJourney/?patientId=${patientId}&userId=${userId}`,
        rolesAuthorised: [],
      };
    },
    getPatients: (id: string) => {
      return {
        link: `/api/data/protected/getPatients/?userId=${id}`,
        rolesAuthorised: [],
      };
    },
    getNotifications: (id: string) => {
      return {
        link: `/api/data/protected/getNotifications/?userId=${id}`,
        rolesAuthorised: [],
      };
    },
    getUserDetails: (id: string) => {
      return {
        link: `/api/data/protected/getUserDetails/?userId=${id}`,
        rolesAuthorised: [],
      };
    },
    getSpecificPatients: (userId: string) => {
      return {
        link: `/api/data/protected/getSpecificPatients/?userId=${userId}`,
        rolesAuthorised: [],
      };
    },
    getListProcessEvaluationsOfAPatientWithUsersLinked: (
      id: string,
      userId: string,
    ) => {
      return {
        link: `/api/data/protected/getListProcessEvaluationsOfAPatientWithUsersLinked/?patientId=${id}&userId=${userId}`,
        rolesAuthorised: [],
      };
    },
    getEvaluation: (
      processEvaluationId: string,
      typeEvaluation: TEvaluationType,
      userId: string,
    ) => {
      return {
        link: `/api/data/protected/getEvaluation/?processEvaluationId=${processEvaluationId}&typeEvaluation=${typeEvaluation}&userId=${userId}`,
        rolesAuthorised: [],
      };
    },
    getComments: (userId: string, processEvaluationId: string) => {
      return {
        link: `/api/data/protected/getComments/?processEvaluationId=${processEvaluationId}&userId=${userId}`,
        rolesAuthorised: [],
      };
    },
    getSchools: (
      countriesID: (number | string)[] | null,
      userId: string | null,
    ) => {
      return {
        link: userId
          ? `/api/data/getSchools/?countryId=${countriesID}&userId=${userId}`
          : `/api/data/getSchools/?countryId=${countriesID}`,
        rolesAuthorised: [],
      };
    },
    getClasses: (
      schoolsID: (number | string)[] | null,
      userId: string | null,
    ) => {
      return {
        link: userId
          ? `/api/data/getClasses/?schoolsID=${schoolsID}&userId=${userId}`
          : `/api/data/getClasses/?schoolsID=${schoolsID}`,
        rolesAuthorised: [],
      };
    },
    getUrlS3: (fileType: string, fileName: string) => {
      return {
        link: `/api/data/s3?type=${fileType}&name=${fileName}`,
        rolesAuthorised: [],
      };
    },
    getPreconisationsEndPointURL: (
      diagnostics: string,
      role: string,
      language: string,
    ) => {
      return {
        link: `/api/data/protected/getPreconisationsEndPointURL?diagnostics=${diagnostics}&role=${role}&language=${language}`,
        rolesAuthorised: [],
      };
    },
    getCountries: {
      link: '/api/data/getCountries',
      rolesAuthorised: [],
    },
    postEvaluationProcess: {
      link: '/api/data/protected/newEvaluation',
      rolesAuthorised: [],
    },
    getInformationsOfAProcessEvaluation: (
      processEvaluationId: string,
      evaluationType: String,
      userId: String,
      doWeReturnAllEvaluations: boolean,
    ) => {
      return {
        link: `/api/data/getInformationsOfAProcessEvaluation/?processEvaluationId=${processEvaluationId}&evaluationType=${evaluationType}&userId=${userId}&doWeReturnAllEvaluations=${doWeReturnAllEvaluations}`,
        rolesAuthorised: [],
      };
    },
  },
  moreInfo: { link: 'https://eng.addhere.com.br/', rolesAuthorised: [] },
  aws: {
    buckets: {
      images: 'https://images-addhere.s3.eu-north-1.amazonaws.com/',
      videosPreconisation: 'https://predispositions.s3.amazonaws.com/',
    },
  },
};
const statusServer = {
  badRequest: 400,
  success: 200,
  internalServerError: 500,
  unauthorized: 401,
  forbidden: 403,
  notAcceptable: 406,
  notFound: 404,
};
const dynamicRoute: { [key: string]: any } = {};

export { routesIn, routesOut, statusServer, dynamicRoute };
