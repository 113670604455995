import { css } from '@emotion/react';

export const screenShadow = {
  self: css({
    backgroundColor: '#80808099',
    height: '100vh',
    width: '100vw',
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: 1000000,
  }),
};

export const primarySpaceMargin = css`
  margin-bottom: 1.5rem;
`;

const secondSpaceMargin = 0.6;
export const secondarySpaceMargin = css`
  margin-right: ${secondSpaceMargin}rem;
`;
export const secondarySpaceMarginAsGapFlex = css`
  gap: ${secondSpaceMargin}rem;
`;

export const thirdSpaceMargin = css`
  margin-bottom: 1rem;
`;
export const primarySpacePadding = css`
  padding: 0.5rem;
  @media (min-width: 400px) {
    padding: 1rem;
  }
`;
export const secondarySpacePadding = css`
  padding: 0.5rem;
  @media (min-width: 400px) {
    padding: 3rem;
  }
`;

export const sizeIconLogoAddhere = css`
  margin: auto;
  margin-top: 1rem;
  img {
    height: 100%;
  }
`;

export const sizeCircleImage = {
  high: 'min(11dvh, 154px)',
  highLight: 'min(9dvh, 135px)',
  low: 'min(4.7dvh, 60px)',
  middleHigh: 'min(7.5dvh, 95px)',
  middle: 'min(5.85dvh, 75px)',
  extraLow: 'min(5.5dvh, 40px)',
};
