import { css } from '@emotion/react';
import * as React from 'react';
import IconsLogos from '../../../components/elements/IconsLogos';
import { sizeIconLogoAddhere } from '../../../components/share';
import ComponentWithLogicDataFetching from '../../../components/utils/ComponentWithLogicDataFetching';
import { ITheme } from '../../../services/style/type';
import InterprationChart from './InterpretationChart';

const componentCSS = {
  self: (props: ITheme) =>
    css({
      '& .printer_conclusion': {
        marginBottom: '1rem',
        display: 'none',
      },
      '@media print': {
        '& .header_layout_interpretation': {
          dislay: 'none',
        },
        '& .printer_conclusion': {
          display: 'block',
        },
        '& .background_slider_interpretation': {
          width: '100vw !important',
        },
      },
      '& .loader_component': {
        '& svg': {
          stroke: `${props.background.third} !important`,
        },
      },
    }),
};

const ResultatEvaluation: React.FC<{
  patientInfos: any;
  handleScrollingCSS;
}> = ({ patientInfos, handleScrollingCSS }) => {
  // const journeysInformation = getJourney(patientInfos.id as string);
  const InterpretationHTML = ComponentWithLogicDataFetching(InterprationChart);

  return (
    <div css={componentCSS.self}>
      <div className="printer_conclusion">
        <div css={[sizeIconLogoAddhere]}>
          <IconsLogos type={'addhereHorizontal'} />
        </div>
      </div>
      {patientInfos.informationOfAnEvaluation &&
        patientInfos.patientDetailContext &&
        patientInfos.patientDetailContext.infosProcessEvaluation &&
        patientInfos.patientDetailContext.infosProcessEvaluation.length && (
          <>
            <InterpretationHTML
              isLoading={
                patientInfos.informationOfAnEvaluation.isLoading ||
                (!patientInfos.resultGetStudentProfile &&
                  !patientInfos.resultGetStudentProfile.data)
              }
              isErrorServer={
                patientInfos.informationOfAnEvaluation.isError &&
                !patientInfos.informationOfAnEvaluation.data
              }
              props={{
                infosProcessEvaluation:
                  patientInfos.patientDetailContext.infosProcessEvaluation,
                // journeysInformation: journeysInformation.data,
                diagnosticsQuestions:
                  patientInfos.resultGetStudentProfile &&
                  patientInfos.resultGetStudentProfile.data &&
                  patientInfos.resultGetStudentProfile.data
                    .diagnosticsQuestions,
                handleScrollingCSS,
              }}
            />
            {/* {!patientInfos.informationOfAnEvaluation.isLoading &&
              !patientInfos.informationOfAnEvaluation.isError && (
                <h2
                  className="font_size_26_medium"
                  style={{ textTransform: 'capitalize' }}
                >
                  {resultEvaluationData[patientInfos.locale].divers[1]}
                </h2>
              )} 
     <CommentsSection /> */}
          </>
        )}
    </div>
  );
};
export default ResultatEvaluation;
