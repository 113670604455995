/* eslint-disable */
import ErrorComponent from './ErrorComponent';
import Loader from './Loader';

const ComponentWithLogicDataFetching =
  (Component: any) =>
  ({ isErrorServer, isLoading, isErrorStatus, typeLoader, ...props }: any) => {
    if (isLoading)
      return (
        <div data-testid="testID-loading">
          <Loader typeLoader={typeLoader} />
        </div>
      );
    if (isErrorServer)
      return (
        <div data-testid="testID-error" style={{ height: '100%' }}>
          <ErrorComponent isErrorStatus={isErrorStatus} />
        </div>
      );
    return <Component data-testid="testID-component" {...props} />;
  };

export default ComponentWithLogicDataFetching;
