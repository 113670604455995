import axios from 'axios';
import { useMutation, useQuery } from 'react-query';
import { routesOut } from '../../../services/bridge';
import {
  returnFormatDateOrdinal,
  returnNewFormatDate,
} from '../../../services/common';
import { TLanguages } from '../../../services/data';
import successJson from '../../../services/data/json/success.json';
import errorJson from '../../../services/data/json/errors.json';
import { INewProcess, Privileges } from '../../evaluation/type';

export const getListProcessEvaluationsOfAPatientWithUsersLinked = (
  id: string,
  userId: string | null,
  language: TLanguages,
) => {
  return useQuery(
    ['processEvaluation', id], // this is the key
    async () => {
      const response = await axios.get(
        routesOut.data.getListProcessEvaluationsOfAPatientWithUsersLinked(
          id as string,
          userId,
        ).link,
      );
      if (response.data && response.data.length) {
        response.data = response.data.map((processEvaluation) => {
          return {
            patientId: id,
            processEvaluationId: processEvaluation.id as string,
            evaluationType: processEvaluation.evaluationType,
            userId: userId,
            listUsers: processEvaluation.users,
            createdAt: returnFormatDateOrdinal(
              returnNewFormatDate(processEvaluation.createdAt),
              language,
            ),
          };
        });
      }
      return response.data;
    },
    { enabled: !!id && !!userId, refetchOnWindowFocus: false },
  );
};

export const postNewProcess = (
  locale: TLanguages,
  refetch,
  setNotification,
) => {
  const mutation = useMutation(
    (newProcessEvaluation: INewProcess) =>
      axios.post(
        routesOut.data.newProcessEvaluation.link,
        newProcessEvaluation,
      ),
    {
      onSuccess: (response) => {
        setNotification({
          type: 'success',
          text:
            response &&
            response.data &&
            response.data.message &&
            successJson[locale][response.data.message]
              ? successJson[locale][response.data.message]
              : successJson[locale].success,
        });
        refetch();
        //! tofix all of my queries does not show up in devtool. form and react-query must be checked for more infos
        // queryClient.invalidateQueries(['processEvaluation', Number(id)]);
      },
      onError: () => {
        setNotification({ type: 'error', text: errorJson[locale].server });
      },
    },
  );

  return mutation;
};

export const updatePrivilege = (
  locale: TLanguages,
  refetch,
  setNotification,
) => {
  const mutation = useMutation(
    (privileges: Privileges) => {
      return axios.post(routesOut.data.updatePrivileges.link, privileges);
    },
    {
      onSuccess: (response) => {
        setNotification({
          type: 'success',
          text:
            response &&
            response.data &&
            response.data.message &&
            successJson[locale][response.data.message]
              ? successJson[locale][response.data.message][0]
              : successJson[locale].success,
        });
        setTimeout(() => {
          refetch();
        }, 5000);
        //! tofix all of my queries does not show up in devtool. form and react-query must be checked for more infos
        // queryClient.invalidateQueries(['processEvaluation', Number(id)]);
      },
      onError: (resp) => {
        console.log(resp);
        setNotification({ type: 'error', text: errorJson[locale].server });
      },
    },
  );

  return mutation;
};
