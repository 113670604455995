import * as React from 'react';
import Image from 'next/image';
import { css } from '@emotion/react';
import LoaderSkelaton from '../utils/LoaderSkelaton';

type TCircleImage = {
  src: string;
  width: string | null;
  height: string | null;
  alt: string;
};

const componentCSS = {
  self: css`
    position: relative;
    img,
    .loader_skelaton {
      border-radius: 50%;
    }
  `,
};
const CircleImage: React.FC<TCircleImage> = ({ src, width, height, alt }) => {
  const [loading, setLoading] = React.useState(true);
  return (
    <div
      css={[
        componentCSS.self,
        {
          height: height ? height : '80px',
          width: width ? width : '80px',
        },
      ]}
      className="circle_image"
    >
      <Image
        src={src ? src : '/assets/png/teacher.png'}
        alt={alt}
        fill={true}
        sizes="100%"
        priority
        onLoadingComplete={() => {
          setLoading(false);
        }}
      />
      {loading && (
        <div style={{ borderRadius: '50%', height: '100%', width: '100%' }}>
          <LoaderSkelaton />
        </div>
      )}
    </div>
  );
};
export default CircleImage;
