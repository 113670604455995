import { css } from '@emotion/react';
import ReactDOM from 'react-dom';
import ButtonOriginal from '../buttons/ButtonOriginal';
import FormWrapper from '../wrappers/FormWrapper';

const componentCSS = {
  self: css`
    .button_original {
      background-color: grey !important;
    }
  `,
};
const Alert = ({ message, onConfirm, answers }) => {
  const handleConfirm = (response) => {
    onConfirm(response);
  };

  return ReactDOM.createPortal(
    <FormWrapper>
      <div css={componentCSS.self}>
        <p className="font_size_28_bold">{message}</p>
        <div
          className="flex_align_center_justify_center_wrap"
          style={{ gap: '1vw' }}
        >
          <ButtonOriginal
            text={answers[0]}
            icon={''}
            functionToApply={() => handleConfirm(true)}
          />
          <ButtonOriginal
            text={answers[1]}
            icon={''}
            functionToApply={() => handleConfirm(false)}
          />
        </div>
      </div>
    </FormWrapper>,
    document.body
  );
};

export default Alert;
