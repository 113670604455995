import { INewProcess, Privileges } from '../evaluation/type';
import { Evaluations } from './components/Evaluations';
import EvaluationDate, {
  EvalTypeComponent,
  EvaluationDateComponent,
} from './components/elements/EvaluationDate';
import EvaluationProgress from './components/elements/EvaluationProgress';
import evaluations from './data/evaluations.json';
import { getListProcessEvaluationsOfAPatientWithUsersLinked } from './service/util';
export {
  evaluations,
  EvaluationDateComponent,
  EvalTypeComponent,
  getListProcessEvaluationsOfAPatientWithUsersLinked,
  EvaluationDate,
  EvaluationProgress,
  Evaluations,
};
export type { INewProcess, Privileges };
