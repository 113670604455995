/** @jsxImportSource @emotion/react */
import React, { useEffect } from 'react';
import Select from 'react-select';

import { useRouter } from 'next/router';
import { Controller } from 'react-hook-form';
import { evaluationData } from '../../../evaluation';
import { TFormField } from '../../type';
import { getFieldForForm } from './GetFieldForForm';
import { customStylesSelection, errorCSS, labelCSS } from './sharedCSS';
type TMultiSelection = {
  data: TFormField;
};

const MultiSelection: React.FC<TMultiSelection> = ({
  data: {
    label,
    options,
    errors,
    name,
    control,
    openField,
    defaultValue,
    updateValue,
  },
}) => {
  options = options.map((option, index) => {
    return { ...option, index: index.toString() };
  });
  const { locale } = useRouter();
  useEffect(() => {
    if (defaultValue) {
      updateValue(name, {
        label: options[defaultValue].label,
        value: options[defaultValue].value,
        index: defaultValue,
      });
    }
  }, []);
  return (
    <div>
      <div
        className="flex_column multiselection_form_field"
        data-testid={`form-${name}-testID`}
      >
        <label className="font_size_26_medium" htmlFor={label} css={labelCSS}>
          {label}
        </label>
        <div className="flex_column">
          <Controller
            name={name}
            render={({ field }) => {
              if (!field.value && defaultValue) {
                if (/^\d+$/.test(defaultValue)) {
                  field.value = {
                    label: options[defaultValue].label,
                    value: options[defaultValue].value,
                    index: defaultValue,
                  };
                } else {
                  field.value = { label: defaultValue, value: defaultValue };
                }
              }
              return (
                <Select
                  styles={customStylesSelection}
                  inputId={label}
                  aria-labelledby={label}
                  instanceId={label}
                  value={field.value}
                  onChange={field.onChange}
                  classNamePrefix="multiselection_class"
                  className="multiselection_class"
                  ref={field.ref}
                  options={options}
                  {...field}
                  placeholder={evaluationData[locale].selects.default}
                  // menuIsOpen={true}
                />
              );
            }}
            control={control}
          />
          <span className="font_size_22_light" css={errorCSS}>
            {errors[name] ? errors[name].label.message : ''}
          </span>
        </div>
      </div>
      {openField &&
        openField.tractValuesForm(openField.sibling) &&
        (openField.tractValuesForm(openField.sibling).value == 'yes' ||
          openField.tractValuesForm(openField.sibling).value == 'oui' ||
          openField.tractValuesForm(openField.sibling).value == 'si' ||
          openField.tractValuesForm(openField.sibling).value == 'other' ||
          openField.tractValuesForm(openField.sibling).value == 'outra' ||
          openField.tractValuesForm(openField.sibling).value == 'autre') && (
          <div key={openField.name}>{getFieldForForm(openField)}</div>
        )}
    </div>
  );
};
// || 'other' || 'autre' || 'outra')
export default MultiSelection;
