import React, { useState } from 'react';
import { IPatientDetail } from '../../features/evaluation/type';

export interface IPatientDetailsContext {
  patientDetails: IPatientDetail;
  handlePatientDetails: any;
}

const PatientDetailsContext = React.createContext<IPatientDetailsContext>({
  patientDetails: {
    userId: '',
    patientId: '',
    evaluationType: null,
    processEvaluationId: '',
    diagnosticsQuestions: null,
    userEvaluation: null,
    isUnderReview: true,
    infosProcessEvaluation: null,
    dateEvaluation: null,
  },
  handlePatientDetails: (a: IPatientDetail) => a,
});

export const UpdatePatientDetails = () => {
  const [patientDetails, setPatientDetails] = useState<IPatientDetail>({
    userId: '',
    patientId: '',
    evaluationType: null,
    processEvaluationId: '',
    diagnosticsQuestions: null,
    userEvaluation: null,
    isUnderReview: true,
    infosProcessEvaluation: null,
    dateEvaluation: null,
  });
  const handlePatientDetails = (a: IPatientDetail) => setPatientDetails(a);
  return { patientDetails, handlePatientDetails };
};

export default PatientDetailsContext;
