/* eslint-disable quotes */
import { TLanguages } from '../../../services/data';
import { TEvaluationType } from '../type';

export const answersPossible = [
  'Not at all',
  'Just a little',
  'Quite a bit',
  'Very much',
];

export const answersEvaluation: {
  // eslint-disable-next-line no-unused-vars
  [key in TLanguages]: {
    // eslint-disable-next-line no-unused-vars
    [key in TEvaluationType]: any;
  };
} = {
  eng: {
    tdah: {
      interview: [
        {
          db: 'no',
          traduction: 'no',
        },
        {
          db: 'yes',
          traduction: 'yes',
        },
      ],
      snaps: [
        {
          db: 'never',
          traduction: 'never',
        },
        {
          db: 'sometimes',
          traduction: 'sometimes',
        },
        {
          db: 'frequently',
          traduction: 'frequently',
        },
        {
          db: 'exaggeratedly',
          traduction: 'exaggeratedly',
        },
      ],
      antes: [
        {
          db: 'no',
          traduction: 'no',
        },
        {
          db: 'yes',
          traduction: 'yes',
        },
      ],
      cognitionAndSchoolLife: [
        {
          db: "I don't know",
          traduction: "I don't know",
        },
        {
          db: 'Much Worse',
          traduction: 'Much Worse',
        },
        {
          db: 'Worse',
          traduction: 'Worse',
        },
        {
          db: 'Same',
          traduction: 'Same',
        },
        {
          db: 'Better',
          traduction: 'Better',
        },
        {
          db: 'Much better',
          traduction: 'Much better',
        },
      ],
      interpersonalRelationships: [
        {
          db: "I don't know",
          traduction: "I don't know",
        },
        {
          db: 'Much Worse',
          traduction: 'Much Worse',
        },
        {
          db: 'Worse',
          traduction: 'Worse',
        },
        {
          db: 'Same',
          traduction: 'Same',
        },
        {
          db: 'Better',
          traduction: 'Better',
        },
        {
          db: 'Much better',
          traduction: 'Much better',
        },
      ],
      activitiesOfDailyLiving: [
        {
          db: "I don't know",
          traduction: "I don't know",
        },
        {
          db: 'Much Worse',
          traduction: 'Much Worse',
        },
        {
          db: 'Worse',
          traduction: 'Worse',
        },
        {
          db: 'Same',
          traduction: 'Same',
        },
        {
          db: 'Better',
          traduction: 'Better',
        },
        {
          db: 'Much better',
          traduction: 'Much better',
        },
      ],
      selfCare: [
        {
          db: "I don't know",
          traduction: "I don't know",
        },
        {
          db: 'Much Worse',
          traduction: 'Much Worse',
        },
        {
          db: 'Worse',
          traduction: 'Worse',
        },
        {
          db: 'Same',
          traduction: 'Same',
        },
        {
          db: 'Better',
          traduction: 'Better',
        },
        {
          db: 'Much better',
          traduction: 'Much better',
        },
      ],
      mobilityAndCoordination: [
        {
          db: "I don't know",
          traduction: "I don't know",
        },
        {
          db: 'Much Worse',
          traduction: 'Much Worse',
        },
        {
          db: 'Worse',
          traduction: 'Worse',
        },
        {
          db: 'Same',
          traduction: 'Same',
        },
        {
          db: 'Better',
          traduction: 'Better',
        },
        {
          db: 'Much better',
          traduction: 'Much better',
        },
      ],
      communication: [
        {
          db: "I don't know",
          traduction: "I don't know",
        },
        {
          db: 'Much Worse',
          traduction: 'Much Worse',
        },
        {
          db: 'Worse',
          traduction: 'Worse',
        },
        {
          db: 'Same',
          traduction: 'Same',
        },
        {
          db: 'Better',
          traduction: 'Better',
        },
        {
          db: 'Much better',
          traduction: 'Much better',
        },
      ],
      parentingStyles: [
        {
          db: '1',
          traduction: '1',
        },
        {
          db: '2',
          traduction: '2',
        },
        {
          db: '3',
          traduction: '3',
        },
        {
          db: '4',
          traduction: '4',
        },
        {
          db: '5',
          traduction: '5',
        },
      ],
      sociodemographic: [
        {
          db: '1',
          traduction: '1',
        },
        {
          db: '2',
          traduction: '2',
        },
        {
          db: '3',
          traduction: '3',
        },
        {
          db: '4',
          traduction: '4',
        },
        {
          db: '5',
          traduction: '5',
        },
      ],
    },
    autism: [
      {
        db: 'never',
        traduction: 'never',
      },
      {
        db: 'sometimes',
        traduction: 'sometimes',
      },
      {
        db: 'frequently',
        traduction: 'frequently',
      },
      {
        db: 'exaggeratedly',
        traduction: 'exaggeratedly',
      },
    ],
    dyslexia: [
      {
        db: 'never',
        traduction: 'never',
      },
      {
        db: 'sometimes',
        traduction: 'sometimes',
      },
      {
        db: 'frequently',
        traduction: 'frequently',
      },
      {
        db: 'exaggeratedly',
        traduction: 'exaggeratedly',
      },
    ],
    generalEvaluation: [
      {
        db: 'never',
        traduction: 'never',
      },
      {
        db: 'sometimes',
        traduction: 'sometimes',
      },
      {
        db: 'frequently',
        traduction: 'frequently',
      },
      {
        db: 'exaggeratedly',
        traduction: 'exaggeratedly',
      },
    ],
  },
  fr: {
    tdah: {
      interview: [
        {
          db: 'no',
          traduction: 'non',
        },
        {
          db: 'yes',
          traduction: 'oui',
        },
      ],
      snaps: [
        {
          db: 'never',
          traduction: 'jamais',
        },
        {
          db: 'sometimes',
          traduction: 'parfois',
        },
        {
          db: 'frequently',
          traduction: 'fréquemment',
        },
        {
          db: 'exaggeratedly',
          traduction: 'excessivement',
        },
      ],
      antes: [
        {
          db: 'no',
          traduction: 'non',
        },
        {
          db: 'yes',
          traduction: 'oui',
        },
      ],
      cognitionAndSchoolLife: [
        {
          db: "I don't know",
          traduction: 'Je ne sais pas',
        },
        {
          db: 'Much Worse',
          traduction: 'Beaucoup pire',
        },
        {
          db: 'Worse',
          traduction: 'Pire',
        },
        {
          db: 'Same',
          traduction: 'Même',
        },
        {
          db: 'Better',
          traduction: 'Meilleur',
        },
        {
          db: 'Much better',
          traduction: 'Beaucoup mieux',
        },
      ],
      interpersonalRelationships: [
        {
          db: "I don't know",
          traduction: 'Je ne sais pas',
        },
        {
          db: 'Much Worse',
          traduction: 'Beaucoup pire',
        },
        {
          db: 'Worse',
          traduction: 'Pire',
        },
        {
          db: 'Same',
          traduction: 'Même',
        },
        {
          db: 'Better',
          traduction: 'Meilleur',
        },
        {
          db: 'Much better',
          traduction: 'Beaucoup mieux',
        },
      ],
      activitiesOfDailyLiving: [
        {
          db: "I don't know",
          traduction: 'Je ne sais pas',
        },
        {
          db: 'Much Worse',
          traduction: 'Beaucoup pire',
        },
        {
          db: 'Worse',
          traduction: 'Pire',
        },
        {
          db: 'Same',
          traduction: 'Même',
        },
        {
          db: 'Better',
          traduction: 'Meilleur',
        },
        {
          db: 'Much better',
          traduction: 'Beaucoup mieux',
        },
      ],
      selfCare: [
        {
          db: "I don't know",
          traduction: 'Je ne sais pas',
        },
        {
          db: 'Much Worse',
          traduction: 'Beaucoup pire',
        },
        {
          db: 'Worse',
          traduction: 'Pire',
        },
        {
          db: 'Same',
          traduction: 'Même',
        },
        {
          db: 'Better',
          traduction: 'Meilleur',
        },
        {
          db: 'Much better',
          traduction: 'Beaucoup mieux',
        },
      ],
      mobilityAndCoordination: [
        {
          db: "I don't know",
          traduction: 'Je ne sais pas',
        },
        {
          db: 'Much Worse',
          traduction: 'Beaucoup pire',
        },
        {
          db: 'Worse',
          traduction: 'Pire',
        },
        {
          db: 'Same',
          traduction: 'Même',
        },
        {
          db: 'Better',
          traduction: 'Meilleur',
        },
        {
          db: 'Much better',
          traduction: 'Beaucoup mieux',
        },
      ],
      communication: [
        {
          db: "I don't know",
          traduction: 'Je ne sais pas',
        },
        {
          db: 'Much Worse',
          traduction: 'Beaucoup plus mauvais',
        },
        {
          db: 'Worse',
          traduction: 'Pire',
        },
        {
          db: 'Same',
          traduction: 'Identique',
        },
        {
          db: 'Better',
          traduction: 'Meilleur',
        },
        {
          db: 'Much better',
          traduction: 'Beaucoup mieux',
        },
      ],
      parentingStyles: [
        {
          db: '1',
          traduction: '1',
        },
        {
          db: '2',
          traduction: '2',
        },
        {
          db: '3',
          traduction: '3',
        },
        {
          db: '4',
          traduction: '4',
        },
        {
          db: '5',
          traduction: '5',
        },
      ],
      sociodemographic: [
        {
          db: '1',
          traduction: '1',
        },
        {
          db: '2',
          traduction: '2',
        },
        {
          db: '3',
          traduction: '3',
        },
        {
          db: '4',
          traduction: '4',
        },
        {
          db: '5',
          traduction: '5',
        },
      ],
    },
    autism: [
      {
        db: 'never',
        traduction: 'jamais',
      },
      {
        db: 'sometimes',
        traduction: 'parfois',
      },
      {
        db: 'frequently',
        traduction: 'souvent',
      },
      {
        db: 'exaggeratedly',
        traduction: 'continuellement',
      },
    ],
    dyslexia: [
      {
        db: 'never',
        traduction: 'jamais',
      },
      {
        db: 'sometimes',
        traduction: 'parfois',
      },
      {
        db: 'frequently',
        traduction: 'souvent',
      },
      {
        db: 'exaggeratedly',
        traduction: 'continuellement',
      },
    ],
    generalEvaluation: [
      {
        db: 'never',
        traduction: 'jamais',
      },
      {
        db: 'sometimes',
        traduction: 'parfois',
      },
      {
        db: 'frequently',
        traduction: 'souvent',
      },
      {
        db: 'exaggeratedly',
        traduction: 'continuellement',
      },
    ],
  },
  ptbr: {
    tdah: {
      interview: [
        {
          db: 'no',
          traduction: 'não',
        },
        {
          db: 'yes',
          traduction: 'sim',
        },
      ],
      snaps: [
        {
          db: 'never',
          traduction: 'nunca',
        },
        {
          db: 'sometimes',
          traduction: 'às vezes',
        },
        {
          db: 'frequently',
          traduction: 'frequentemente',
        },
        {
          db: 'exaggeratedly',
          traduction: 'exageradamente',
        },
      ],
      antes: [
        {
          db: 'no',
          traduction: 'não',
        },
        {
          db: 'yes',
          traduction: 'sim',
        },
      ],
      cognitionAndSchoolLife: [
        {
          db: "I don't know",
          traduction: 'Não sei',
        },
        {
          db: 'Much Worse',
          traduction: 'Muito pior',
        },
        {
          db: 'Worse',
          traduction: 'Pior',
        },
        {
          db: 'Same',
          traduction: 'Mesmo',
        },
        {
          db: 'Better',
          traduction: 'Melhor',
        },
        {
          db: 'Much better',
          traduction: 'Muito melhor',
        },
      ],
      interpersonalRelationships: [
        {
          db: "I don't know",
          traduction: 'Não sei',
        },
        {
          db: 'Much Worse',
          traduction: 'Muito pior',
        },
        {
          db: 'Worse',
          traduction: 'Pior',
        },
        {
          db: 'Same',
          traduction: 'Mesmo',
        },
        {
          db: 'Better',
          traduction: 'Melhor',
        },
        {
          db: 'Much better',
          traduction: 'Muito melhor',
        },
      ],
      activitiesOfDailyLiving: [
        {
          db: "I don't know",
          traduction: 'Não sei',
        },
        {
          db: 'Much Worse',
          traduction: 'Muito pior',
        },
        {
          db: 'Worse',
          traduction: 'Pior',
        },
        {
          db: 'Same',
          traduction: 'Mesmo',
        },
        {
          db: 'Better',
          traduction: 'Melhor',
        },
        {
          db: 'Much better',
          traduction: 'Muito melhor',
        },
      ],
      selfCare: [
        {
          db: "I don't know",
          traduction: 'Não sei',
        },
        {
          db: 'Much Worse',
          traduction: 'Muito pior',
        },
        {
          db: 'Worse',
          traduction: 'Pior',
        },
        {
          db: 'Same',
          traduction: 'Mesmo',
        },
        {
          db: 'Better',
          traduction: 'Melhor',
        },
        {
          db: 'Much better',
          traduction: 'Muito melhor',
        },
      ],
      mobilityAndCoordination: [
        {
          db: "I don't know",
          traduction: 'Não sei',
        },
        {
          db: 'Much Worse',
          traduction: 'Muito pior',
        },
        {
          db: 'Worse',
          traduction: 'Pior',
        },
        {
          db: 'Same',
          traduction: 'Mesmo',
        },
        {
          db: 'Better',
          traduction: 'Melhor',
        },
        {
          db: 'Much better',
          traduction: 'Muito melhor',
        },
      ],
      communication: [
        {
          db: "I don't know",
          traduction: 'Não sei',
        },
        {
          db: 'Much Worse',
          traduction: 'Muito pior',
        },
        {
          db: 'Worse',
          traduction: 'Pior',
        },
        {
          db: 'Same',
          traduction: 'Igual',
        },
        {
          db: 'Better',
          traduction: 'Melhor',
        },
        {
          db: 'Much better',
          traduction: 'Muito melhor',
        },
      ],
      parentingStyles: [
        {
          db: '1',
          traduction: '1',
        },
        {
          db: '2',
          traduction: '2',
        },
        {
          db: '3',
          traduction: '3',
        },
        {
          db: '4',
          traduction: '4',
        },
        {
          db: '5',
          traduction: '5',
        },
      ],
      sociodemographic: [
        {
          db: '1',
          traduction: '1',
        },
        {
          db: '2',
          traduction: '2',
        },
        {
          db: '3',
          traduction: '3',
        },
        {
          db: '4',
          traduction: '4',
        },
        {
          db: '5',
          traduction: '5',
        },
      ],
    },
    autism: [
      {
        db: 'never',
        traduction: 'nunca',
      },
      {
        db: 'sometimes',
        traduction: 'às vezes',
      },
      {
        db: 'frequently',
        traduction: 'frequentemente',
      },
      {
        db: 'exaggeratedly',
        traduction: 'exageradamente',
      },
    ],
    dyslexia: [
      {
        db: 'never',
        traduction: 'nunca',
      },
      {
        db: 'sometimes',
        traduction: 'às vezes',
      },
      {
        db: 'frequently',
        traduction: 'frequentemente',
      },
      {
        db: 'exaggeratedly',
        traduction: 'exageradamente',
      },
    ],
    generalEvaluation: [
      {
        db: 'never',
        traduction: 'nunca',
      },
      {
        db: 'sometimes',
        traduction: 'às vezes',
      },
      {
        db: 'frequently',
        traduction: 'frequentemente',
      },
      {
        db: 'exaggeratedly',
        traduction: 'exageradamente',
      },
    ],
  },
  es: {
    tdah: {
      interview: [
        {
          db: 'no',
          traduction: 'no',
        },
        {
          db: 'yes',
          traduction: 'sí',
        },
      ],
      snaps: [
        {
          db: 'never',
          traduction: 'nunca',
        },
        {
          db: 'sometimes',
          traduction: 'a veces',
        },
        {
          db: 'frequently',
          traduction: 'frecuentemente',
        },
        {
          db: 'exaggeratedly',
          traduction: 'exageradamente',
        },
      ],
      antes: [
        {
          db: 'no',
          traduction: 'no',
        },
        {
          db: 'yes',
          traduction: 'sí',
        },
      ],
      cognitionAndSchoolLife: [
        {
          db: "I don't know",
          traduction: 'No sé',
        },
        {
          db: 'Much Worse',
          traduction: 'Mucho peor',
        },
        {
          db: 'Worse',
          traduction: 'Peor',
        },
        {
          db: 'Same',
          traduction: 'Igual',
        },
        {
          db: 'Better',
          traduction: 'Mejor',
        },
        {
          db: 'Much better',
          traduction: 'Mucho mejor',
        },
      ],
      interpersonalRelationships: [
        {
          db: "I don't know",
          traduction: 'No sé',
        },
        {
          db: 'Much Worse',
          traduction: 'Mucho peor',
        },
        {
          db: 'Worse',
          traduction: 'Peor',
        },
        {
          db: 'Same',
          traduction: 'Igual',
        },
        {
          db: 'Better',
          traduction: 'Mejor',
        },
        {
          db: 'Much better',
          traduction: 'Mucho mejor',
        },
      ],
      activitiesOfDailyLiving: [
        {
          db: "I don't know",
          traduction: 'No sé',
        },
        {
          db: 'Much Worse',
          traduction: 'Mucho peor',
        },
        {
          db: 'Worse',
          traduction: 'Peor',
        },
        {
          db: 'Same',
          traduction: 'Igual',
        },
        {
          db: 'Better',
          traduction: 'Mejor',
        },
        {
          db: 'Much better',
          traduction: 'Mucho mejor',
        },
      ],
      selfCare: [
        {
          db: "I don't know",
          traduction: 'No sé',
        },
        {
          db: 'Much Worse',
          traduction: 'Mucho peor',
        },
        {
          db: 'Worse',
          traduction: 'Peor',
        },
        {
          db: 'Same',
          traduction: 'Igual',
        },
        {
          db: 'Better',
          traduction: 'Mejor',
        },
        {
          db: 'Much better',
          traduction: 'Mucho mejor',
        },
      ],
      mobilityAndCoordination: [
        {
          db: "I don't know",
          traduction: 'No sé',
        },
        {
          db: 'Much Worse',
          traduction: 'Mucho peor',
        },
        {
          db: 'Worse',
          traduction: 'Peor',
        },
        {
          db: 'Same',
          traduction: 'Igual',
        },
        {
          db: 'Better',
          traduction: 'Mejor',
        },
        {
          db: 'Much better',
          traduction: 'Mucho mejor',
        },
      ],
      communication: [
        {
          db: "I don't know",
          traduction: 'No sé',
        },
        {
          db: 'Much Worse',
          traduction: 'Mucho peor',
        },
        {
          db: 'Worse',
          traduction: 'Peor',
        },
        {
          db: 'Same',
          traduction: 'Igual',
        },
        {
          db: 'Better',
          traduction: 'Mejor',
        },
        {
          db: 'Much better',
          traduction: 'Mucho mejor',
        },
      ],
      parentingStyles: [
        {
          db: '1',
          traduction: '1',
        },
        {
          db: '2',
          traduction: '2',
        },
        {
          db: '3',
          traduction: '3',
        },
        {
          db: '4',
          traduction: '4',
        },
        {
          db: '5',
          traduction: '5',
        },
      ],
      sociodemographic: [
        {
          db: '1',
          traduction: '1',
        },
        {
          db: '2',
          traduction: '2',
        },
        {
          db: '3',
          traduction: '3',
        },
        {
          db: '4',
          traduction: '4',
        },
        {
          db: '5',
          traduction: '5',
        },
      ],
    },
    autism: [
      {
        db: 'never',
        traduction: 'nunca',
      },
      {
        db: 'sometimes',
        traduction: 'a veces',
      },
      {
        db: 'frequently',
        traduction: 'frecuentemente',
      },
      {
        db: 'exaggeratedly',
        traduction: 'exageradamente',
      },
    ],
    dyslexia: [
      {
        db: 'never',
        traduction: 'nunca',
      },
      {
        db: 'sometimes',
        traduction: 'a veces',
      },
      {
        db: 'frequently',
        traduction: 'frecuentemente',
      },
      {
        db: 'exaggeratedly',
        traduction: 'exageradamente',
      },
    ],
    generalEvaluation: [
      {
        db: 'never',
        traduction: 'nunca',
      },
      {
        db: 'sometimes',
        traduction: 'a veces',
      },
      {
        db: 'frequently',
        traduction: 'frecuentemente',
      },
      {
        db: 'exaggeratedly',
        traduction: 'exageradamente',
      },
    ],
  },
};
export const questionsEvaluation: any = {
  eng: {
    autism: [
      {
        typeAnswer: 'interview',
        title: 'Interview',
        questions: [
          'He prefers to do things on my own, rather than with others.',
          'He prefers doing things the same way - for instance his morning routine or trip to the supermarket',
          'He finds himself becoming strongly absorbed in something – even obsessional',
          'He is very sensitive to noise and will wear earplugs or cover his ears in certain situations',
          'Sometimes people say he is being rude, even though he thinks he is being polite.',
          'He finds it easy to imagine what characters from a book might look like.',
          'He finds it easy to talk in groups of people',
          'He is more interested in finding out about ‘things’ than people',
          'He finds numbers, dates and strings of information fascinating',
          'He prefers non-fiction books and films to fiction',
        ], // open fields

        keyApi: 'interview_',
      },
    ],
    tdah: [
      {
        typeAnswer: 'interview',
        title: 'Interview',
        keyApi: 'Interview_',
        questions: [
          'Was the pregnancy planned?',
          'Was the pregnancy well accepted?',
          'Did you use any contraceptive method?',
          'Preference for gender?',
          'History of difficulty getting pregnant?',
          'Did you have any prenatal appointments?',
          'Did you have any health problems?',
          'Did you need to be hospitalized?',
          'Did you have bleeding?',
          'Threatened abortion?',
          'Did you use alcohol? ',
          'Did you smoke during pregnancy?',
          'If yes: How many cigarettes or vapes a day?',
          ['Did you use any medicine?', 'If yes: Which one(s)'], // open fields
        ],
        answers: {},
      },
      {
        typeAnswer: 'snaps',
        title: 'questions',
        keyApi: 'SNAP',
        questions: [
          [
            'Often fails to give close attention to details or makes careless mistakes in schoolwork or tasks.',
            'How often the person fails to give close attention to details or makes careless mistakes in schoolwork or tasks?',
          ],
          [
            'Often has difficulty sustaining attention in tasks or play activities.',
            'How often the person has difficulty sustaining attention in tasks or play activities?',
          ],
          [
            'Often does not seem to listen when spoken to directly.',
            'How often the person does not seem to listen when spoken to directly?',
          ],
          [
            'Often does not follow through on instructions and fails to finish schoolwork, chores, or duties',
            'How often the person does not follow through on instructions and fails to finish schoolwork, chores, or duties?',
          ],
          [
            'Often has difficulty organizing tasks and activities',
            'How often has difficulty organizing tasks and activities?',
          ],
          [
            ' Often avoids, dislikes, or reluctantly engages in tasks requiring sustained mental effort',
            'How often the person avoids, dislikes, or reluctantly engages in tasks requiring sustained mental effort?',
          ],
          [
            ' Often loses things necessary for activities (e.g., toys, school assignments, pencils or books',
            'How often the person loses things necessary for activities (e.g., toys, school assignments, pencils or books?',
          ],
          [
            ' Often is distracted by extraneous stimuli',
            'How often the person is distracted by extraneous stimuli?',
          ],
          [
            'Often is forgetful in daily activities',
            'How often the person is forgetful in daily activities?',
          ],
          [
            'Often fidgets with hands or feet or squirms in seat',
            'How often the person fidgets with hands or feet or squirms in seat?',
          ],
          [
            'Often leaves seat in classroom or in other situations in which remaining seated is expected',
            'How often the person leaves seat in classroom or in other situations in which remaining seated is expected?',
          ],
          [
            'Often runs about or climbs excessively in situations in which it is inappropriate',
            'How often the person runs about or climbs excessively in situations in which it is inappropriate?',
          ],
          [
            'Often has difficulty playing or engaging in leisure activities quietly',
            'How often the person has difficulty playing or engaging in leisure activities quietly?',
          ],
          [
            'Often is “on the go” or often acts as if “driven by a motor”',
            'How often the person is “on the go” or often acts as if “driven by a motor”?',
          ],
          [
            'Often talks excessively',
            "How often the person talks excessively, even when it's not their turn or they are interrupting others?",
          ],
          [
            'Often blurts out answers before questions have been completed',
            'How often the person blurts out answers before questions have been completed?',
          ],
          [
            'Often has difficulty awaiting turn',
            'How often the person has difficulty awaiting turn?',
          ],
          [
            'Often interrupts or intrudes on others (e.g., butts into conversations/games)',
            'How often the person interrupts or intrudes on others (e.g., butts into conversations/games)?',
          ],
          ['Often loses temper', 'How often the person loses temper?'],
          [
            'Often argues with adults',
            'How often the person argue with adults, including parents or teachers?',
          ],
          [
            'Often actively defies or refuses adult requests or rules',
            'How often the person actively defies or refuses adult requests or rules?',
          ],
          [
            'Often deliberately does things that annoy other people',
            'How often the person deliberately does things that annoy other people?',
          ],
          [
            'Often blames others for his or her mistakes or misbehaviour',
            'How often the person blames others for his or her mistakes or misbehaviour, instead of taking responsibility?',
          ],
          [
            'Often is touchy or easily annoyed by others',
            'How often the person s touchy or easily annoyed by others, even over small things?',
          ],
          [
            'Often is angry and resentful',
            'How Often the person is angry and resentful, even when it seems unjustified?',
          ],
          [
            'Often is spiteful or vindictive',
            'How often the person engage in spiteful or vindictive behavior, like seeking revenge, at least twice within the past 6 months?',
          ],
        ],
        answers: {},
      },
      {
        typeAnswer: 'antes',
        title: 'Before',
        keyApi: 'Antes_',
        questions: [
          'Were the above issues present before age 7?',
          'Were the above issues present before age 12?',
        ],
        answers: {},
      },
      {
        typeAnswer: 'cognitionAndSchoolLife',
        title: 'Cognition and School Life',
        keyApi: 'F_Cog_',
        questions: [
          'Mathematics, calculations',
          'Reading, text comprehension',
          'Writing, spelling',
          'Learning things outside of school (day to day)',
        ],
        answers: {},
      },
      {
        typeAnswer: 'interpersonalRelationships',
        title: 'Interpersonal Relationships',
        keyApi: 'F_RI_',
        questions: [
          'Relating to parents',
          'Meet new people, make friends',
          "Understanding other people's feelings or emotions",
          'Understand your emotions and feelings',
        ],
        answers: {},
      },
      {
        typeAnswer: 'activitiesOfDailyLiving',
        title: 'Activities of Daily Living',
        keyApi: 'F_AVD_',
        questions: [
          'Participates in domestic activities and routines',
          'Participates in school activities',
          'Can prepare and organize for homework',
          'Participates in activities such as groups, churches, associations',
        ],
        answers: {},
      },
      {
        typeAnswer: 'selfCare',
        title: 'self-Care',
        keyApi: 'F_Auto_',
        questions: [
          'Controlling urine and stool',
          'Take a shower and brush your teeth',
          'Choose and change clothes',
          'Safely use forks, knives and other utensils',
        ],
        answers: {},
      },
      {
        typeAnswer: 'mobilityAndCoordination',
        title: 'Mobility and Coordination',
        keyApi: 'F_Mov_',
        questions: [
          'Walking an average distance (+-10 blocks)',
          'Jumping and jumping during pranks',
          'Playing a sport like football or basketball',
          'Cut paper using scissors',
        ],
        answers: {},
      },
      {
        typeAnswer: 'communication',
        title: 'communication',
        keyApi: 'F_Com_',
        questions: [
          'Express yourself well through speech',
          'Understand what people say',
          'Has a good vocabulary, knows many words',
          'Communicate via messages or text',
        ],
        answers: {},
      },
      {
        typeAnswer: 'parentingStyles',
        title: 'Parenting Styles',
        keyApi: 'QEDP_S_',
        questions: [
          'I use physical punishment as a way of disciplining my child',
          'When my child is naughty, I spank him/her',
          'I encourage my child to talk about his/her problems',
          'I find it difficult to discipline my child',
          "I encourage my child to express themselves openly, even when I don't agree with them",
          'I give comfort and understanding to my child when he/she is upset',
          'I scream or yell when my child misbehaves',
          'I have tantrums with my child',
          'I threaten to punish my child more often than I actually do',
          "I determine punishments for my child, but I don't really carry them out",
          "I show respect for my child's opinions by encouraging him/her to express them",
          'I spoil my child',
          'I have warm and special moments with my child',
          'I scold and harshly criticize my child when his/her behaviour does not meet my expectations',
          'I explain to my child the consequences of his/her behaviour',
        ],
        answers: {},
      },
      {
        typeAnswer: 'sociodemographic',
        title: 'Sociodemographic',
        keyApi: 'SES_',
        questions: [
          'Bathrooms',
          'Domestic Servants',
          'Automobiles',
          'Microcomputer',
          'Dishwasher',
          'Refrigerator',
          'Freezer',
          'Wash Clothes',
          'DVD',
          'Microwave',
          'Motorcycle',
          'Tumble Dryer',
          'Piped Water',
          'Paved Street',
        ],
        answers: {},
      },
    ],
    dyslexia: [
      {
        typeAnswer: 'interview',
        title: 'Interview',
        questions: [
          'Does he miss out words or lose his place when reading',
          'Does he mix up similar words such as Dog and God?',
          'Does he confuse the names of certain objects',
          'Does he often get lost or arrive really late to meetings',
          'Does he confuse his left from your right',
          'Does he find it hard to follow several instructions all at one time',
          'Does he read a page multiple times to understand it',
        ], // open fields

        keyApi: 'interview_',
      },
    ],
    generalEvaluation: [
      {
        typeAnswer: 'interview',
        title: 'Interview',
        questions: [
          'Have difficulty getting up and getting ready for school in the morning?',
          'Take a long time to complete simple tasks?',
          'Have difficulty planning and/or organizing tasks?',
          'Have difficulty with situations that require change and new ways of doing things?',
          'Have difficulty participating in group activities?',
          'Have difficulty estimating how long it will take to complete a chore or a project?',
          'Seem to tire easily when doing school work?',
        ], // open fields

        keyApi: 'interview_',
      },
    ],
  },
  fr: {
    autism: {
      typeAnswer: 'interview',
      title: 'Interview',
      questions: [
        "Il préfère faire les choses tout seul, plutôt qu'avec les autres.",
        'Il préfère faire les choses de la même manière - par exemple sa routine matinale ou son voyage au supermarché',
        'Il se retrouve fortement absorbé par quelque chose, voire obsessionnel ',
        "Il est très sensible au bruit et portera des bouchons d'oreille ou se couvrira les oreilles dans certaines situations",
        "Parfois, les gens disent qu'il est grossier, même s'il pense qu'il est poli.",
        "Il trouve facile d'imaginer à quoi pourraient ressembler les personnages d'un livre.",
        'Il trouve facile de parler en groupe',
        "Il est plus intéressé à découvrir des 'choses' que des gens",
        "Il trouve des chiffres, des dates et des chaînes d'informations fascinantes",
        'Il préfère les livres et les films de non-fiction à la fiction',
      ], // open fields

      key: 'interview_',
    },
    tdah: [
      {
        typeAnswer: 'interview',
        title: 'Interview',
        keyApi: 'Interview_',
        questions: [
          'La grossesse était-elle planifiée ?',
          'La grossesse a-t-elle été bien acceptée ?',
          'Avez-vous utilisé une méthode contraceptive ?',
          'Préférence pour le genre ?',
          'Antécédents de difficultés à concevoir ?',
          'Avez-vous suivi des rendez-vous prénataux ?',
          'Avez-vous eu des problèmes de santé ?',
          "Avez-vous eu besoin d'être hospitalisé(e) ?",
          'Avez-vous eu des saignements ?',
          'Avortement menacé ?',
          "Avez-vous consommé de l'alcool ?",
          'Avez-vous fumé pendant la grossesse ?',
          'Si oui, combien de cigarettes ou de vapes par jour ?',
          ['Avez-vous pris des médicaments ?', 'Si oui, lesquels ?'], // open fields
        ],
        answers: {},
      },
      {
        typeAnswer: 'snaps',
        title: 'questions',
        keyApi: 'SNAP',
        questions: [
          [
            'Souvent, ne parvient pas à accorder une attention soutenue aux détails ou commet des erreurs négligentes dans les travaux scolaires ou les tâches.',
            'À quelle fréquence la personne ne parvient-elle pas à accorder une attention soutenue aux détails ou commet-elle des erreurs négligentes dans les travaux scolaires ou les tâches?',
          ],
          [
            'Souvent, a des difficultés à maintenir son attention dans les tâches ou les activités ludiques.',
            'À quelle fréquence la personne a-t-elle des difficultés à maintenir son attention dans les tâches ou les activités ludiques?',
          ],
          [
            "Souvent, ne semble pas écouter lorsqu'on lui parle directement.",
            "À quelle fréquence la personne ne semble-t-elle pas écouter lorsqu'on lui parle directement?",
          ],
          [
            'Souvent, ne suit pas les instructions et ne parvient pas à terminer les travaux scolaires, les corvées ou les devoirs.',
            'À quelle fréquence la personne ne suit-elle pas les instructions et ne parvient-elle pas à terminer les travaux scolaires, les corvées ou les devoirs?',
          ],
          [
            'Souvent, a des difficultés à organiser les tâches et les activités.',
            'À quelle fréquence a-t-elle des difficultés à organiser les tâches et les activités?',
          ],
          [
            "Souvent, évite, n'aime pas ou s'engage à contrecœur dans des tâches nécessitant un effort mental soutenu.",
            "À quelle fréquence la personne évite-t-elle, n'aime-t-elle pas ou s'engage-t-elle à contrecœur dans des tâches nécessitant un effort mental soutenu?",
          ],
          [
            'Souvent, perd des objets nécessaires aux activités (par exemple, jouets, devoirs, crayons ou livres).',
            'À quelle fréquence la personne perd-elle des objets nécessaires aux activités (par exemple, jouets, devoirs, crayons ou livres)?',
          ],
          [
            'Souvent, est distrait(e) par des stimuli extérieurs.',
            'À quelle fréquence la personne est-elle distraite par des stimuli extérieurs?',
          ],
          [
            'Souvent, est étourdi(e) dans les activités quotidiennes.',
            'À quelle fréquence la personne est-elle étourdie dans les activités quotidiennes?',
          ],
          [
            'Souvent, gigote avec les mains ou les pieds ou se tortille sur son siège.',
            'À quelle fréquence la personne gigote-t-elle avec les mains ou les pieds ou se tortille-t-elle sur son siège?',
          ],
          [
            "Souvent, quitte son siège en classe ou dans d'autres situations où il est attendu de rester assis.",
            "À quelle fréquence la personne quitte-t-elle son siège en classe ou dans d'autres situations où il est attendu de rester assis?",
          ],
          [
            'Souvent, court ou grimpe de manière excessive dans des situations où cela est inapproprié.',
            'À quelle fréquence la personne court-elle ou grimpe-t-elle de manière excessive dans des situations où cela est inapproprié?',
          ],
          [
            "Souvent, a des difficultés à jouer ou à s'engager dans des activités de loisirs en silence.",
            "À quelle fréquence la personne a-t-elle des difficultés à jouer ou à s'engager dans des activités de loisirs en silence?",
          ],
          [
            'Souvent, est en mouvement ou agit souvent comme si elle était poussée par un moteur.',
            'À quelle fréquence la personne est-elle en mouvement ou agit-elle souvent comme si elle était poussée par un moteur?',
          ],
          [
            'Souvent, parle excessivement.',
            "À quelle fréquence la personne parle-t-elle excessivement, même lorsque ce n'est pas son tour ou qu'elle interrompt les autres?",
          ],
          [
            'Souvent, répond de manière impulsive avant que les questions ne soient terminées.',
            'À quelle fréquence la personne répond-elle de manière impulsive avant que les questions ne soient terminées?',
          ],
          [
            'Souvent, a des difficultés à attendre son tour.',
            'À quelle fréquence la personne a-t-elle des difficultés à attendre son tour?',
          ],
          [
            "Souvent, interrompt ou s'immisce dans les affaires des autres (par exemple, s'immisce dans des conversations/jeux).",
            "À quelle fréquence la personne interrompt-elle ou s'immisce-t-elle dans les affaires des autres (par exemple, s'immisce dans des conversations/jeux)?",
          ],
          [
            'Souvent, perd son calme.',
            'À quelle fréquence la personne perd-elle son calme?',
          ],
          [
            'Souvent, se dispute avec les adultes.',
            'À quelle fréquence la personne se dispute-t-elle avec les adultes, y compris les parents ou les enseignants?',
          ],
          [
            'Souvent, défie activement ou refuse les demandes ou les règles des adultes.',
            'À quelle fréquence la personne défie-t-elle activement ou refuse-t-elle les demandes ou les règles des adultes?',
          ],
          [
            'Souvent, fait délibérément des choses qui agacent les autres.',
            'À quelle fréquence la personne fait-elle délibérément des choses qui agacent les autres?',
          ],
          [
            'Souvent, blâme les autres pour ses erreurs ou son comportement inapproprié.',
            'À quelle fréquence la personne blâme-t-elle les autres pour ses erreurs ou son comportement inapproprié, au lieu de prendre ses responsabilités?',
          ],
          [
            "Souvent, est susceptible ou s'irrite facilement contre les autres.",
            "À quelle fréquence la personne est-elle susceptible ou s'irrite-t-elle facilement contre les autres, même pour de petites choses?",
          ],
          [
            'Souvent, est en colère et ressent du ressentiment.',
            'À quelle fréquence la personne est-elle en colère et ressent-elle du ressentiment, même lorsque cela semble injustifié?',
          ],
          [
            'Souvent, est vindicatif(ve) ou revanchard(e).',
            'À quelle fréquence la personne adopte-t-elle un comportement vindicatif ou revanchard, comme chercher à se venger, au moins deux fois au cours des six derniers mois?',
          ],
        ],
        answers: {},
      },
      {
        typeAnswer: 'antes',
        title: 'Avant',
        keyApi: 'Antes_',
        questions: [
          "Est-ce que les problèmes ci-dessus étaient présents avant l'âge de 7 ans ?",
          "Est-ce que les problèmes ci-dessus étaient présents avant l'âge de 12 ans ?",
        ],
        answers: {},
      },
      {
        typeAnswer: 'cognitionAndSchoolLife',
        title: 'Cognition and School Life',
        keyApi: 'F_Cog_',
        questions: [
          'Mathématiques, calculs',
          'Lecture, compréhension de texte',
          'Ecriture, orthographe',
          "Apprentissage de choses en dehors de l'école (quotidien)",
        ],
        answers: {},
      },
      {
        typeAnswer: 'interpersonalRelationships',
        title: 'Interpersonal Relationships',
        keyApi: 'F_RI_',
        questions: [
          'Relier aux parents',
          'Rencontrer de nouvelles personnes, se faire des amis',
          'Comprendre les sentiments ou les émotions des autres',
          'Comprendre vos propres émotions et sentiments',
        ],
        answers: {},
      },
      {
        typeAnswer: 'activitiesOfDailyLiving',
        title: 'Activities of Daily Living',
        keyApi: 'F_AVD_',
        questions: [
          'Participer aux activités et routines domestiques',
          'Participer aux activités scolaires',
          "Peut préparer et s'organiser pour les devoirs",
          'Participer à des activités telles que des groupes, des églises, des associations',
        ],
        answers: {},
      },
      {
        typeAnswer: 'selfCare',
        title: 'self-Care',
        keyApi: 'F_Auto_',
        questions: [
          'Contrôle de la miction et des selles',
          'Prendre une douche et se brosser les dents',
          'Choisir et changer de vêtements',
          'Utiliser en toute sécurité des fourchettes, des couteaux et d’autres ustensiles',
        ],
        answers: {},
      },
      {
        typeAnswer: 'mobilityAndCoordination',
        title: 'Mobility and Coordination',
        keyApi: 'F_Mov_',
        questions: [
          'Marcher une distance moyenne (+-10 pâtés de maisons)',
          'Sauter et sauter lors de blagues',
          'Jouer à un sport comme le football ou le basketball',
          'Découper du papier avec des ciseaux',
        ],
        answers: {},
      },
      {
        typeAnswer: 'communication',
        title: 'communication',
        keyApi: 'F_Com_',
        questions: [
          'Exprimer bien par la parole',
          'Comprendre ce que les gens disent',
          'Avoir un bon vocabulaire, connaître de nombreux mots',
          'Communiquer via des messages ou des textes',
        ],
        answers: {},
      },
      {
        typeAnswer: 'parentingStyles',
        title: 'Parenting Styles',
        keyApi: 'QEDP_S_',
        questions: [
          "J'utilise la punition physique comme méthode de discipline envers mon enfant",
          'Quand mon enfant est désobéissant, je le/la gifle',
          "J'encourage mon enfant à parler de ses problèmes",
          'Je trouve difficile de discipliner mon enfant',
          "J'encourage mon enfant à s'exprimer librement, même quand je ne suis pas d'accord avec lui/elle",
          "Je réconforte et je comprends mon enfant lorsqu'il/elle est bouleversé(e)",
          'Je crie ou hurle lorsque mon enfant se comporte mal',
          'Je fais des crises avec mon enfant',
          'Je menace de punir mon enfant plus souvent que je ne le fais réellement',
          'Je détermine des punitions pour mon enfant, mais je ne les applique pas vraiment',
          "Je respecte les opinions de mon enfant en l'encourageant à les exprimer",
          'Je gâte mon enfant',
          "J'ai des moments chaleureux et spéciaux avec mon enfant",
          'Je gronde et je critique sévèrement mon enfant lorsque son comportement ne répond pas à mes attentes',
          "J'explique à mon enfant les conséquences de son comportement",
        ],
        answers: {},
      },
      {
        typeAnswer: 'sociodemographic',
        title: 'Sociodemographic',
        keyApi: 'SES_',
        questions: [
          'Bathrooms',
          'Domestic Servants',
          'Automobiles',
          'Microcomputer',
          'Dishwasher',
          'Réfrigérateur',
          'Congélateur',
          'Laver les vêtements',
          'DVD',
          'Micro-ondes',
          'Moto',
          'Sèche-linge',
          'Eau courante',
          'Rue pavée',
        ],
        answers: {},
      },
    ],
    dyslexia: [
      "Est-ce qu'il manque des mots ou perd sa place en lisant",
      "Est-ce qu'il mélange des mots similaires tels que Chien et Dieu?",
      "Est-ce qu'il confond les noms de certains objets",
      "Est-ce qu'il se perd souvent ou arrive très tard aux réunions",
      "Est-ce qu'il confond sa gauche de votre droite",
      'A-t-il du mal à suivre plusieurs instructions en même temps',
      "Est-ce qu'il lit une page plusieurs fois pour la comprendre",
    ],
    generalEvaluation: [
      "Avez-vous des difficultés à vous lever et à vous préparer pour l'école le matin ?",
      'Prendre beaucoup de temps pour accomplir des tâches simples ?',
      'Vous avez des difficultés à planifier et/ou organiser des tâches ?',
      'Avez-vous des difficultés avec des situations qui nécessitent des changements et de nouvelles façons de faire?',
      'Avez-vous des difficultés à participer à des activités de groupe ?',
      'Vous avez du mal à estimer combien de temps il vous faudra pour terminer une corvée ou un projet ?',
      'Semble se fatiguer facilement en faisant des devoirs scolaires ?',
    ],
  },
  ptbr: {
    autism: [
      'Ele prefere fazer as coisas sozinho, em vez de com os outros',
      'Ele prefere fazer as coisas da mesma maneira - por exemplo, sua rotina matinal ou viagem ao supermercado',
      'Ele se vê se tornando fortemente absorvido em algo - até mesmo obsessivo',
      'Ele é muito sensível a ruídos e usará protetores de ouvido ou cobrirá seus ouvidos em certas situações',
      'Às vezes, as pessoas dizem que ele está sendo rude, mesmo que ele pense que está sendo educado',
      'Ele encontra facilidade em imaginar como os personagens de um livro podem parecer',
      'Ele encontra facilidade em falar em grupos de pessoas',
      'Ele está mais interessado em descobrir sobre "coisas" do que sobre pessoas',
      'Ele acha números, datas e sequências de informações fascinantes',
      'Ele prefere livros e filmes de não-ficção a ficção',
    ],
    tdah: [
      {
        typeAnswer: 'interview',
        title: 'Entrevista',
        keyApi: 'Interview_',
        questions: [
          'Foi uma gravidez planejada?',
          'A gravidez foi bem aceita?',
          'Você usou algum método contraceptivo?',
          'Preferência por gênero?',
          'Histórico de dificuldade para engravidar?',
          'Você fez algum acompanhamento pré-natal?',
          'Você teve algum problema de saúde?',
          'Foi necessário ser hospitalizada?',
          'Você teve algum sangramento?',
          'Aborto ameaçado?',
          'Você consumiu álcool durante a gravidez?',
          'Você fumou durante a gravidez?',
          'Se sim: quantos cigarros ou vape por dia?',
          ['Você usou algum medicamento?', 'Se sim: quais?'], // open fields
        ],
        answers: {},
      },
      {
        typeAnswer: 'snaps',
        title: 'Questões',
        keyApi: 'SNAP',
        questions: [
          [
            'Não presta atenção aos detalhes ou comete erros descuidados.',
            'A pessoa comete erros descuidados ou perde detalhes importantes em seu trabalho ou outras atividades?',
          ],
          [
            'Tem dificuldade em manter a atenção em tarefas ou atividades lúdicas.',
            'A pessoa tem dificuldade em manter o foco em tarefas ou atividades, mesmo aquelas que gostam?',
          ],
          [
            'Não parece ouvir quando falado diretamente.',
            'A pessoa parece não ouvir quando falada diretamente, como se estivesse em outro lugar em sua mente?',
          ],
          [
            'Não segue as instruções e não termina as tarefas',
            'A pessoa tem dificuldade em seguir as instruções e não consegue completar as tarefas?',
          ],
          [
            'Tem dificuldade em organizar tarefas e atividades',
            'A pessoa tem dificuldade em organizar suas tarefas ou atividades, ou parece desorganizada?',
          ],
          [
            'Evita ou não gosta de tarefas que exigem esforço mental sustentado',
            'A pessoa evita ou não gosta de tarefas que exigem esforço mental, como trabalhos escolares ou outros projetos?',
          ],
          [
            'Perde coisas necessárias para tarefas ou atividades',
            'A pessoa costuma perder coisas necessárias para seu trabalho ou atividades, como material escolar ou itens pessoais?',
          ],
          [
            'É facilmente distraído por estímulos externos',
            'A pessoa é facilmente distraída por coisas ao seu redor, mesmo que não sejam importantes?',
          ],
          [
            'É esquecido nas atividades diárias',
            'A pessoa esquece coisas importantes que precisa fazer em sua vida diária, como compromissos ou prazos?',
          ],
          [
            'Mexe as mãos ou os pés ou se contorce na cadeira',
            'A pessoa se mexe ou se contorce na cadeira, bate com as mãos ou pés ou tem dificuldade em ficar quieta?',
          ],
          [
            'Levanta da cadeira na sala de aula ou em outras situações em que se espera que permaneça sentado',
            'A pessoa costuma levantar da cadeira, mesmo quando deve permanecer sentada, como na sala de aula ou em uma reunião?',
          ],
          [
            'Corre ou escala excessivamente em situações inadequadas',
            'A pessoa corre ou escala excessivamente, mesmo quando não é apropriado ou seguro?',
          ],
          [
            'Tem dificuldade em brincar ou se envolver em atividades de lazer silenciosamente',
            'A pessoa tem dificuldade em brincar ou fazer atividades de lazer silenciosamente?',
          ],
          [
            'Age como se estivesse "impulsionado por um motor"',
            'A pessoa age como se estivesse "impulsionada por um motor", incapaz de desacelerar ou fazer uma pausa?',
          ],
          [
            'Fala excessivamente',
            'A pessoa fala excessivamente, mesmo quando não é sua vez ou interrompe os outros?',
          ],
          [
            'Diz respostas antes que as perguntas tenham sido concluídas',

            'A pessoa responde antes que as perguntas tenham sido completamente feitas ou concluídas?',
          ],
          [
            'Tem dificuldade em aguardar a sua vez',
            'A pessoa tem dificuldade em aguardar a sua vez?',
          ],
          [
            'Interrompe ou invade os outros (por exemplo, interrompe conversas ou jogos)',
            'A pessoa interrompe outras pessoas, invade suas conversas ou atividades, ou tem dificuldade em esperar sua vez?',
          ],
          [
            'Frequentemente perde a calma',
            'A pessoa frequentemente perde a calma e se irrita facilmente?',
          ],
          [
            'Frequentemente discute com adultos',
            'A pessoa frequentemente discute com adultos, incluindo pais ou professores?',
          ],
          [
            'Frequentemente desafia ativamente ou se recusa a cumprir solicitações ou regras de adultos',
            'A pessoa frequentemente desafia ativamente ou se recusa a seguir as solicitações ou regras de adultos?',
          ],
          [
            'Frequentemente incomoda deliberadamente os outros',
            'A pessoa frequentemente incomoda ou provoca deliberadamente os outros, como irmãos ou colegas?',
          ],
          [
            'Frequentemente culpa os outros por seus erros ou mau comportamento',
            'A pessoa frequentemente culpa os outros por seus erros ou mau comportamento, em vez de assumir responsabilidade?',
          ],
          [
            'Facilmente se irrita com os outros',
            'A pessoa se irrita facilmente ou se incomoda com os outros, mesmo por coisas pequenas?',
          ],
          [
            'Frequentemente está com raiva e ressentido',
            'A pessoa frequentemente está com raiva ou ressentido, mesmo quando parece injustificado?',
          ],
          [
            'É malicioso ou vingativo pelo menos duas vezes nos últimos 6 meses',
            'A pessoa se comporta de forma maliciosa ou vingativa, como buscar vingança, pelo menos duas vezes nos últimos 6 meses?',
          ],
        ],
        answers: {},
      },
      {
        typeAnswer: 'antes',
        title: 'History',
        keyApi: 'Antes_',
        questions: [
          'Os problemas acima mencionados persistiram antes dos 7 anos de idade?',
          'Os problemas acima mencionados persistiram antes dos 12 anos de idade?',
        ],
        answers: {},
      },
      {
        typeAnswer: 'cognitionAndSchoolLife',
        title: 'Cognição e Vida Escolar',
        keyApi: 'F_Cog_',
        questions: [
          'Matemática, cálculos',
          'Leitura, compreensão de texto',
          'Escrita, ortografia',
          'Aprendizado de coisas fora da escola (dia a dia)',
        ],
        answers: {},
      },
      {
        typeAnswer: 'interpersonalRelationships',
        title: 'Relacionamento interpessoal',
        keyApi: 'F_RI_',
        questions: [
          'Relating to parents',
          'Conhecer pessoas novas, fazer amigos',
          'Compreender os sentimentos ou emoções dos outros',
          'Compreender seus próprios sentimentos e emoções',
        ],
        answers: {},
      },
      {
        typeAnswer: 'activitiesOfDailyLiving',
        title: 'Activities of Daily Living',
        keyApi: 'F_AVD_',
        questions: [
          'Participates in domestic activities and routines',
          'Participates in school activities',
          'Can prepare and organize for homework',
          'Participates in activities such as groups, churches, associations',
        ],
        answers: {},
      },
      {
        typeAnswer: 'selfCare',
        title: 'Autocuidados',
        keyApi: 'F_Auto_',
        questions: [
          'Controlling urine and stool',
          'Tomar banho e escovar os dentes',
          'Escolher e trocar de roupa',
          'Usar com segurança garfos, facas e outros utensílios',
        ],
        answers: {},
      },
      {
        typeAnswer: 'mobilityAndCoordination',
        title: 'Mobilidade e Coordenação',
        keyApi: 'F_Mov_',
        questions: [
          'Camminhar uma distância média (+-10 quarteirões)',
          'Pular e saltar durante brincadeiras',
          'Praticar um esporte como futebol ou basquete',
          'Cortar papel com tesoura',
        ],
        answers: {},
      },
      {
        typeAnswer: 'communication',
        title: 'Comunicação',
        keyApi: 'F_Com_',
        questions: [
          'Expressar-se bem através da fala',
          'Compreender o que as pessoas dizem',
          'Ter um bom vocabulário, conhecer muitas palavras',
          'Comunicar-se por meio de mensagens ou texto',
        ],
        answers: {},
      },
      {
        typeAnswer: 'parentingStyles',
        title: 'Estilos parentais',
        keyApi: 'QEDP_S_',
        questions: [
          'Afirmo que uso punição física como forma de disciplinar meu filho',
          'Quando meu filho se comporta mal, eu dou palmadas nele/nela',
          'Eu encorajo meu filho a falar sobre seus problemas',
          'Acho difícil disciplinar meu filho',
          'Eu encorajo meu filho a se expressar abertamente, mesmo quando não concordo com ele/ela',
          'Eu dou conforto e compreensão ao meu filho quando ele/ela está chateado/a',
          'Eu grito quando meu filho se comporta mal',
          'Eu tenho ataques de raiva com meu filho',
          'Eu ameaço punir meu filho com mais frequência do que realmente faço',
          'Eu determino punições para meu filho, mas não as coloco em prática de verdade',
          'Eu mostro respeito pelas opiniões do meu filho ao incentivá-lo/a a expressá-las',
          'Eu mimo meu filho',
          'Eu tenho momentos especiais e carinhosos com meu filho',
          'Eu repreendo e critico meu filho de forma dura quando seu comportamento não atende às minhas expectativas',
          'Eu explico ao meu filho as consequências de seu comportamento',
        ],
        answers: {},
      },
      {
        typeAnswer: 'sociodemographic',
        title: 'Sóciodemográfico',
        keyApi: 'SES_',
        questions: [
          'Banheiros',
          'Serviços Domésticos',
          'Automóveis',
          'Microcomputador',
          'Máquina de lavar louça',
          ' Geladeira',
          'Freezer',
          'Lavar Roupas',
          'DVD',
          'Micro-ondas',
          'Motocicleta',
          'Secadora de Roupas',
          'Água Encanada',
          'Rua Asfaltada',
        ],
        answers: {},
      },
    ],
    dyslexia: [
      'Ele deixa de ler palavras ou perde o lugar quando lê?',
      'Ele confunde palavras similares, como cachorro e Deus?',
      'Ele confunde o nome de certos objetos?',
      'Ele frequentemente se perde ou chega muito atrasado em reuniões?',
      'Ele confunde a esquerda com a direita?',
      'Ele acha difícil seguir várias instruções de uma vez?',
      'Ele lê uma página várias vezes para entendê-la?',
    ],
    generalEvaluation: [
      'Possui dificuldade para levantar e se preparar para a escola de manhã?',
      'Leva muito tempo para concluir tarefas simples?',
      'Tem dificuldade para planejar e / ou organizar tarefas?',
      'Tem dificuldade com situações que exigem mudança e novas maneiras de fazer as coisas?',
      'Tem dificuldade em participar de atividades em grupo?',
      'Tem dificuldade em estimar quanto tempo levará para concluir uma tarefa ou um projeto?',
      'Parece se cansar facilmente ao fazer trabalhos escolares?',
    ],
  },
  es: {
    tdah: [
      {
        typeAnswer: 'interview',
        title: 'Entrevista',
        keyApi: 'Entrevista_',
        questions: [
          '¿Fue el embarazo planeado?',
          '¿Fue el embarazo bien aceptado?',
          '¿Utilizó algún método anticonceptivo?',
          '¿Preferencia de género?',
          '¿Historial de dificultades para quedar embarazada?',
          '¿Realizó citas prenatales?',
          '¿Tuvo algún problema de salud?',
          '¿Necesitó ser hospitalizada?',
          '¿Hubo sangrado?',
          '¿Aborto amenazado?',
          '¿Consumió alcohol?',
          '¿Fumó durante el embarazo?',
          'En caso afirmativo: ¿Cuántos cigarrillos o vapes al día?',
          ['¿Usó algún medicamento?', 'En caso afirmativo: ¿Cuál(es)?'], // campos abiertos
        ],
        answers: {},
      },
      {
        typeAnswer: 'snaps',
        title: 'preguntas',
        keyApi: 'SNAP',
        questions: [
          [
            'A menudo no presta atención a los detalles o comete errores descuidados en tareas o trabajos escolares.',
            '¿Con qué frecuencia la persona no presta atención a los detalles o comete errores descuidados en tareas o trabajos escolares?',
          ],
          [
            'A menudo tiene dificultades para mantener la atención en tareas o actividades lúdicas.',
            '¿Con qué frecuencia la persona tiene dificultades para mantener la atención en tareas o actividades lúdicas?',
          ],
          [
            'A menudo parece no escuchar cuando se le habla directamente.',
            '¿Con qué frecuencia la persona parece no escuchar cuando se le habla directamente?',
          ],
          [
            'A menudo no sigue las instrucciones y no termina tareas escolares, quehaceres o deberes',
            '¿Con qué frecuencia la persona no sigue las instrucciones y no termina tareas escolares, quehaceres o deberes?',
          ],
          [
            'A menudo tiene dificultades para organizar tareas y actividades',
            '¿Con qué frecuencia tiene dificultades para organizar tareas y actividades?',
          ],
          [
            'A menudo evita, no le gusta o participa de mala gana en tareas que requieren esfuerzo mental sostenido',
            '¿Con qué frecuencia la persona evita, no le gusta o participa de mala gana en tareas que requieren esfuerzo mental sostenido?',
          ],
          [
            'A menudo pierde cosas necesarias para actividades (por ejemplo, juguetes, tareas escolares, lápices o libros',
            '¿Con qué frecuencia la persona pierde cosas necesarias para actividades (por ejemplo, juguetes, tareas escolares, lápices o libros)?',
          ],
          [
            'A menudo se distrae por estímulos irrelevantes',
            '¿Con qué frecuencia la persona se distrae por estímulos irrelevantes?',
          ],
          [
            'A menudo olvida actividades diarias',
            '¿Con qué frecuencia la persona olvida actividades diarias?',
          ],
          [
            'A menudo juguetea con las manos o los pies o se retuerce en el asiento',
            '¿Con qué frecuencia la persona juguetea con las manos o los pies o se retuerce en el asiento?',
          ],
          [
            'A menudo se levanta del asiento en el aula o en otras situaciones en las que se espera que permanezca sentado',
            '¿Con qué frecuencia la persona se levanta del asiento en el aula o en otras situaciones en las que se espera que permanezca sentado?',
          ],
          [
            'A menudo corre o trepa de manera excesiva en situaciones en las que es inapropiado',
            '¿Con qué frecuencia la persona corre o trepa de manera excesiva en situaciones en las que es inapropiado?',
          ],
          [
            'A menudo tiene dificultades para jugar o participar en actividades de ocio en silencio',
            '¿Con qué frecuencia la persona tiene dificultades para jugar o participar en actividades de ocio en silencio?',
          ],
          [
            'A menudo está "en marcha" o actúa como si estuviera "impulsado por un motor"',
            '¿Con qué frecuencia la persona está "en marcha" o actúa como si estuviera "impulsado por un motor"?',
          ],
          [
            'A menudo habla en exceso',
            '¿Con qué frecuencia la persona habla en exceso, incluso cuando no es su turno o interrumpe a los demás?',
          ],
          [
            'A menudo suelta respuestas antes de que se completen las preguntas',
            '¿Con qué frecuencia la persona suelta respuestas antes de que se completen las preguntas?',
          ],
          [
            'A menudo tiene dificultades para esperar su turno',
            '¿Con qué frecuencia la persona tiene dificultades para esperar su turno?',
          ],
          [
            'A menudo interrumpe o se entromete en otros (por ejemplo, se mete en conversaciones/juegos)',
            '¿Con qué frecuencia la persona interrumpe o se entromete en otros (por ejemplo, se mete en conversaciones/juegos)?',
          ],
          [
            '1A menudo pierde la calma',
            '¿Con qué frecuencia la persona pierde la calma?',
          ],
          [
            'A menudo discute con adultos',
            '¿Con qué frecuencia la persona discute con adultos, incluidos padres o maestros?',
          ],
          [
            'A menudo desafía activamente o se niega a cumplir las solicitudes o reglas de los adultos',
            '¿Con qué frecuencia la persona desafía activamente o se niega a cumplir las solicitudes o reglas de los adultos?',
          ],
          [
            'A menudo hace deliberadamente cosas que molestan a otras personas',
            '¿Con qué frecuencia la persona hace deliberadamente cosas que molestan a otras personas?',
          ],
          [
            'A menudo culpa a otros por sus errores o mal comportamiento',
            '¿Con qué frecuencia la persona culpa a otros por sus errores o mal comportamiento en lugar de asumir la responsabilidad?',
          ],
          [
            'A menudo es quisquilloso o se molesta fácilmente por otras personas',
            '¿Con qué frecuencia la persona es quisquillosa o se molesta fácilmente por otras personas, incluso por cosas pequeñas?',
          ],
          [
            'A menudo está enojado y resentido',
            '¿Con qué frecuencia la persona está enojada y resentida, incluso cuando parece injustificado?',
          ],
          [
            'A menudo es malicioso o vengativo',
            '¿Con qué frecuencia la persona se comporta de manera maliciosa o vengativa, como buscar venganza, al menos dos veces en los últimos 6 meses?',
          ],
        ],
        answers: {},
      },
      {
        typeAnswer: 'antes',
        title: 'Antes',
        keyApi: 'Antes_',
        questions: [
          '¿Estos problemas estaban presentes antes de los 7 años?',
          '¿Estos problemas estaban presentes antes de los 12 años?',
        ],
        answers: {},
      },
      {
        typeAnswer: 'cognitionAndSchoolLife',
        title: 'Cognición y Vida Escolar',
        keyApi: 'F_Cog_',
        questions: [
          'Matemáticas, cálculos',
          'Lectura, comprensión de texto',
          'Escritura, ortografía',
          'Aprender cosas fuera de la escuela (día a día)',
        ],
        answers: {},
      },
      {
        typeAnswer: 'interpersonalRelationships',
        title: 'Relaciones Interpersonales',
        keyApi: 'F_RI_',
        questions: [
          'Relación con los padres',
          'Conocer gente nueva, hacer amigos',
          'Entender los sentimientos o emociones de otras personas',
          'Entender sus propias emociones y sentimientos',
        ],
        answers: {},
      },
      {
        typeAnswer: 'activitiesOfDailyLiving',
        title: 'Actividades de la Vida Diaria',
        keyApi: 'F_AVD_',
        questions: [
          'Participa en actividades domésticas y rutinas',
          'Participa en actividades escolares',
          'Puede preparar y organizar las tareas',
          'Participa en actividades como grupos, iglesias, asociaciones',
        ],
        answers: {},
      },
      {
        typeAnswer: 'selfCare',
        title: 'Auto-Cuidado',
        keyApi: 'F_Auto_',
        questions: [
          'Controlar la orina y las heces',
          'Tomar una ducha y cepillarse los dientes',
          'Elegir y cambiar de ropa',
          'Usar tenedores, cuchillos y otros utensilios de forma segura',
        ],
        answers: {},
      },
      {
        typeAnswer: 'mobilityAndCoordination',
        title: 'Movilidad y Coordinación',
        keyApi: 'F_Mov_',
        questions: [
          'Caminar una distancia promedio (+-10 cuadras)',
          'Saltar y brincar durante bromas',
          'Jugar un deporte como fútbol o baloncesto',
          'Cortar papel con tijeras',
        ],
        answers: {},
      },
      {
        typeAnswer: 'communication',
        title: 'Comunicación',
        keyApi: 'F_Com_',
        questions: [
          'Expresarse bien a través del habla',
          'Entender lo que dicen las personas',
          'Tener un buen vocabulario, conocer muchas palabras',
          'Comunicarse a través de mensajes o texto',
        ],
        answers: {},
      },
      {
        typeAnswer: 'parentingStyles',
        title: 'Estilos de Crianza',
        keyApi: 'QEDP_S_',
        questions: [
          'Uso el castigo físico como una forma de disciplinar a mi hijo',
          'Cuando mi hijo se porta mal, lo azoto',
          'Animo a mi hijo a hablar sobre sus problemas',
          'Me resulta difícil disciplinar a mi hijo',
          'Animo a mi hijo a expresarse abiertamente, incluso cuando no estoy de acuerdo con él/ella',
          'Brindo consuelo y comprensión a mi hijo cuando está molesto',
          'Grito o hablo en voz alta cuando mi hijo se porta mal',
          'Tengo berrinches con mi hijo',
          'Amenazo con castigar a mi hijo con más frecuencia de la que realmente lo hago',
          'Determino castigos para mi hijo, pero realmente no los llevo a cabo',
          'Muestro respeto por las opiniones de mi hijo al animarlo a expresarse',
          'Consiento a mi hijo',
          'Tengo momentos cálidos y especiales con mi hijo',
          'Regaño y critico duramente a mi hijo cuando su comportamiento no cumple con mis expectativas',
          'Explico a mi hijo las consecuencias de su comportamiento',
        ],
        answers: {},
      },
      {
        typeAnswer: 'sociodemographic',
        title: 'Sociodemográfico',
        keyApi: 'SES_',
        questions: [
          'Baños',
          'Empleadas domésticas',
          'Automóviles',
          'Microcomputadora',
          'Lavavajillas',
          'Refrigerador',
          'Congelador',
          'Lava Ropa',
          'DVD',
          'Microondas',
          'Motocicleta',
          'Secadora de ropa',
          'Agua corriente',
          'Calle pavimentada',
        ],
        answers: {},
      },
    ],
    autismo: [
      'Prefiere hacer las cosas solo en lugar de con otros',
      'Prefiere hacer las cosas de la misma manera, por ejemplo, su rutina matinal o su viaje al supermercado',
      'Se absorbe intensamente en algo, incluso obsesivamente',
      'Es muy sensible a los ruidos y usará protectores auditivos o se cubrirá los oídos en ciertas situaciones',
      'A veces, las personas dicen que está siendo grosero, incluso si él piensa que está siendo educado',
      'Encuentra facilidad en imaginar cómo pueden lucir los personajes de un libro',
      'Encuentra facilidad para hablar en grupos de personas',
      'Está más interesado en descubrir sobre "cosas" que sobre personas',
      'Encuentra fascinantes los números, las fechas y las secuencias de información',
      'Prefiere libros y películas de no ficción a la ficción',
    ],
    dislexia: [
      '¿Deja de leer palabras o pierde el lugar cuando lee?',
      '¿Confunde palabras similares, como perro y Dios?',
      '¿Confunde el nombre de ciertos objetos?',
      '¿Se pierde con frecuencia o llega muy tarde a las reuniones?',
      '¿Confunde la izquierda con la derecha?',
      '¿Encuentra difícil seguir varias instrucciones a la vez?',
      '¿Lee una página varias veces para entenderla?',
    ],
    generalEvaluation: [
      {
        typeAnswer: 'interview',
        title: 'Entrevista',
        questions: [
          '¿Tiene dificultades para levantarse y prepararse para la escuela por la mañana?',
          '¿Toma mucho tiempo para completar tareas simples?',
          '¿Tiene dificultades para planificar y/o organizar tareas?',
          '¿Tiene dificultades con situaciones que requieren cambios y nuevas formas de hacer las cosas?',
          '¿Tiene dificultades para participar en actividades grupales?',
          '¿Tiene dificultades para estimar cuánto tiempo tomará completar una tarea o un proyecto?',
          '¿Parece cansarse fácilmente al hacer trabajos escolares?',
        ],
      },
    ],
  },
};
export const listOfCategoryWithoutDiagnosticQuestions = ['snaps'];
export const modelML = {
  tdah: {
    Idade: null, // age
    Sexo: null, // gender
    Escolaridade: null, // education
    Responsavel: null, // responsable
    Entrevista_1: null, // interview
    Entrevista_2: null,
    Entrevista_3: null,
    Entrevista_4: null,
    Entrevista_5: null,
    Entrevista_6: null,
    Entrevista_7: null,
    Entrevista_8: null,
    Entrevista_9: null,
    Entrevista_10: null,
    Entrevista_11: null,
    Entrevista_12: null,
    Cigarros: null, // cigarette
    Remedios: null, // medication
    Parto: null, // delivry
    IG: null, //
    Peso: null, // weight
    Comprimento: null, // length
    Chorou: null, // cried
    Prob_Saude: null, // Health_Problem
    Alta_junto: null, // Discharge_Together
    Apgar5: null,
    SNAP1: null,
    SNAP2: null,
    SNAP3: null,
    SNAP4: null,
    SNAP5: null,
    SNAP6: null,
    SNAP7: null,
    SNAP8: null,
    SNAP9: null,
    SNAP10: null,
    SNAP11: null,
    SNAP12: null,
    SNAP13: null,
    SNAP14: null,
    SNAP15: null,
    SNAP16: null,
    SNAP17: null,
    SNAP18: null,
    SNAP19: null,
    SNAP20: null,
    SNAP21: null,
    SNAP22: null,
    SNAP23: null,
    SNAP24: null,
    SNAP25: null,
    SNAP26: null,
    Antes_7: null,
    Antes_12: null,
    F_Cog_1: null,
    F_Cog_2: null,
    F_Cog_3: null,
    F_Cog_4: null,
    F_RI_1: null,
    F_RI_2: null,
    F_RI_3: null,
    F_RI_4: null,
    F_AVD_1: null,
    F_AVD_2: null,
    F_AVD_3: null,
    F_AVD_4: null,
    F_Auto_1: null,
    F_Auto_2: null,
    F_Auto_3: null,
    F_Auto_4: null,
    F_Mov_1: null,
    F_Mov_2: null,
    F_Mov_3: null,
    F_Mov_4: null,
    F_Com_1: null,
    F_Com_2: null,
    F_Com_3: null,
    F_Com_4: null,
    QEDP_S_1: null,
    QEDP_S_2: null,
    QEDP_S_3: null,
    QEDP_S_4: null,
    QEDP_S_5: null,
    QEDP_S_6: null,
    QEDP_S_7: null,
    QEDP_S_8: null,
    QEDP_S_9: null,
    QEDP_S_10: null,
    QEDP_S_11: null,
    QEDP_S_12: null,
    QEDP_S_13: null,
    QEDP_S_14: null,
    QEDP_S_15: null,
    SES_1: null,
    SES_2: null,
    SES_3: null,
    SES_4: null,
    SES_5: null,
    SES_6: null,
    SES_7: null,
    SES_8: null,
    SES_9: null,
    SES_10: null,
    SES_11: null,
    SES_12: null,
    SES_13: null,
    SES_14: null,
    CBCE_Classe: null, // class
    Date: null, // date
    Comments: [
      {
        UserRole: null,
        DateTime: null,
        Message: null,
        ImageUrl: null,
      },
    ],
  },
};

export const evaluationsType = ['adhd'];
