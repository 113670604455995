import { getContrastColor } from './function';
import { ConfigSizeImage, ITheme, TTypeConfigSizeImage } from './type';

const themeStyle: ITheme = {
  colors: {
    primary: '#0088CE',
    secondary: '#5b5757',
    third: 'null',
    fourth_first: '#93C530',
    fourth_second: '#ABD050',
    fourth_third: '#C3DB6F',
    fifth_second: '#49ACCC',
    fifth_third: '#5BBDD7',
    fifth_fourth: '#85D2E3',
    sixth_first: '#FDC700',
    seventh_first: '#E65B9B',
    seventh_second: '#ED88B7',
  },
  colorsText: {
    primary: '#707070',
    secondary: '#49ACCC',
    third: 'white',
    fourth: '#2d2626',
    fifth: '#666',
    error: '#671010',
    success: '#09ab21',
  },
  background: {
    primary: '#48ACCB',
    secondary: '#7A5BD9',
    third: '#FF6B10',
    fourth: '#f3f4f6;',
    fifth: '#463986',
  },
  spacing: {
    body_spacing_primary: 30,
    letter_spacing_primary: 0.2,
    letter_spacing_secondary: 0.15,
  },
  extra: {
    border_radius: 5,
    border_color: '#b3b3b3',
    transition_time: 500,
  },
};
const breakingPoints = {
  mobile: 450,
  tablet: 765,
  laptop: 950,
};
const configSizeImage: { [key in TTypeConfigSizeImage]: ConfigSizeImage } = {
  newJourney: {
    maxWidth: 700,
    maxHeight: 700,
    compressFormat: 'JPEG',
    quality: 100,
    rotation: 0,
    outputType: 'base64',
    minWidth: 0,
    minHeight: 0,
  },
  default: {
    maxWidth: 750,
    maxHeight: 750,
    compressFormat: 'JPEG',
    quality: 100,
    rotation: 0,
    outputType: 'base64',
    minWidth: 0,
    minHeight: 0,
  },
};

const templateSizes = {
  mobile: {
    height: {
      screen: 1280,
      header: 400,
    },
  },
};
export const colorPaletteHeaderFooter = [
  {
    colorText: getContrastColor('#ff7e5f'), // Light or dark text color based on background color
    mainColor: '#ff7e5f',
  },
  {
    colorText: getContrastColor('#4facfe'),
    mainColor: '#4facfe',
  },
  {
    colorText: getContrastColor('#12c2e9'),
    mainColor: '#12c2e9',
  },
  {
    colorText: getContrastColor('#56ab2f'),
    mainColor: '#56ab2f',
  },
  {
    colorText: getContrastColor('#ffcc33'),
    mainColor: '#ffcc33',
  },
  {
    colorText: getContrastColor('#2b5876'),
    mainColor: '#2b5876',
  },
  {
    colorText: getContrastColor('#0575e6'),
    mainColor: '#0575e6',
  },
  {
    colorText: getContrastColor('#f12711'),
    mainColor: '#f12711',
  },
  {
    colorText: getContrastColor('#E85C9E'),
    mainColor: '#E85C9E',
  },
  {
    colorText: getContrastColor('#F36B21'),
    mainColor: '#F36B21',
  },
  {
    colorText: getContrastColor('#FED672'),
    mainColor: '#FED672',
  },
  {
    colorText: getContrastColor('#4e4376'),
    mainColor: '#4e4376',
  },
];
const animationTransitionTime = {
  primary: 0.2,
};
export {
  themeStyle,
  breakingPoints,
  configSizeImage,
  animationTransitionTime,
  templateSizes,
};
