import * as React from 'react';
import { ReactNode, createContext, useState } from 'react';

export interface ContextProviderProps {
  children: ReactNode;
}

interface ContextValue {
  headerSize: { width: number; height: number };
  // eslint-disable-next-line no-unused-vars
  updateHeaderSize: (x: { width: number; height: number }) => void;
}

export const HeaderContextState = createContext<ContextValue>({
  headerSize: { width: 0, height: 0 },
  updateHeaderSize: () => {},
});

const HeaderProviderContext: React.FC<ContextProviderProps> = ({
  children,
}) => {
  const [headerSize, setHeaderSize] = useState({ width: 0, height: 0 });

  const updateHeaderSize = (value: { width: number; height: number }) =>
    setHeaderSize(value);

  return (
    <HeaderContextState.Provider value={{ headerSize, updateHeaderSize }}>
      {children}
    </HeaderContextState.Provider>
  );
};
export default HeaderProviderContext;
