import * as React from 'react';
import IconsLogos from '../../../../components/elements/IconsLogos';

type TProps = {
  props: { value: string; disabled?: boolean };
};

const SubmitFormV2: React.FC<TProps> = ({ props: { disabled } }) => {
  return (
    <button
      id="submit-formv2"
      className="on_hover submit_form_v2 button_no_decor"
      type="submit"
      disabled={disabled ? disabled : false}
      data-testid="submit-button-test"
    >
      <IconsLogos type={'arrowV3'} />
    </button>
  );
};
export default SubmitFormV2;
