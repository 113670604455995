import { useRouter } from 'next/router';
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import evaluationsData from '../../data/evaluations.json';
import { css } from '@emotion/react';
import { ITheme } from '../../../../services/style/type';

const componentCSS = {
  self: (props: ITheme) => css`
    gap: min(2.7%, 20px);

    .CircularProgressbar-trail {
      stroke: ${props.colors.fifth_fourth} !important;
    }
    .CircularProgressbar-path {
      stroke: ${props.colors.fifth_second} !important;
    }
  `,
};
const EvaluationProgress = ({ percentage }) => {
  const { locale } = useRouter();
  return (
    <div
      className="flex_end_center evaluation_progress"
      css={componentCSS.self}
    >
      <CircularProgressbar
        value={percentage}
        text={''}
        styles={{
          path: {
            stroke: '#49ACCC',
          },
        }}
      />
      <span className="font_size_23_light flex_align_center_no_wrap">
        {percentage}%
      </span>
      <span
        className="font_size_23_light"
        style={{ textTransform: 'capitalize' }}
      >
        {percentage > 99
          ? evaluationsData[locale].text[6]
          : evaluationsData[locale].text[7]}
      </span>
    </div>
  );
};

export default EvaluationProgress;
