import axios from 'axios';
import { routesOut } from '../../../services/bridge';
import { useQuery } from 'react-query';
import { TDiagnosticObject } from '../type';

export const getAllEvolutionsDiagnostics = (studentId: string) => {
  return useQuery(
    ['studentEvolutionDiagnostic', studentId], // this is the key
    async () => {
      const response = await axios.get(
        routesOut.data.getStudentEvolutionDiagnostics(studentId as string).link
      );
      return response;
    },
    { enabled: !!studentId, refetchOnWindowFocus: false }
  );
};

export const returnPercentagesFromDiagnosticObject = (
  diagnosticObject: TDiagnosticObject
) => {
  const studentPercentage =
    (diagnosticObject.patient / diagnosticObject.max) * 100;
  const adhdPercentage = (diagnosticObject.adhd / diagnosticObject.max) * 100;

  return { studentPercentage, adhdPercentage };
};

export const returnNumberOfDiagnosticsDisplayed = (data) => {
  return data.filter((entry) => entry.diagnostic !== null).length;
};
