import { css } from '@emotion/react';
import { useRouter } from 'next/router';
import * as React from 'react';
import { IInformationUser } from '../../../services/data';
import { roles } from '../../../services/data/constant';
import resultEvaluationData from '../data/resultEvaluation.json';
const componentCSS = {
  self: css`
    margin-bottom: 40px;
    .circle {
      width: 20px;
      height: 20px;
      border-radius: 50%;
    }
    .circle1 {
      background-color: #f1cf70;
    }
    .circle2 {
      background-color: #df5b9a;
    }
    .circle3 {
      background-color: #80cbdf;
    }
    .circle4 {
      background-color: transparent;
      border: 1px solid blue;
    }
  `,
};
const ChartHeader: React.FC<{
  informationUser: IInformationUser;
}> = ({ informationUser }) => {
  const { locale } = useRouter();
  const { divers, legend } = resultEvaluationData[locale];

  return (
    <div css={componentCSS.self}>
      <h2 className={'font_size_26_medium'} style={{ marginTop: '52px' }}>
        {divers[6]}
      </h2>
      <div className="flex_row_btw_wrap" style={{ marginBottom: '1rem' }}>
        <div>
          <div className="flex_row" css={{ gap: '18px' }}>
            <span
              className="font_size_22_medium"
              style={{ textTransform: 'capitalize' }}
            >
              {divers[2]}:{' '}
            </span>{' '}
            <span
              className="font_size_22_book"
              style={{ textTransform: 'capitalize' }}
            >
              {informationUser.name}{' '}
            </span>
          </div>
          <div className="flex_row" css={{ gap: '18px' }}>
            <span
              className="font_size_22_medium"
              style={{ textTransform: 'capitalize' }}
            >
              {divers[3]}:
            </span>
            <span
              className="font_size_22_book"
              style={{ textTransform: 'capitalize' }}
            >
              {' '}
              {roles[locale][informationUser.role]}
            </span>
          </div>
        </div>
        <div className="flex_row" css={{ gap: '18px' }}>
          <span
            className="font_size_22_medium"
            style={{ textTransform: 'capitalize' }}
          >
            {divers[4]}:
          </span>
          <span
            className="font_size_22_book"
            style={{ textTransform: 'capitalize' }}
          >
            {informationUser.dateEvaluation}
          </span>
        </div>
      </div>
      <div
        className="flex_align_center_justify_center"
        style={{ gap: 'min(1.5vw, 54px)', marginTop: '37px' }}
      >
        <p
          className="font_size_22_book"
          style={{ textTransform: 'capitalize' }}
        >
          {legend.title[4]}:
        </p>
        <div className="flex_align_center_no_wrap" style={{ gap: '7px' }}>
          <div className="circle circle1" />
          <div className="circle circle2" />
          <div className="circle circle3" />
          <span
            className="font_size_20_book"
            style={{ textTransform: 'uppercase' }}
          >
            {legend.title[2]}{' '}
          </span>
        </div>
        <div className="flex_align_center_no_wrap" style={{ gap: '5px' }}>
          <div className="circle circle4" />{' '}
          <span
            className="font_size_18_book"
            style={{ textTransform: 'uppercase' }}
          >
            {legend.title[3]}{' '}
          </span>
        </div>
      </div>
    </div>
  );
};

export default ChartHeader;
