import { css } from '@emotion/react';
import { motion } from 'framer-motion';
import React, { ReactNode } from 'react';
import { ITheme } from '../../services/style/type';

type Props = {
  children: ReactNode;
  isOverFlow?: boolean;
};

const componentCSS = {
  self: css({
    height: '100vh',
    width: '100vw',
    position: 'fixed',
    top: 0,
    left: 0,
    zIndex: 1000000,
  }),
  form: (props: ITheme) =>
    css({
      position: 'absolute',
      height: 'max-content',
      maxHeight: '90%',
      overflow: 'auto',
      top: 0,
      bottom: 0,
      margin: 'auto',
      left: 0,
      right: 0,
      borderRadius: `0 0 ${props.extra.border_radius * 3}px 0`,
      backgroundColor: 'white',
      textAlign: 'center',
      padding: '2rem',
      paddingTop: '2.2rem',
      paddingBottom: '2.2rem',
      zIndex: 200,
      maxWidth: '350px',
      '@media (min-width: 550px)': {
        maxWidth: '500px',
      },
      '& .close_button': {
        position: 'absolute',
        right: '0rem',
        top: '0rem',
        margin: '.5rem',
        borderRadius: '50%',
        color: 'white',
        padding: '.5rem',
      },
    }),
};
const FormWrapper: React.FC<Props> = ({ children, isOverFlow }) => {
  return (
    <motion.div
      animate={{ backgroundColor: '#2d2d2dbf' }}
      transition={{
        backgroundColor: { duration: 0.2 },
        default: { ease: 'linear' },
      }}
      css={componentCSS.self}
      className="div_form_wrapper_effect"
    >
      <div
        css={[componentCSS.form, { overflow: isOverFlow ? 'visible' : 'auto' }]}
        className="formWrapper"
      >
        {children}
      </div>
    </motion.div>
  );
};
export default FormWrapper;
