import axios from 'axios';

import {
  logErrorAsyncMessage,
  logMessage,
  returnDifferenceTimeStamp,
} from '../../common';
import { localStorageKeys } from './constant';
import { TImageTypeGenerator } from '../../../backend/customise/type';

const getImageFromGenerator = async (request) => {
  try {
    const result = await axios.post(process.env.API_GENERATOR_IMAGE, request, {
      headers: {
        'x-api-key': process.env.API_GENERATOR_IMAGE_SECRET,
      },
    });
    return result;
  } catch (error) {
    logMessage(`${logErrorAsyncMessage(
      'services/api',
      'getImageFromGenerator'
    )},
			${error}`);
    return { error: true, message: error.message };
  }
};
const formatRequest = (prompt: string, imageType: TImageTypeGenerator) => {
  return {
    UserPrompt: prompt,
    ImageType: imageType,
    Size: '1024x1024',
  };
};
function getIntervalVariableLocalStorage() {
  const storedTimestamp = localStorage.getItem(
    localStorageKeys.intervalKeyLocaleStorage
  );
  return storedTimestamp;
}
function getIncrementVariableLocalStorage() {
  const incrementVariable = localStorage.getItem(
    localStorageKeys.incrementKeyLocalStorage
  );
  return incrementVariable;
}
function initVariablesLocaleStorage() {
  const intervalVariableLocalStorage = getIntervalVariableLocalStorage();
  if (!intervalVariableLocalStorage) updateIntervalLocalStorage();
  const incrementVariableLocalStorage = getIncrementVariableLocalStorage();
  if (!incrementVariableLocalStorage) updateIncrementVariableLocalStorage();
}
function updateIntervalLocalStorage() {
  const newInterval = new Date().getTime();
  localStorage.setItem(
    localStorageKeys.intervalKeyLocaleStorage,
    newInterval.toString()
  );
}
function updateIncrementVariableLocalStorage() {
  let value = localStorage.getItem(localStorageKeys.incrementKeyLocalStorage);
  let increment = value !== null ? (parseInt(value, 10) + 1).toString() : '0';
  localStorage.setItem(localStorageKeys.incrementKeyLocalStorage, increment);
}
function restoreIncrementVariableLocalStorage() {
  localStorage.setItem(localStorageKeys.incrementKeyLocalStorage, '0');
}
function checkRuleIntervalVariableLocalStorage() {
  const intervalLocalStorage = getIntervalVariableLocalStorage();
  return (
    returnDifferenceTimeStamp(parseInt(intervalLocalStorage)) >
    parseInt(process.env.NEXT_PUBLIC_INTERVAL_API_GENERATOR)
  );
}
function checkRuleCreditsVariableLocaleStorage() {
  const storedIncrementVariableFromLocalStorage =
    getIncrementVariableLocalStorage();
  return (
    !storedIncrementVariableFromLocalStorage ||
    parseInt(storedIncrementVariableFromLocalStorage) <
      parseInt(process.env.NEXT_PUBLIC_CREDIT_BY_INTERVAL)
  );
}
function restoreVariablesLocalStorage() {
  restoreIncrementVariableLocalStorage();
  updateIntervalLocalStorage();
}
function checkAuthorisationToRequireImage() {
  const isIntervalValid = checkRuleIntervalVariableLocalStorage();
  const isEnoughCredits = checkRuleCreditsVariableLocaleStorage();

  if (!isIntervalValid && !isEnoughCredits) return false;
  return isIntervalValid || isEnoughCredits;
}
function checkIfWeRestoreAuthorisationToRequireImage() {
  const isIntervalValid = checkRuleIntervalVariableLocalStorage();
  const isEnoughCredits = checkRuleCreditsVariableLocaleStorage();
  if (isIntervalValid && !isEnoughCredits) restoreVariablesLocalStorage();
}
function updateOrCreatesVariablesLocalStorage() {
  updateIncrementVariableLocalStorage();
}

export {
  updateOrCreatesVariablesLocalStorage,
  checkIfWeRestoreAuthorisationToRequireImage,
  checkAuthorisationToRequireImage,
  updateIncrementVariableLocalStorage,
  updateIntervalLocalStorage,
  initVariablesLocaleStorage,
  getIncrementVariableLocalStorage,
  getIntervalVariableLocalStorage,
  formatRequest,
  getImageFromGenerator,
};
