import { css } from '@emotion/react';
import * as React from 'react';
import { errorCSS, labelCSS } from './sharedCSS';
import { TFormField } from '../../type';

type Props = {
  data: TFormField;
};

const componentCSS = {
  selfWrapper: css({
    margin: 'auto',
  }),
};
const TextArea: React.FC<Props> = ({
  data: { register, name, errors, label, placeHolder },
}) => {
  return (
    <div
      css={componentCSS.selfWrapper}
      className="flex_column_center textarea-container"
    >
      <label
        className="font_size_26_medium"
        htmlFor={label}
        css={labelCSS}
        key={name}
      >
        {label}
      </label>
      <textarea
        className="font_size_26_bold"
        aria-labelledby={label}
        {...register}
        placeholder={placeHolder}
        id={name}
      />
      <span className="font_size_22_light" css={errorCSS}>
        {errors[name] ? errors[name].message : ''}
      </span>
    </div>
  );
};
export default TextArea;
