import * as React from 'react';

import { css } from '@emotion/react';
import { ITheme } from '../../../../services/style';

type TProps = {
  props: { value: string; disabled?: boolean };
};
const componentCSS = {
  input: (props: ITheme) =>
    css({
      width: '100%',
      paddingRight: '2rem',
      paddingLeft: '2rem',
      paddingTop: '.5rem',
      paddingBottom: '.5rem',
      color: 'white',
      fontWeight: 'bold',
      textTransform: 'uppercase',
      letterSpacing: '.1rem',
      borderRadius: props.extra.border_radius,
      backgroundColor: props.background.primary,
      border: 'none',
      margin: 'auto',
      marginTop: '1rem',
      '@media (min-width: 450px)': {
        width: 'max-content',
      },
      '&:hover': {
        cursor: 'pointer',
      },
    }),
};
const SubmitForm: React.FC<TProps> = ({ props: { value, disabled } }) => {
  return (
    <input
      css={componentCSS.input}
      id="submit-form"
      className="on_hover submit_form"
      type="submit"
      disabled={disabled ? disabled : false}
      value={value}
      data-testid={'submit-button-test'}
    />
  );
};
export default SubmitForm;
