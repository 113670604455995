// yarn add bcryptjs  @types/bcryptjs --save
/****************************BCRYPTS ******************/
import * as bcrypt from 'bcryptjs';
import { logErrorAsyncMessage, logMessage } from '../common';

const bcryptHash = async (password: string): Promise<string | boolean> => {
  try {
    const salt = await bcrypt.genSalt(10);
    const hash = await bcrypt.hash(password, salt);
    return hash;
  } catch (error) {
    // eslint-disable-next-line no-console
    logMessage(`${logErrorAsyncMessage('security/bcrypt', 'bcryptHash')},
			${error}`);
    return false;
  }
};

const bcryptCompare = async (
  password: string,
  hash: string
): Promise<boolean> => await bcrypt.compare(password, hash);

export { bcryptCompare, bcryptHash };
