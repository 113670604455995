import { useRouter } from 'next/router';
import * as React from 'react';
import { Controller } from 'react-hook-form';
import Select from 'react-select';
import { TMultiSelection } from '../../../../../components/forms/selectSchoolClass/School';
import ComponentWithLogicDataFetching from '../../../../../components/utils/ComponentWithLogicDataFetching';
import { statusServer } from '../../../../../services/bridge';
import { generalData } from '../../../../../services/data';

import { getCountries } from '../../../../user';
import {
  TOption,
  customStylesSelection,
  errorCSS,
  labelCSS,
} from '../../../../forms';

interface ISchoolsHTML {
  props: {
    label: string;
    name: string;
    control: any;
    options: TOption[];
    countrySelectedId: number;
    isLoading: boolean;
    placeHolder: string;
    errors: string;
    defaultValue: string;
    updateValue: () => void;
  };
}

const CountryHTML: React.FC<ISchoolsHTML> = ({
  props: { label, name, control, options, isLoading, placeHolder, errors },
}) => {
  return (
    <div className="flex_column" data-testid={`form-${name}-testID`}>
      <label htmlFor={label} css={labelCSS} data-testid="amjade">
        {label}
        <Controller
          name={name}
          render={({ field }) => (
            <Select
              aria-labelledby={label}
              styles={customStylesSelection}
              classNamePrefix="react-select"
              inputId={label.replace(/\s+/g, '')}
              instanceId={label}
              onChange={field.onChange}
              placeholder={placeHolder}
              ref={field.ref}
              options={options}
              {...field}
              isLoading={isLoading}
            />
          )}
          control={control}
        />
      </label>
      <span className="font_size_22_light" css={errorCSS}>
        {errors[name] && errors[name].value ? errors[name].value.message : ''}
      </span>
    </div>
  );
};

export const Country: React.FC<TMultiSelection> = ({
  data: {
    label,
    name,
    control,
    placeHolder,
    errors,
    defaultValue,
    updateValue,
    secretField,
  },
}) => {
  const { locale } = useRouter();

  const { data, isError } = getCountries(generalData[locale].countries);

  const options =
    data && data.status === statusServer.success
      ? data.data.map((data: { id: number; name: string }) => {
          return { value: data.name, label: data.name, id: data.id };
        })
      : [];

  React.useEffect(() => {
    if (defaultValue && options && options.length) {
      (() => {
        const countryDefault = options.filter(
          (opt) => opt.id == defaultValue.id
        );
        if (countryDefault.length) {
          updateValue('country', {
            id: countryDefault[0].id,
            ...countryDefault[0],
          });
        } else {
          updateValue('country', {
            id: null,
            value: '',
            label: '',
          });
        }
      })();
    } else {
      updateValue('country', {
        id: null,
        value: '',
        label: '',
      });
    }
  }, [defaultValue, data && data.status]);
  const SelectCountry = ComponentWithLogicDataFetching(CountryHTML);

  if (secretField) return;
  return (
    <div>
      <SelectCountry
        isErrorServer={isError && !data}
        isLoading={false}
        props={{
          label,
          name,
          control,
          options,
          placeHolder,
          errors,
          defaultValue,
          updateValue,
        }}
      />
    </div>
  );
};
