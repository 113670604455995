import DOMPurify from 'isomorphic-dompurify';
import fetch from 'node-fetch';

import { v4 as uuidv4 } from 'uuid';
const logMessage = (message: string): void => {
  if (process.env.NEXT_PUBLIC_DEVELOPMENT == 'true') console.log(message);
};
const logErrorAsyncMessage = (path: string, method: string): string =>
  `*** file: ${path}, method: ${method} `;

const testFunction = (a: number, b: number) => a + b;

const returnInitialNames = (names, surname): string => {
  let nameParts = names.trim().split(/\s+/);
  const initials = nameParts
    .map((name) => name.charAt(0).toUpperCase())
    .join('.');
  const surnameInitial = surname.charAt(0).toUpperCase();
  return initials + '.' + surnameInitial;
};

const combineNestedArrays = (...arrays) => {
  let combinedArray = [].concat(...arrays);
  return combinedArray;
};
const addSpaceBeforeCapital = (str) => {
  if (!str) return;
  return str.replace(/\B([A-Z])\B/g, ' ' + '$1').toLowerCase();
};

function removeDuplicates(arr) {
  let seen = new Set();
  return arr.filter((item) => {
    if (seen.has(item.answer)) {
      return false;
    }
    seen.add(item.answer);
    return true;
  });
}

const createMarkup = (html) => {
  return {
    __html: DOMPurify.sanitize(html),
  };
};

function formatName(str) {
  if (!str) return;
  const string = str.replace(/([a-z])([A-Z])/g, '$1 $2').toLowerCase();
  return string.charAt(0).toUpperCase() + string.slice(1);
}
const getImageDataUrl = async (filename) => {
  try {
    const headers = new Headers({
      'x-api-key': process.env.API_GENERATOR_IMAGE_SECRET,
    });
    const imageUrl = filename;
    const imageUrlData = await fetch(imageUrl, {
      method: 'GET',
      headers: headers,
    });
    const buffer = await imageUrlData.arrayBuffer();
    const stringifiedBuffer = Buffer.from(buffer, 'binary');
    const contentType = imageUrlData.headers.get('content-type');
    const name = uuidv4();
    const contentLength = imageUrlData.headers.get('content-length');
    const sizeInBytes = parseInt(contentLength, 10);

    return {
      file: {
        data_url: stringifiedBuffer,
        name,
        type: contentType,
        size: sizeInBytes,
      },
    };
  } catch (error) {
    throw new Error(`file ${filename} no exist ❌`);
  }
};

export {
  addSpaceBeforeCapital,
  combineNestedArrays,
  createMarkup,
  formatName,
  getImageDataUrl,
  logErrorAsyncMessage,
  logMessage,
  removeDuplicates,
  returnInitialNames,
  testFunction,
};
