import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { TFormsType } from '../type';
import { getValidationSchema } from './function';
import { TLanguages } from '../../../services/data';

type TInitialValue = {
  [key: string]: any;
};

const connectReactHookFormWithYup = (
  typeForm: TFormsType,
  language: TLanguages
) => {
  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
    control,
    watch,
    setValue,
  } = useForm<TInitialValue>({
    // We connect our formik with YUP
    //https://www.npmjs.com/package/@hookform/resolvers
    //https://react-hook-form.com/api/useform/#resolver
    resolver: yupResolver(getValidationSchema(typeForm, language)),
  });
  return {
    handleSubmit,
    errors,
    reset,
    register,
    control,
    watch,
    setValue,
  };
};

export { connectReactHookFormWithYup };
