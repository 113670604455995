import { css } from '@emotion/react';
import * as React from 'react';
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Controller } from 'react-hook-form';
import { ITheme } from '../../../../services/style/type';
import { errorCSS, labelCSS } from './sharedCSS';
import { TFormField } from '../../type';

type TCalandar = {
  data: TFormField;
};

const componentCSS = {
  self: (props: ITheme) =>
    css({
      marginTop: '.5rem',
      color: props.colorsText.primary,
      '& .react-datepicker__input-container': {
        color: props.colorsText.primary,
      },

      '& input': {
        padding: '1.2rem',
        marginTop: '.2rem',
        borderRadius: `${props.extra.border_radius}px`,
        border: '1px solid',
        borderColor: 'hsl(0, 0%, 80%)',

        minWidth: 'max-content',
        width: '80%',
        fontSize: '22px',
        fontFamily: '"Light"',
        color: `${props.colorsText.primary} !important`,
        margin: 'auto',
        '@media (min-width: 550px)': {
          minWidth: 'max-content',
        },
      },

      '& .calendar': {
        position: 'absolute',
        left: 0,
        right: 0,
        margin: 0,
        height: '20rem',
        top: '0%',
        scale: 0,
        transition: 'scale 500ms',
        color: props.colorsText.primary,
        '& .react-calendar': {
          borderRadius: `0px 0px ${props.extra.border_radius}px ${props.extra.border_radius}px`,
          color: props.colorsText.primary,
        },
        '& .calendar_close': {
          backgroundColor: '#5e5d5d',
          borderRadius: `${props.extra.border_radius}px ${props.extra.border_radius}px 0px 0px`,
          '& button': {
            backgroundColor: 'transparent',
            border: 'none',
            color: 'white',
            fontWeight: 'bold',
            textTransform: 'uppercase',
          },
        },
      },
      '& .calendar_popup': {
        scale: '1',
        transition: 'scale 1000ms',
      },
    }),
};

const CalendarComp: React.FC<TCalandar> = ({
  data: { label, errors, name, control, defaultValue, updateValue },
}) => {
  React.useEffect(() => {
    if (defaultValue) {
      const defaultDate = defaultValue
        ? new Date(defaultValue as string)
        : null;
      updateValue(name, defaultDate);
    }
  }, []);
  return (
    <div css={componentCSS.self} className="flex_column">
      <label
        className="font_size_26_medium"
        htmlFor={label}
        css={labelCSS}
        key={name}
      >
        {label}
      </label>
      <Controller
        control={control}
        name={name}
        render={({ field: { onChange, onBlur, value, ref, name } }) => {
          const defaultDate = defaultValue
            ? new Date(defaultValue as string)
            : null;
          return (
            <ReactDatePicker
              ref={(elem) => {
                elem && ref(elem.input);
              }}
              aria-labelledby={label}
              name={name}
              className="font_size_26_bold"
              onChange={onChange}
              onBlur={onBlur}
              selected={value ? value : defaultDate}
              dateFormat="dd/MM/yyyy"
              id={name}
            />
          );
        }}
      />

      <span css={errorCSS}>{errors[name] ? errors[name].message : ''}</span>
    </div>
  );
};

export default CalendarComp;
