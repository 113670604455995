import { css } from '@emotion/react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import * as React from 'react';
import { buttonShared } from '../../../components/buttons/sharedCSS';
import IconsLogos from '../../../components/elements/IconsLogos';
import { routesIn } from '../../../services/bridge';
import { ITheme } from '../../../services/style/type';
import { getSessionData } from '../../../services/userSession';
import evaluationData from '../data/evaluations.json';
import { TProcessEvaluationComponentProps } from '../type';

import EvaluationDate from './elements/EvaluationDate';
import EvaluationProgress from './elements/EvaluationProgress';
import ShareEvaluation from './ShareEvaluation';

const componentCSS = {
  button: (props: ITheme) =>
    css({
      ...buttonShared(props.background.third),
      justifyContent: 'space-between',
      paddingTop: 'min(8%, 24px)',
      paddingBottom: 'min(8%, 24px)',

      '& .arrow_right_open svg': {
        fill: props.colors.fourth_first,
      },
      '&:hover': {
        cursor: 'default',
      },
      '& span': {
        color: props.colorsText.primary,
        textAlign: 'left',
        '&::first-letter': {
          textTransform: 'uppercase',
        },
      },
      '.green_span': {
        color: props.colors.fourth_first,
      },
      '& svg': {
        color: props.colors.fifth_second,
        fill: props.colors.fifth_second,
      },
      '& .process_eval_h3': {
        color: props.colorsText.primary,
      },
      '& h3': {
        '&::first-letter': {
          textTransform: 'uppercase',
        },
      },
    }),
  contributors: (props: ITheme) =>
    css({
      '&:hover': {
        cursor: 'pointer',
      },
      '& p': {
        color: props.colorsText.primary,
        textDecoration: 'underline',
        '&::first-letter': {
          textTransform: 'uppercase',
        },
      },
    }),
};
const ProcessEvaluationCard: React.FC<TProcessEvaluationComponentProps> = ({
  date,
  evaluationType,
  processEvaluationId,
  patientId,
  listUsers,
  resultGetStudentProfile,
  refetch,
}) => {
  const [doWeDisplay, setDoWeDisplay] = React.useState(false);
  const handleDisplayNewClient = () => setDoWeDisplay(!doWeDisplay);
  const { locale } = useRouter();
  const { userId } = getSessionData();
  const actualUserInfosEvaluation = listUsers.filter(
    (user) => user.id === userId,
  );

  return (
    <div css={componentCSS.button} className="flex_row_align_start">
      <div
        className="flex_column"
        style={{ alignItems: 'flex-start', gap: 'min(5.5vw, 40px)' }}
      >
        <EvaluationDate
          date={date ? date : ''}
          evaluationType={evaluationType}
        />
        <EvaluationProgress
          percentage={
            actualUserInfosEvaluation &&
            actualUserInfosEvaluation.length &&
            actualUserInfosEvaluation[0].percentageEval
              ? 100
              : 0
          }
        />
      </div>
      <div
        className="flex_column on_screen_mobile"
        style={{ alignItems: 'flex-end', gap: 'min(5.5vw, 40px)' }}
      >
        <Link
          href={{
            pathname: routesIn.eng.student.evaluation(
              patientId,
              processEvaluationId as string,
              evaluationType,
            ).link,
            query: { m: 2 },
          }}
          locale={locale}
          aria-label="link to evaluation"
          className={'flex_align_center_no_wrap arrow_right_open'}
        >
          <span className="green_span font_size_18_medium">
            {evaluationData[locale].text[4]}
          </span>
          <IconsLogos type={'arrow2'} />
        </Link>
        <div
          className={'flex_align_center_no_wrap hover_cursor'}
          style={{ gap: '10px' }}
          tabIndex={0}
          onKeyDown={(event) => {
            if (event.key === 'Enter') handleDisplayNewClient();
          }}
          role={'button'}
          onClick={() => handleDisplayNewClient()}
        >
          <IconsLogos type={'twoMens'} />
          <span className="green_span font_size_18_medium">
            {evaluationData[locale].text[5]}
          </span>
          <IconsLogos type={'share'} />
        </div>
      </div>
      {doWeDisplay && (
        <ShareEvaluation
          refetch={refetch}
          handleDisplayNewClient={handleDisplayNewClient}
          processEvaluationId={processEvaluationId}
          date={date}
          evaluationType={evaluationType}
          listUsers={listUsers}
          resultGetStudentProfile={resultGetStudentProfile}
        />
      )}
    </div>
  );
};
export default ProcessEvaluationCard;
