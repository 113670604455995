import { css } from '@emotion/react';
import { useRouter } from 'next/router';
import * as React from 'react';
import ReactDOM from 'react-dom';
import Select from 'react-select';
import { evaluationData } from '..';
import ButtonOriginal from '../../../components/buttons/ButtonOriginal';
import { buttonFormWithIcon } from '../../../components/footers/sharedCSS';

import Loader from '../../../components/utils/Loader';
import FormWrapper from '../../../components/wrappers/FormWrapper';
import { ITheme } from '../../../services/style/type';
import { customStylesSelection } from '../../forms';

export const componentCSS = {
  self: (props: ITheme) =>
    css({
      '& h2': {
        color: props.colorsText.fourth,
        '&::first-letter': {
          textTransform: 'uppercase',
        },
      },
      '& .button_original': {
        width: '80%',
        '@media (min-width: 550px)': {
          width: '60%',
        },
      },
      '& .multiselectionCheckBox_select__value-container': {
        width: '10rem',
        overflow: 'auto',
      },
      '& .new_evaluation_close': {
        position: 'absolute',
        right: '0rem',
        top: '0rem',
        margin: '.5rem',
        borderRadius: '50%',
        color: 'white',
        padding: '.5rem',
        border: '1px solid white',
        backgroundColor: props.background.third,
        width: 32,
        height: 32,
      },
      '& .calendar_button_select': {
        ...buttonFormWithIcon,
      },
      '& .loader_component': {
        '& svg': {
          stroke: `${props.background.third} !important`,
        },
      },
    }),
  buttonSaved: (props: ITheme) =>
    css({
      marginTop: '1rem',
      '& button': {
        margin: 'auto',
        backgroundColor: `${props.background.third} !important`,
      },
      '& .save_error': {
        color: props.colorsText.error,
      },
    }),
};

type TNewProcessEvaluation = {
  hide: () => void;
  // eslint-disable-next-line no-unused-vars
  handleSelectedOption: (options) => void;
  isLoading: boolean;
  notification: { type: string; text: string };
  options: { label: string; value: string }[];
};

// !refactor use formDefault
const NewProcessEvaluation: React.FC<TNewProcessEvaluation> = ({
  hide,
  handleSelectedOption,
  isLoading,
  notification,
  options,
}) => {
  const [selectedOption, setSelectedOption] = React.useState(null);
  const { locale } = useRouter();

  const handleChange = (selectedOption) => {
    setSelectedOption(selectedOption);
  };
  const SubmitDisplayHTML = () => {
    return <ButtonOriginal text={'Save'} icon={''} />;
  };

  return ReactDOM.createPortal(
    //@ts-ignore
    <FormWrapper>
      <div css={componentCSS.self}>
        <button
          className={'new_evaluation_close'}
          onClick={() => hide()}
          aria-labelledby="close select"
        >
          X
        </button>
        <h2 className="font_size_23_medium">
          {evaluationData[locale].divers[2]}
        </h2>
        <div style={{ paddingBottom: '2rem' }}>
          <Select
            styles={customStylesSelection}
            value={selectedOption}
            onChange={handleChange}
            options={options}
            id={'select_evaluation'}
            placeholder={evaluationData[locale].selects.default}
          />
        </div>
        {isLoading ? (
          <Loader />
        ) : (
          <div
            css={[
              componentCSS.buttonSaved,
              {
                display:
                  selectedOption && notification.type !== 'success'
                    ? 'inline'
                    : 'none !important',
              },
            ]}
            role="button"
            tabIndex={0}
            id={'button_send_select_evaluation'}
            onKeyDown={(event) => {
              if (event.key === 'Enter') {
                handleSelectedOption(selectedOption);
              }
            }}
            onClick={() => {
              handleSelectedOption(selectedOption);
              setTimeout(() => {
                hide();
              }, 4000);
            }}
          >
            {SubmitDisplayHTML()}
          </div>
        )}

        <p className={notification.type === 'error' ? 'save_error' : ''}>
          {notification.text}
        </p>
      </div>
    </FormWrapper>,
    document.body,
  );
};
export default NewProcessEvaluation;
