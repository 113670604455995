import { useRouter } from 'next/router';
import * as React from 'react';
import { IDiagnostic } from '../../../services/data';
import resultEvaluationData from '../data/resultEvaluation.json';
import DescriptionDiagnostic from './elements/DescriptionDiagnostic';
import IconAndText from '../../../components/elements/IconAndText';

const DescriptionChartResultWithText: React.FC<{
  diagnostics: IDiagnostic[];
}> = ({ diagnostics }) => {
  const { locale } = useRouter();
  const { diagnostic } = resultEvaluationData[locale];
  let indiceComparaisonPatientAdhd = 0;
  for (let i = 0; i < diagnostics.length; i++) {
    if (diagnostics[i].patient > diagnostics[i].adhd) {
      indiceComparaisonPatientAdhd++;
    }
  }

  const returnConclusion = (num) => {
    switch (num) {
      case 0:
        return diagnostic[8];

      case 1:
        return diagnostic[9];

      default:
        return diagnostic[7];
    }
  };
  return (
    <>
      <p
        className={'font_size_24_medium_italic'}
        style={{ marginTop: '0rem', marginBottom: '10px' }}
      >
        {diagnostic[0]}{' '}
      </p>
      {diagnostics[0].patient > diagnostics[0].adhd ? (
        <DescriptionDiagnostic diagnostic={diagnostic[1]} icon={'tickv2'} />
      ) : (
        <DescriptionDiagnostic diagnostic={diagnostic[2]} icon={'crossv2'} />
      )}

      {diagnostics[1].patient > diagnostics[1].adhd ? (
        <DescriptionDiagnostic diagnostic={diagnostic[3]} icon={'tickv2'} />
      ) : (
        <DescriptionDiagnostic diagnostic={diagnostic[4]} icon={'crossv2'} />
      )}

      {diagnostics[2].patient > diagnostics[2].adhd ? (
        <DescriptionDiagnostic diagnostic={diagnostic[5]} icon={'tickv2'} />
      ) : (
        <DescriptionDiagnostic diagnostic={diagnostic[6]} icon={'crossv2'} />
      )}
      <div className="font_size_24_medium flex_align_center_no_wrap">
        <IconAndText icon={'addhereMini'} text={''} />
        <p className="font_size_24_medium" style={{ marginTop: '2px' }}>
          {' '}
          {returnConclusion(indiceComparaisonPatientAdhd)}
        </p>
      </div>
      {/* <p css={{ fontStyle: 'italic', marginTop: '0rem' }}>{diagnostic[10]} </p> */}
    </>
  );
};

export default DescriptionChartResultWithText;
