import {
  S3Client,
  GetObjectCommand,
  // ListObjectsV2Command,
  // HeadObjectCommand,
} from '@aws-sdk/client-s3';
import { getSignedUrl } from '@aws-sdk/s3-request-presigner';
import axios from 'axios';
import { routesOut, statusServer } from '../bridge';
import { logMessage, logErrorAsyncMessage } from '../common';
import { expireTimeAWSLinkSeconds } from './constant';
const AWS = require('aws-sdk');

const client = new S3Client({
  region: process.env.AWS_REGION,
});

const s3 = new AWS.S3({
  accessKeyId: process.env.AWS_ACCESS_KEY_ID,
  secretAccessKey: process.env.AWS_SECRET_ACCESS_KEY,
  region: process.env.AWS_REGION,
});

const uploadFileFromBackEnd = async (object) => {
  try {
    let randomString = (Math.random() + 1).toString(36).substring(2);
    const name = randomString + object.file.name;
    const params = {
      Bucket: process.env.AWS_NAME_BUCKET,
      Key: name,
      Body: object.file.data_url,
    };
    const data = await s3.upload(params).promise();
    return data.Location;
  } catch (error) {
    logMessage(`${logErrorAsyncMessage(
      'amazon/AWSFunction',
      'uploadFileFromBackend',
    )},
  ${error}`);
    throw new Error(error);
  }
};

const uploadFileToS3 = async (file: any) => {
  try {
    let randomString = (Math.random() + 1).toString(36).substring(2);
    const temporaryURL = await axios.get(
      routesOut.data.getUrlS3(file.type, randomString + file.name).link,
    );
    const result = await axios.put(temporaryURL.data.url, file, {
      headers: {
        'Content-Type': `${file.type}`,
      },
    });
    return result.status === statusServer.success
      ? routesOut.aws.buckets.images + randomString + file.name
      : '';
  } catch (error) {
    logMessage(`${logErrorAsyncMessage('amazon/function', 'uploadFileToS3')},
  ${error}`);
    return '';
  }
};

const createPresignedUrlWithClient = async ({ bucket, key }) => {
  const command = new GetObjectCommand({ Bucket: bucket, Key: key });
  return getSignedUrl(client, command, { expiresIn: expireTimeAWSLinkSeconds });
};

// const fetchMetadata = async (key, bucketName) => {
//   const headCommand = new HeadObjectCommand({
//     Key: key,
//     Bucket: bucketName,
//   });
//   const response = await client.send(headCommand);
//   return response.Metadata;
// };

async function fetchMetadata(bucket: { Key: string; Bucket: string }) {
  const response = await s3.headObject(bucket).promise();
  return response.Metadata;
}

const listObjectsInABucketAWS = async (bucket: {
  Key: string;
  Bucket: string;
  Prefix: string;
}) => {
  const params = {
    Bucket: bucket.Bucket,
    Prefix: bucket.Prefix,

    ContinuationToken: null,
  };

  try {
    let isTruncated = true;
    let contents = [];

    while (isTruncated) {
      const { Contents, IsTruncated, NextContinuationToken } = await s3
        .listObjectsV2(params)
        .promise();
      // await client.send(command);
      // For each object, fetch additional metadata
      for (const object of Contents) {
        // const metadata = await fetchMetadata(object.Key, bucketName.Bucket);
        const metadata = await fetchMetadata({
          Bucket: bucket.Bucket,
          Key: object.Key,
        });
        //@ts-ignore
        object.Metadata = metadata;
        contents.push(object);
      }
      isTruncated = IsTruncated;
      // command.input.ContinuationToken = NextContinuationToken;
      params.ContinuationToken = NextContinuationToken;
    }

    return contents;
  } catch (error) {
    logMessage(`${logErrorAsyncMessage(
      'amazon/function',
      'listObjectsInABucketAWS',
    )},
  ${error}`);
  }
};

export {
  listObjectsInABucketAWS,
  createPresignedUrlWithClient,
  uploadFileToS3,
  uploadFileFromBackEnd,
};
