/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React from 'react';
import { ITheme } from '../../../../services/style/type';
import { errorCSS, inputLogin } from './sharedCSS';
import { TFormField } from '../../type';

type Props = {
  data: TFormField;
};
const componentCSS = {
  self: (props: ITheme) =>
    css({
      borderColor: props.colors.primary,
      ...inputLogin,
      '&::placeholder': {
        // color: props.textColors.primary,
        textAlign: 'center',
      },
    }),
};

const InputLogin: React.FC<Props> = ({
  data: {
    register,
    name,
    errors,
    type,
    placeHolder,
    defaultValue,
    updateValue,
  },
}) => {
  React.useEffect(() => {
    updateValue(name, defaultValue);
  }, [defaultValue]);
  return (
    <div className="flex_column_center_align">
      <input
        css={componentCSS.self}
        className="font_size_26_bold"
        type={type}
        defaultValue={defaultValue}
        data-testid={`form-${name}-testID`}
        {...register}
        aria-invalid={errors[name] ? errors[name].message : ''}
        placeholder={placeHolder}
        autoComplete="on"
      />
      <span className="font_size_22_light" css={[errorCSS]}>
        {errors[name] ? errors[name].message : ''}
      </span>
    </div>
  );
};
export default InputLogin;
