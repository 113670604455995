import IconAndText from '../../../../components/elements/IconAndText';
import { createMarkup } from '../../../../services/common';

const DescriptionDiagnostic = ({ diagnostic, icon }) => {
  return (
    <div className="flex_align_center_no_wrap">
      <IconAndText icon={icon} text={''} />

      <p
        className="font_size_24_book"
        dangerouslySetInnerHTML={createMarkup(diagnostic)}
      ></p>
    </div>
  );
};

export default DescriptionDiagnostic;
