import { themeStyle } from '../../services/style';

export const wrapperButtonsShared = {
  '& button': {
    marginBottom: '1rem',
  },
};

// eslint-disable-next-line no-unused-vars
export const buttonShared = (color: string) => {
  return {
    margin: 'auto',
    borderRadius: '0px 0px 25px 0px',
    paddingTop: 'min(5%, 24px)',
    paddingBottom: 'min(5%, 24px)',
    paddingLeft: 'min(3.75vw, 27px)',
    paddingRight: 'min(3.75vw, 27px)',
    boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.3)',
    backgroundColor: 'white',
    marginBottom: '1rem',
    width: '100%',

    color: themeStyle.colorsText.fourth,
    '&:hover': {
      cursor: 'pointer',
    },
  };
};

export const notificatioWhenEmpty = {
  maxWidth: '800px',
  margin: 'auto',
  color: themeStyle.colorsText.fourth,
};
