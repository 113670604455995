import CalendarComp from './Calendar';
import CheckBox from './CheckBox';
import ImageUpload from './ImageUpload';
import InputUI from './InputUI';
import MultiSelection from './MultiSelection';
import React from 'react';
import { School } from '../../../../components/forms/selectSchoolClass/School';
import { ClassRoom } from '../../../../components/forms/selectSchoolClass/ClassRoom';
import TextArea from './TextArea';
import JourneyForm from './JourneyForm';
import InputLogin from './InputLogin';
import { Country } from '../../../students/filteringStudents/components/screens/Country';
import { TFormField } from '../../type';
import MultiselectionCheckbox from './MultiselectionCheckbox';

export const getFieldForForm = (data: TFormField): React.ReactNode => {
  switch (data.tag) {
    case 'input':
      return <InputUI data={data} />;
    case 'multiselection':
      return <MultiSelection data={data} />;
    case 'uploadImage':
      return <ImageUpload data={data} isMultiple={false} />;
    case 'date':
      return <CalendarComp data={data} />;
    case 'checkBox':
      return <CheckBox data={data} />;
    case 'selectSchool':
      return <School data={data} />;
    case 'selectCountry':
      return <Country data={data} />;
    case 'countryId':
      return <School data={data} />;
    case 'selectClassRoom':
      return <ClassRoom data={data} />;
    case 'textArea':
      return <TextArea data={data} />;

    case 'inputLogin':
      return <InputLogin data={data} />;
    case 'uploadImages':
      return <ImageUpload data={data} isMultiple={true} />;
    case 'journey':
      return <JourneyForm data={data} />;
    case 'multiselectionCheckbox':
      return <MultiselectionCheckbox data={data} />;

    default:
      return null;
  }
};
