import { css } from '@emotion/react';
import { useRouter } from 'next/router';
import IconsLogos from '../../../../components/elements/IconsLogos';
import {
  EvalTypeComponent,
  EvaluationDateComponent,
} from '../../../evaluations';
import evolutionsData from '../../data/evolutions.json';
import { themeStyle } from '../../../../services/style';

const componentCSS = {
  self: css`
    gap: min(1rem, 43px);
    .evaluation_date {
      gap: 12px !important;
    }
    .evaluation_type {
      gap: 12px !important;
    }

    h4 {
      font-weight: 400;
    }
    .column_gap {
      gap: 5px;
    }
  `,
};
const HeaderEvolutionCard = ({ date, name }) => {
  const { locale, query } = useRouter();
  return (
    <div className="flex_row_btw_center" css={componentCSS.self}>
      <div className="flex_column column_gap">
        <span className="font_size_22_light">
          {evolutionsData[locale].text[2]}:{' '}
        </span>
        <EvaluationDateComponent date={date} />
      </div>
      <div className="flex_column column_gap">
        <span className="font_size_22_light">
          {evolutionsData[locale].text[3]}:{' '}
        </span>
        <div className="flex_align_center_no_wrap">
          <span
            style={{
              width: '2px',
              height: '1.9dvh',
              border: `1px solid ${themeStyle.colorsText.primary}`,
              marginRight: '10%',
            }}
            className="separator"
          ></span>
          <EvalTypeComponent evaluationType={query.evalType} />
          <span
            style={{
              width: '2px',
              height: '1.9dvh',
              border: `1px solid ${themeStyle.colorsText.primary}`,
              marginLeft: '1rem',
            }}
            className="separator"
          ></span>
        </div>
      </div>
      <div className="flex_column column_gap" style={{ marginLeft: '1rem' }}>
        <span className="font_size_22_light">
          {evolutionsData[locale].text[1]}:{' '}
        </span>
        <div className="flex_align_center" style={{ gap: '12px' }}>
          <IconsLogos type="menSearch" />
          <p className="font_size_22_medium" style={{ margin: 0 }}>
            {name}
          </p>
        </div>
      </div>
    </div>
  );
};

export default HeaderEvolutionCard;
