import { TConfigVideoType } from './type';

export const administration = {
  headOffice:
    ' The Innovation Centre – Sci-Tech Daresbury – Keckwick Lane – Daresbury – Cheshire – WA4 4FS – UK',
  phoneNumber: '+55 (11) 97612-5852 ',
  openingTime: '09:00 – 18:00',
  website:
    process.env.NEXT_PUBLIC_APP_ENV_DEVELOPMENT === 'true'
      ? 'http://localhost:3000'
      : 'https://app.addhere.co.uk',
  emaiAssistance:
    process.env.NEXT_PUBLIC_APP_ENV_DEVELOPMENT === 'true'
      ? 'j.sba@outlook.com'
      : process.env.NOTIFICATION_EMAIL,
  emailContact: 'contato@addhere.co.uk',
  linkOfficialIcon:
    'https://firebasestorage.googleapis.com/v0/b/addhere-371016.appspot.com/o/images%2Faddhere.png?alt=media&token=de2e09b0-d61c-4e17-9537-8158471ba2db',
};

//@ts-ignore
export const configVideo: { [key in TConfigVideoType]: any } = {
  youtube: {
    playerVars: { showinfo: 0, autoplay: 1, controls: 0, loop: 1 },
  },
  local: {},
};
