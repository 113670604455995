import { ListEvolutions } from './components/ListEvolutions';
import { translateDiagnostic } from './data/constant';
import { returnNumberOfDiagnosticsDisplayed } from './service/function';
import evolutionsData from './data/evolutions.json';

export {
  translateDiagnostic,
  ListEvolutions,
  returnNumberOfDiagnosticsDisplayed,
  evolutionsData,
};
