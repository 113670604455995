import { regex } from 'uuidv4';
import {
  languages,
  roles,
  imagePropsTeacherDefault,
  dataTestID,
  optionsLanguages,
  ordinal,
  dayName,
  days,
  mappingLanguages,
  months,
} from './constant';
import {
  ContextProviderProps,
  IInformationsUser,
  InfoProcessEvaluation,
  IInfosProcessEvaluation,
  IInformationUser,
  IDiagnostic,
  IReactQueryGet,
  Functionality,
  IFileInfos,
  TPreconisationMetric,
  Symptom,
  IPreconisationModel,
  IUser,
  Patient,
  IStaticData,
  INavigations,
  IInterpretation,
  INotification,
  IAgreement,
  IHome,
  IHeaderHome,
  TMenuBurgerLink,
  TMenuLink,
  IPerceptions,
  IPerceptionsHeader,
  IPerceptionsFooter,
  IPatients,
  IButton,
  IPatient,
  IPatientHeader,
  IPatientFetched,
  ICommon,
  IHeaderSEO,
  Meta2,
  Meta,
  IHeader,
  IProfile,
  TModes,
  TLanguages,
} from './type';
import { getPatientDetailsUseCase, mockSession } from './useCase/fakeData';
import generalData from './json/general.json';
export type {
  ContextProviderProps,
  IInformationsUser,
  InfoProcessEvaluation,
  IInfosProcessEvaluation,
  IInformationUser,
  IDiagnostic,
  IReactQueryGet,
  Functionality,
  IFileInfos,
  TPreconisationMetric,
  Symptom,
  IPreconisationModel,
  IUser,
  Patient,
  IStaticData,
  INavigations,
  IInterpretation,
  INotification,
  IAgreement,
  IHome,
  IHeaderHome,
  TMenuBurgerLink,
  TMenuLink,
  IPerceptions,
  IPerceptionsHeader,
  IPerceptionsFooter,
  IPatients,
  IButton,
  IPatient,
  IPatientHeader,
  IPatientFetched,
  ICommon,
  IHeaderSEO,
  Meta2,
  Meta,
  IHeader,
  IProfile,
  TModes,
  TLanguages,
};

export {
  languages,
  roles,
  regex,
  imagePropsTeacherDefault,
  dataTestID,
  optionsLanguages,
  ordinal,
  dayName,
  days,
  mappingLanguages,
  months,
  getPatientDetailsUseCase,
  mockSession,
  generalData,
};
