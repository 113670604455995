import { configSizeImage } from '../../style/constant';
import { TTypeConfigSizeImage } from '../../style/type';
import Resizer from 'react-image-file-resizer';
const resizeFile = (file, typeFile: TTypeConfigSizeImage) =>
  new Promise((resolve) => {
    const configImage = configSizeImage[typeFile]
      ? configSizeImage[typeFile]
      : configSizeImage.default;
    Resizer.imageFileResizer(
      file,
      configImage.maxWidth,
      configImage.maxHeight,
      configImage.compressFormat,
      configImage.quality,
      configImage.rotation,
      (uri) => {
        resolve(uri);
      }, //   responseUriFunc, // Is the callBack function of the resized new image URI.
      configImage.outputType
      //0 => //   minWidth, // Is the minWidth of the resized new image.
      //0 => //  minHeight // Is the minHeight of the resized new image.
    );
  });
function urltoFile(url, filename, mimeType) {
  return fetch(url)
    .then(function (res) {
      return res.arrayBuffer();
    })
    .then(function (buf) {
      return new File([buf], filename, { type: mimeType });
    });
}

export { urltoFile, resizeFile };
