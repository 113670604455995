import React from 'react';
import resultEvaluationData from '../data/resultEvaluation.json';
import { ITheme } from '../../../services/style/type';
import { css } from '@emotion/react';
import { useRouter } from 'next/router';

const componentCSS = {
  self: (props: ITheme) =>
    css({
      '& .on_print': {
        //using display: none does not work with print for chart
        maxHeight: ' 0px',
        overflow: 'hidden',
      },
      '@media print': {
        '& .on_print': {
          maxHeight: 'none',
          backgroundColor: props.background.fourth,
        },
        '& .background_slider_interpretation': {
          display: 'none',
        },
        /* styles here */
      },

      '& .background_slider_interpretation': {
        backgroundColor: props.background.fourth,
        borderRadius: '5px',
        padding: '2rem',
        '@media (max-width: 850px)': {
          '& .slick-next, .slick-prev': {
            top: '400px !important',
          },
          '& .slick-next': {
            right: '-10px',
          },
          '& .slick-prev': {
            left: '-10px',
          },
        },

        '& svg': {
          color: '#b4b4b4',
          '@media (max-width: 700px)': {
            color: '#b4b4b4 !important',
          },
        },
      },
    }),

  introduction: (props: ITheme) =>
    css({
      '& .description_text': {
        backgroundColor: '#F2F2F2',

        padding: '45px',
        borderRadius: `${props.extra.border_radius * 2}px`,
      },
      '& .description_text p:first-of-type': {
        marginTop: 0,
      },
      '& .description_text p:last-of-type': {
        marginBottom: 0,
      },
      '& p': {
        color: props.colorsText.primary,
        textAlign: 'justify',
        '&::first-letter': {
          textTransform: 'uppercase',
        },
      },
    }),
  key: (props: ITheme) =>
    css({
      color: props.colorsText.primary,
      fontWeight: 'bold',
      textAlign: 'justify',
      textTransform: 'capitalize',
    }),
};

const Description: React.FC = () => {
  const { locale } = useRouter();
  const { introduction, divers } = resultEvaluationData[locale];
  return (
    <div css={componentCSS.introduction} id="description">
      <h2 className="font_size_26_medium" style={{ marginBottom: '30px' }}>
        {' '}
        {divers[5]}
      </h2>
      <div className="description_text">
        {introduction.map((sentence, index) => (
          <p className="font_size_24_light" key={index}>
            {' '}
            {sentence}
          </p>
        ))}
      </div>
    </div>
  );
};
export default Description;
