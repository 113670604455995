import { css } from '@emotion/react';
import * as React from 'react';
import errors from '../../services/data/json/errors.json';
import { useRouter } from 'next/router';
import { ITheme } from '../../services/style/type';

const ErrorComponent: React.FC<{ isErrorStatus?: number }> = ({
  isErrorStatus,
}) => {
  const base = (props: ITheme) => css`
    color: ${props.colorsText.fourth};
  `;
  const { locale } = useRouter();
  const [notification, setNotification] = React.useState('');
  React.useEffect(() => {
    setNotification(
      isErrorStatus ? errors[locale][isErrorStatus] : errors[locale].server
    );
  }, [isErrorStatus]);

  return (
    <div data-testid="errorComponent-id" css={base} id="error-component">
      <p style={{ textAlign: 'center' }}> {notification}</p>
    </div>
  );
};
export default ErrorComponent;
