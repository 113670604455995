import { connectReactHookFormWithYup } from './service/UtilComponent';
import { submitFormCases } from './data/constant';
import {
  doWeResizeImage,
  getValidationSchema,
  handleNotification,
  isFormValidated,
} from '../../features/forms/service/function';
import {
  Errors,
  ExtendedNextApiRequest,
  IBodyGetDataAdmin,
  IBodyGetEvaluation,
  Interest,
  Profile,
  Register,
  TDivers,
  TForm,
  TFormField,
  TForms,
  TFormsType,
  TNewUserFormValue,
  TOption,
} from './type';
import formsData from './data/formsData.json';
import Calendar from './components/elements/Calendar';
import CheckBox from './components/elements/CheckBox';
import ImageUpload from './components/elements/ImageUpload';
import InputLogin from './components/elements/InputLogin';
import InputUI from './components/elements/InputUI';
import JourneyForm from './components/elements/JourneyForm';
import MultiSelection from './components/elements/MultiSelection';
import SubmitForm from './components/elements/SubmitForm';
import SubmitFormV2 from './components/elements/SubmitFormV2';
import TextArea from './components/elements/TextArea';
import FormDefault from './components/FormDefault';
import {
  labelCSS,
  errorCSS,
  successCSS,
  inputLogin,
  windowPopUpCSS,
  inputCSS,
  customStylesSelection,
  roleStyleSelect,
} from './components/elements/sharedCSS';

export {
  connectReactHookFormWithYup,
  doWeResizeImage,
  getValidationSchema,
  handleNotification,
  isFormValidated,
  submitFormCases,
  formsData,
  Calendar,
  CheckBox,
  ImageUpload,
  InputLogin,
  InputUI,
  JourneyForm,
  MultiSelection,
  SubmitForm,
  SubmitFormV2,
  TextArea,
  FormDefault,
  labelCSS,
  errorCSS,
  successCSS,
  inputLogin,
  windowPopUpCSS,
  inputCSS,
  customStylesSelection,
  roleStyleSelect,
};
export type {
  Errors,
  ExtendedNextApiRequest,
  IBodyGetDataAdmin,
  IBodyGetEvaluation,
  Interest,
  Profile,
  Register,
  TDivers,
  TForm,
  TFormField,
  TForms,
  TFormsType,
  TNewUserFormValue,
  TOption,
};
