import { css } from '@emotion/react';
import { useRouter } from 'next/router';
import * as React from 'react';
import { generalData } from '../../services/data';
import IconsLogos from './IconsLogos';

type TBackButton = {
  type?: string;
};
const componentCSS = {
  shared: css({
    '& span': {
      color: 'white',
      textTransform: 'capitalize',
      marginTop: '.2rem',
    },

    '&:hover': {
      cursor: 'pointer',
    },
  }),
};
const BackButton: React.FC<TBackButton> = ({ type }) => {
  const router = useRouter();
  return (
    <button
      css={[componentCSS.shared, componentCSS[type ? type : 'original']]}
      onClick={() => router.back()}
      className="back_button button_no_decor flex_align_center_no_wrap"
      style={{ gap: '5px' }}
      aria-label="back-button"
    >
      <IconsLogos type={'arrowBack'} />
      <span className="font_size_18_light">
        {generalData[router.locale].back}
      </span>
    </button>
  );
};
export default BackButton;
