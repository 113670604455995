import { css } from '@emotion/react';
import * as React from 'react';

type TCloseButton = {
  functionToClose: () => void;
  color: string;
};

const componentCSS = {
  self: css`
    border-radius: 50%;
    color: white;
    border: none;
    padding: 0.5rem;
  `,
};
const CloseButton: React.FC<TCloseButton> = ({ functionToClose, color }) => {
  return (
    <button
      css={[{ backgroundColor: color ? color : 'grey' }, componentCSS.self]}
      className="close_button"
      type="button"
      onClick={() => functionToClose()}
    >
      X
    </button>
  );
};
export default CloseButton;
