import { css } from '@emotion/react';
import { useRouter } from 'next/router';
import * as React from 'react';
import ExpendAnimationHeight from '../../../../components/animations/ExpendAnimationHeight';
import IconsLogos from '../../../../components/elements/IconsLogos';
import evaluationsData from '../../data/evaluations.json';
import UserInvitedPrivilegeForm from './ViewPrivileges';
import { getSessionData } from '../../../../services/userSession';

const componentCSS = {
  self: css`
    width: 100%;

    .user-list_name {
      margin-left: 1vw;
    }
    @media (min-width: 600px) {
      .user-list_name {
        margin-left: 2rem;
      }
    }

    @media (min-width: 750px) {
      .user-list_name {
        margin-left: 3.5rem;
      }
    }
    .user-list-header {
      margin-top: min(3.9dvh, 20px);
      font-weight: bold;
    }
    .user-list-title svg path {
      fill: #ff6b10;
    }

    .grid-container {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr; /* Three equal-width columns */
      width: 100%;
      gap: min(3.2dvh, 40px);
    }
    .full-width {
      grid-column: 1 / -1; /* Span across all columns */
    }
    .header {
      font-weight: bold;
      margin-bottom: min(3.2dvh, 40px);
    }

    .grid-container p {
      text-align: center;
    }

    .grid-container p:last-child {
      border-right: none;
    }
  `,
};
const ShareUserInfoOnEvaluation: React.FC<any> = ({ listUsers, refetch }) => {
  const { locale } = useRouter();
  const [clickedIndex, setClickedIndex] = React.useState(null);
  const handleClick = (index) => {
    setClickedIndex(index === clickedIndex ? null : index);
  };
  const { userId } = getSessionData();
  console.log(listUsers, userId);
  return (
    <div css={componentCSS.self}>
      <div className="user-list-header grid-container header">
        <div
          className="flex_align_center_no_wrap user-list-title"
          style={{ gap: '18px', margin: 'auto' }}
        >
          <IconsLogos type={'twoMens'} />
          <p className="font_size_22_medium">
            {evaluationsData[locale].text[9]}:
          </p>
        </div>
        <p className="font_size_22_book user-list-title">
          {evaluationsData[locale].text[10]}:
        </p>
        <p className="font_size_22_book user-list-title">
          {evaluationsData[locale].text[11]}:
        </p>
      </div>
      {listUsers.map((user, index) => {
        if (user.id == userId) return;
        return (
          <div key={index} className="grid-container ">
            <div
              className="flex user-list-value user-list_name "
              style={{ gap: '18px', width: 'max-content' }}
            >
              <IconsLogos type="menv2" />
              <span
                className="font_size_22_book"
                style={{ whiteSpace: 'nowrap' }}
              >
                {' '}
                {user.name}
              </span>
            </div>
            <div
              key={index}
              className="flex_align_center_justify_center user-list-value user_eye_wrapper"
              style={{ gap: '3vw' }}
              onClick={() => handleClick(index)}
              role="button"
              tabIndex={0}
              onKeyDown={(event) => {
                if (event.key === 'Enter') handleClick(index);
              }}
            >
              <IconsLogos type={user.listUsers ? 'eye' : 'eye'} />
              <span className="font_size_22_light">
                {evaluationsData[locale].text[12]}
              </span>{' '}
            </div>
            <div className="user-list-value user_tick_cross">
              <IconsLogos
                key={index}
                type={user.hasCompletedEvaluation ? 'tick' : 'cross'}
              />
            </div>
            <div className="full-width">
              <ExpendAnimationHeight isOpen={index === clickedIndex}>
                <div
                  style={{
                    backgroundColor: 'white',
                    zIndex: 20,
                    marginBottom: 'min(3.2dvh, 20px)',
                  }}
                >
                  <UserInvitedPrivilegeForm
                    privilegesOfTheUser={user.UserInvitedPrivilege}
                    refetch={refetch}
                    userInvitedId={user.id}
                  />
                </div>
              </ExpendAnimationHeight>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default ShareUserInfoOnEvaluation;
