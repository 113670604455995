/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React from 'react';
import { ScreenTwo } from '../../../features/students/filteringStudents/components/ScreenTwo';
import { TFormField, windowPopUpCSS } from '../../../features/forms';

export type TMultiSelection = {
  data: TFormField;
};

export type TOption = {
  label: string;
  id: number | null;
  value: string;
  index?: number | string;
};
const componentCSS = {
  self: css({
    position: 'relative',
  }),
  wrapperSelector: windowPopUpCSS,
};

interface TSchoolOption extends TOption {
  countryId: null | number;
}
export type TGeneral = {
  school: TSchoolOption;
  country: TOption;
};

export const School: React.FC<TMultiSelection> = ({
  data: {
    label,
    options,
    errors,
    name,
    control,
    tag,
    type,
    placeHolder,
    register,
    updateValue,
    defaultValue,
    tractValuesForm,
    secretField,
  },
}) => {
  return (
    <div css={[componentCSS.self]}>
      <ScreenTwo
        data={{
          label,
          options,
          errors,
          name,
          control,
          tag,
          type,
          placeHolder,
          register,
          updateValue,
          tractValuesForm,
          secretField,
        }}
        isDefaultValue={defaultValue}
      />
    </div>
  );
};
