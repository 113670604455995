import { css } from '@emotion/react';
import Image from 'next/image';
import LoaderSkelaton from './utils/LoaderSkelaton';
import React from 'react';
const componentCSS = {
  cardImg: css(`
	top: 0;
	left: 0;
	height: 100vh;
	width: 100vw;
	position: fixed;
	z-index: -100;
	`),
};
type Props = {
  banner: string;
  loading: boolean;
  handleSetLoading: () => void;
};
const BannerUser: React.FC<Props> = ({ banner, loading, handleSetLoading }) => {
  return (
    <>
      {banner && (
        <>
          <Image
            src={banner}
            alt={'background image'}
            css={componentCSS.cardImg}
            width={1000}
            height={1000}
            onLoadingComplete={() => handleSetLoading()}
          />

          {loading && (
            <div css={componentCSS.cardImg}>
              <LoaderSkelaton />
            </div>
          )}
        </>
      )}
    </>
  );
};
export default BannerUser;
