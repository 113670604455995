import { css } from '@emotion/react';
import IconsLogos from '../elements/IconsLogos';

const componentCSS = {
  self: css`
    svg {
      color: grey;
    }
  `,
};
const PrintButtonv2 = () => {
  return (
    <button
      css={componentCSS.self}
      className="button_no_decor"
      onKeyDown={(event) => {
        if (event.key === 'Enter') window.print();
      }}
      onClick={() => window.print()}
    >
      <IconsLogos type={'printer'} />
    </button>
  );
};

export default PrintButtonv2;
