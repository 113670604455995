import { css } from '@emotion/react';
import { useSession } from 'next-auth/react';
import { useRouter } from 'next/router';
import * as React from 'react';
import ReactDOM from 'react-dom';
import { getUserDetails } from '..';
import { IBodyUpdateUserDetails } from '../../../backend/user';
import ComponentWithLogicDataFetching from '../../../components/utils/ComponentWithLogicDataFetching';
import FormWrapper from '../../../components/wrappers/FormWrapper';
import { ITheme } from '../../../services/style/type';
import { returnDetailsUser } from '../../../services/user/functions';
import FormDefault from '../../forms/components/FormDefault';
import userData from '../data/user.json';
import { themeStyle } from '../../../services/style';
import CloseButton from '../../../components/buttons/CloseButton';

const componentCSS = {
  self: (props: ITheme) =>
    css({
      '& .check_box_wrapper': {
        marginTop: '.7rem',
      },
      '& .checkbox_input': {
        display: 'inline',
        '& p': {
          display: 'inline',
        },
      },
      '& .image_upload': {
        '& span': {
          textTransform: 'capitalize',
        },
        '& .image_upload_button_select': {
          backgroundColor: props.background.secondary,
        },
        '& .image-item': {
          '& img': {
            borderRadius: '50%',
            height: '6rem',
            width: '6rem',
            marginTop: '.5rem',
            marginBottom: '.5rem',
          },
        },
        '& .image-item__btn-wrapper': {
          '& button': {
            color: 'white',
            '&:first-of-type': {
              display: 'none',
            },
            backgroundColor: props.background.secondary,
          },
        },
      },
      '& button': {
        backgroundColor: `${props.background.primary} !important`,
        border: 'none',
      },
      '& .submit_form': {
        backgroundColor: `${props.background.primary} !important`,
      },
      '& .loader_component': {
        '& svg': {
          stroke: `${props.background.primary}  !important`,
        },
      },
    }),
  notification: css({
    color: 'grey',
    fontStyle: 'italic',
  }),
};
type TEditUserDetails = {
  hideForm: () => void;
  passRefetch?: () => void;
};

interface IProps {
  props: {
    userDetails: IBodyUpdateUserDetails;
    hideForm: () => void;
  };
}
const UpdateUserHTML: React.FC<IProps> = ({
  props: { hideForm, userDetails },
}) => {
  const { locale, reload } = useRouter();
  const { updateUserDetails } = userData[locale];
  const { data: session } = useSession();
  const [forceRender, setForceRender] = React.useState(false);

  const refreshSession = () => {
    setTimeout(() => {
      hideForm();
      reload();
    }, 3000);
  };

  // @ts-ignore
  const userId = session && session.user ? session.user.id : null;
  const user = returnDetailsUser(session, { data: { data: userDetails } });
  React.useEffect(() => {
    if (userDetails) {
      updateUserDetails.fields[0].defaultValue = user.name;
      updateUserDetails.fields[1].defaultValue = userDetails.email;
      updateUserDetails.fields[2].defaultValue = userId;
      updateUserDetails.fields[3].defaultValue = user.authorisationEmailing;
      setForceRender(!forceRender);
    }
  }, [userDetails]);

  return (
    <>
      <FormDefault {...updateUserDetails} refetch={refreshSession} />
      {/* <p css={componentCSS.notification}> {notification} </p> */}
    </>
  );
};
const UpdateUserDetails: React.FC<TEditUserDetails> = ({ hideForm }) => {
  const { data: session } = useSession();
  //@ts-ignore
  const userId = session && session.user ? session.user.id : null;
  let userDetails = getUserDetails(userId);
  const refetch = () => userDetails.refetch();
  const UpdateUserHOC = ComponentWithLogicDataFetching(UpdateUserHTML);
  return ReactDOM.createPortal(
    //@ts-ignore
    <FormWrapper>
      <div css={componentCSS.self}>
        <CloseButton
          functionToClose={hideForm}
          color={themeStyle.background.secondary}
        />
        <UpdateUserHOC
          isErrorServer={userDetails.isError && !userDetails.data}
          isLoading={userDetails.isLoading}
          props={{
            userDetails:
              userDetails && userDetails.data && userDetails.data.data
                ? userDetails.data.data
                : null,
            passRefetch: refetch,
          }}
        />
      </div>
    </FormWrapper>,
    document.body,
  );
};
export default UpdateUserDetails;
