import React, { useEffect, useState } from 'react';
import { evaluations } from '../..';
import { useRouter } from 'next/router';
import { css } from '@emotion/react';
import { ITheme } from '../../../../services/style';
import Loader from '../../../../components/utils/Loader';
import ButtonOriginal from '../../../../components/buttons/ButtonOriginal';
import { updatePrivilege } from '../../service/util';
import { TLanguages } from '../../../../services/data';
import _ from 'lodash';
import { getSessionData } from '../../../../services/userSession';

const componentCSS = {
  title: (props: ITheme) => css`
    text-transform: capitalize;
    text-align: center;
    margin-top: 0;
    color: white;
    padding: 0.5rem;
    color: white !important;
    background-color: ${props.background.third};
  `,
  btnSendAndLoader: (props: ITheme) => css`
    margin: auto;
    .button_original {
      background-color: ${props.background.third};
      margin-top: 1rem;
    }
    .button_original span {
      color: white;
    }
  `,
};
const UserInvitedPrivilegeForm = ({
  privilegesOfTheUser,
  refetch,
  userInvitedId,
}) => {
  const { locale } = useRouter();
  const [notification, setNotification] = useState({ text: '', type: '' });
  const [doWeDisableEdit, setDoWeDisableEdit] = useState(false);
  const [privileges, setPrivileges] = useState({
    canInputEvaluationAndNotSeeAllEvaluations: false,
    canInputAndSeeAllEvaluations: false,
    canSeeJourneyComments: false,
    canAddJourneyComments: false,
    isUserBlocked: false,
    userIdInvited: null,
    id:
      privilegesOfTheUser && privilegesOfTheUser.length > 0
        ? privilegesOfTheUser[0].id
        : null,
  });
  const { userId } = getSessionData();

  useEffect(() => {
    if (privilegesOfTheUser && privilegesOfTheUser.length > 0) {
      setDoWeDisableEdit(!(userId === privilegesOfTheUser[0].invitedBy));
      setPrivileges({
        canInputEvaluationAndNotSeeAllEvaluations:
          privilegesOfTheUser[0].canInputEvaluationAndNotSeeAllEvaluations,
        canInputAndSeeAllEvaluations:
          privilegesOfTheUser[0].canInputAndSeeAllEvaluations,
        canSeeJourneyComments: privilegesOfTheUser[0].canSeeJourneyComments,
        canAddJourneyComments: privilegesOfTheUser[0].canAddJourneyComments,
        isUserBlocked: privilegesOfTheUser[0],
        id: privilegesOfTheUser ? privilegesOfTheUser[0].id : null,
        userIdInvited: userInvitedId ? userInvitedId : null,
      });
    } else {
      setPrivileges({
        canInputEvaluationAndNotSeeAllEvaluations: true,
        canInputAndSeeAllEvaluations: true,
        canSeeJourneyComments: true,
        canAddJourneyComments: true,
        isUserBlocked: false,
        id: privilegesOfTheUser ? privilegesOfTheUser.id : null,
        userIdInvited: userInvitedId ? userInvitedId : null,
      });

      setDoWeDisableEdit(true);
    }
  }, [privilegesOfTheUser]);

  const handleChange = (e) => {
    const { name, checked } = e.target;
    let privilegesCopy = _.cloneDeep(privileges);
    privilegesCopy[name] = checked;
    setPrivileges(privilegesCopy);
  };

  const updatePrivilegeMutation = updatePrivilege(
    locale as TLanguages,
    refetch,
    setNotification,
  );

  return (
    <>
      {/* <h2 className="font_size_20_medium" css={componentCSS.title}>
        {name}
      </h2> */}
      <div className="flex_column_start_align" style={{ width: 'max-content' }}>
        {evaluations[locale].privileges.map((priv, index) => (
          <div key={index} className="flex_center" style={{ gap: '5px' }}>
            <input
              type="checkbox"
              name={priv.table}
              checked={privileges[priv.table]}
              onChange={handleChange}
              disabled={doWeDisableEdit}
            />
            <label className="font_size_18_book">{priv.label}</label>
          </div>
        ))}
        {!doWeDisableEdit && (
          <div css={componentCSS.btnSendAndLoader}>
            {updatePrivilegeMutation.isLoading ? (
              <Loader />
            ) : (
              <ButtonOriginal
                text={'save'}
                icon={''}
                functionToApply={() =>
                  updatePrivilegeMutation.mutate(privileges)
                }
              />
            )}
            <p> {notification.text} </p>
          </div>
        )}
      </div>
    </>
  );
};

export default UserInvitedPrivilegeForm;
