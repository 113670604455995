import { css } from '@emotion/react';
import axios from 'axios';
import _ from 'lodash';
import { useRouter } from 'next/router';
import { useContext, useState } from 'react';
import { useMutation } from 'react-query';
import { IBodyNewEvaluation } from '../../../backend/evaluation';
import ButtonOriginal from '../../../components/buttons/ButtonOriginal';
import ComponentWithLogicDataFetching from '../../../components/utils/ComponentWithLogicDataFetching';
import { routesOut } from '../../../services/bridge';
import PatientDetailsContext from '../../../services/contexts/PatientDetailsContext';
import errorsJson from '../../../services/data/json/errors.json';
import { ITheme } from '../../../services/style/type';
import { questionsEvaluation } from '../data/constant';
import evaluationData from '../data/evaluation.json';
import { IPatientDetail, TEvaluationType } from '../type';
import { errorCSS, successCSS } from '../../forms';
export const componentCSS = {
  self: (props: ITheme) =>
    // @ts-ignore
    css({
      '@media print': {
        //using display: none does not work with print for chart
        display: 'none',
      },
      '& span': {
        color: 'white',
      },
      '& button': {
        backgroundColor: props.colors.sixth_first,
        padding: '2rem',
        marginTop: '45px',
      },
    }),
  wrapper: css({
    width: '90%',
    margin: 'auto',
    gap: '5px',
  }),
};

const Component = ({ props: { handleOnSubmit, notification } }) => {
  const router = useRouter();
  return (
    <div
      tabIndex={0}
      onKeyDown={(event) => {
        if (event.key === 'Enter') handleOnSubmit();
      }}
      role={'button'}
      onClick={() => handleOnSubmit()}
      className="flex_column_center_align"
    >
      <ButtonOriginal text={evaluationData[router.locale].button} icon={''} />
      <p
        css={[notification.error ? errorCSS : successCSS]}
        className="notification_form font_size_22_light"
      >
        {notification.message}
      </p>
    </div>
  );
};
const EvaluationSubmitButton = () => {
  const router = useRouter();
  const [notification, setNotification] = useState({
    error: false,
    message: '',
  });
  const SubmitEvaluationHTML = ComponentWithLogicDataFetching(Component);
  const { patientDetails } = useContext(PatientDetailsContext);
  const postNewEvaluation = useMutation(
    (newProcessEvaluation: IBodyNewEvaluation) =>
      axios
        .post(routesOut.data.postEvaluationProcess.link, newProcessEvaluation)
        .then((result) => result.data),
    {
      onSuccess: () => {
        setNotification({
          error: false,
          message: evaluationData[router.locale].divers[0],
        });

        setTimeout(() => {
          router.push('/notification/evaluationUnderReview');
        }, 3500);
      },
      onMutate: () => setNotification(evaluationData[router.locale].divers[1]),
      onError: (error: { response: { data: { message: string } } }) => {
        setNotification({
          error: true,
          message:
            error &&
            error.response &&
            error.response.data &&
            error.response.data.message &&
            errorsJson[router.locale][error.response.data.message]
              ? errorsJson[router.locale][error.response.data.message]
              : errorsJson[router.locale].server,
        });
        setTimeout(() => setNotification({ error: false, message: '' }), 3500);
      },
    }
  );
  const handleOnSubmit = () => {
    if (patientDetails.isEvaluationCompleted && patientDetails.isUnderReview) {
      router.push('/notification/evaluationUnderReview');
      return;
    }
    const { answersLength, questionsLength } = (() => {
      let answersLength = 0;
      let questionsLength = 0;
      patientDetails.userEvaluation.map((category) => {
        answersLength = answersLength + Object.keys(category.answers).length;
        questionsLength = questionsLength + category.questions.length;
      });
      // !repeat !logic
      questionsLength = patientDetails.diagnosticsQuestions
        ? questionsLength
        : questionsEvaluation.eng[patientDetails.evaluationType][1].questions
            .length;
      return { answersLength, questionsLength };
    })();
    if (answersLength !== questionsLength)
      return setNotification({
        error: true,
        message: `${
          evaluationData[router.locale][
            router.query.evalType as TEvaluationType
          ].notifications[2]
        } ${questionsLength - answersLength}`,
      });
    else {
      // Save the form
      const a: IPatientDetail = _.cloneDeep(patientDetails);
      // @ts-ignore
      a.userEvaluation = a.userEvaluation
        .map((evalu) => evalu.answers)
        .reduce((r, c) => Object.assign(r, c), {});

      postNewEvaluation.mutate(a as unknown as IBodyNewEvaluation);
    }
  };

  return (
    <div css={componentCSS.self} className="flex_center">
      <SubmitEvaluationHTML
        key={1}
        isErrorServer={postNewEvaluation.isError}
        isLoading={postNewEvaluation.isLoading}
        props={{
          handleOnSubmit,
          notification,
        }}
      />
    </div>
  );
};

export default EvaluationSubmitButton;
