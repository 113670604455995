import { ordinal, months } from '../../data/constant';
import { TLanguages } from '../../data/type';

const returnNewFormatDate = (date: Date | string): string | null => {
  const asDate = new Date(date);

  const formattedDate = asDate
    .toLocaleDateString('en-GB', {
      month: '2-digit',
      day: '2-digit',
      year: 'numeric',
    })
    .split('/')
    .join('.');
  return formattedDate;
};
const returnNewFormatDateSecond = (date: Date | string): string | null => {
  const asDate = new Date(date);

  const formattedDate = asDate
    .toLocaleDateString('en-GB', {
      month: '2-digit',
      day: '2-digit',
      year: 'numeric',
    })
    .split('/')
    .join('-');
  return formattedDate;
};

const returnAgeFromBirthDay = (birthDay: string): number => {
  var pattern = /^(\d{2})-(\d{2})-(\d{4})$/;
  const isMatch = birthDay.match(pattern);

  const currentDate = new Date();

  let birthDate: Date;
  if (isMatch) {
    const day = Number(isMatch[1]);
    const month = Number(isMatch[2]) - 1;
    const year = Number(isMatch[3]);
    birthDate = new Date(year, month, day);
  } else {
    birthDate = new Date(birthDay);
  }

  let age = currentDate.getFullYear() - birthDate.getFullYear();

  const currentMonth = currentDate.getMonth();
  const currentDay = currentDate.getDate();
  if (
    currentMonth < birthDate.getMonth() ||
    (currentMonth === birthDate.getMonth() && currentDay < birthDate.getDate())
  ) {
    age--;
  }

  return age;
};

const returnDifferenceTimeStamp = (dateTime) => {
  const currentDate = new Date();
  const targetDate = new Date(dateTime);
  //@ts-ignore
  const differenceInMilliseconds = currentDate - targetDate;
  const differenceInMinutes = Math.floor(
    differenceInMilliseconds / (1000 * 60),
  );

  return differenceInMinutes;
};
const returnFormatDateOrdinal = (
  date: string,
  language: TLanguages,
): string => {
  if (!date) return;
  let regex = /^(0?[1-9]|1[0-2])[/.](0?[1-9]|[12][0-9]|3[01])[/.](\d{4})/;
  let regex2 = /^(\d{2}).(\d{2}).(\d{4})$/;
  let destructure = date.match(regex);
  if (!destructure) destructure = date.match(regex2);
  const day = destructure
    ? ordinal[destructure[2]]
      ? destructure[1] + ordinal[language][destructure[1]]
      : destructure[1] + ordinal[language].default
    : null;
  return destructure
    ? day +
        ' ' +
        months[language][parseInt(destructure[2]) - 1].toUpperCase() +
        ' ' +
        destructure[3]
    : null;
};
const returnActualYear = () => new Date().getFullYear();
const returnDate = (): string => {
  let currentDate = new Date();

  let year = currentDate.getFullYear();
  let month = currentDate.getMonth() + 1; // getMonth returns a value between 0 and 11, so we need to add 1 to get the correct month number
  let day = currentDate.getDate();

  // If the month or day is less than 10, we need to add a leading zero
  if (month < 10) {
    // @ts-ignore
    month = '0' + month;
  }
  if (day < 10) {
    // @ts-ignore
    day = '0' + day;
  }

  return day + '.' + month + '.' + year;
};
const returnDateFromDateDB = (date: string) => {
  let data = new Date(date);
  let formattedDate = data.toLocaleDateString('en-US', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  });
  return formattedDate;
};
const returnDateAndHoursFromDateDB = (dateDB: string) => {
  const date = new Date(dateDB);

  // Extract the individual components
  const year = date.getUTCFullYear();
  const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // Months are zero-indexed
  const day = String(date.getUTCDate()).padStart(2, '0');
  const hours = String(date.getUTCHours()).padStart(2, '0');
  const minutes = String(date.getUTCMinutes()).padStart(2, '0');
  // const seconds = String(date.getUTCSeconds()).padStart(2, '0');
  // const milliseconds = String(date.getUTCMilliseconds()).padStart(3, '0');

  // Format the date and time as needed
  const formattedDate = `${day}.${month}.${year}`;
  const formattedTime = `${hours}:${minutes}`;

  return { formattedDate, formattedTime };
};
const returnDateFromDateDBGB = (date: string) => {
  let data = new Date(date);
  let formattedDate = data.toLocaleDateString('en-GB', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  });
  return formattedDate;
};
function reverseFormatDate(date) {
  var d = new Date(date),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;

  return [day, month, year].join('/');
}
function getFormattedDate() {
  const date = new Date();
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
}
function getDaysDifference(dateString) {
  // Parse the input date string in DD.MM.YYYY format
  const [day, month, year] = dateString.split('.').map(Number);
  const targetDate = new Date(year, month - 1, day); // JavaScript months are 0-based
  const currentDate = new Date();

  // Calculate the time difference in milliseconds
  //@ts-ignore
  const timeDifference = currentDate - targetDate;

  // Convert the time difference from milliseconds to days
  const dayDifference = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));

  return dayDifference;
}
export {
  returnNewFormatDate,
  returnAgeFromBirthDay,
  returnDifferenceTimeStamp,
  returnFormatDateOrdinal,
  returnActualYear,
  returnDate,
  returnDateFromDateDB,
  returnDateAndHoursFromDateDB,
  returnDateFromDateDBGB,
  reverseFormatDate,
  getFormattedDate,
  getDaysDifference,
  returnNewFormatDateSecond,
};
