import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { useRouter } from 'next/router';
import React, { useState } from 'react';
import CloseButton from '../../../components/buttons/CloseButton';
import IconsLogos from '../../../components/elements/IconsLogos';
import { themeStyle } from '../../../services/style/constant';
import evaluationData from '../data/evaluation.json';
export type TPerceptionCircle = {
  icon: string;
  type: string;
  classCSS: string;
  original: string;
};
// const colors = [
//   'red',
//   'orange',
//   'green',
//   'blue',
//   'purple',
//   'pink',
//   'brown',
//   'gray',
//   'black',
//   'teal',
//   'magenta',
// ];

export const colorDiagnostic = {
  hyperactivity: '#E85C9E',
  inattention: '#FBD772',
  odd: '#85D3E6',
  neutral: 'white',
};

// Styles
const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
`;

const Content = styled.div`
  background-color: #fff;
  padding: 20px;
  max-width: 80%;
  max-height: 80%;
  overflow-y: auto;
  position: relative;
`;

const componentCSS = {
  overlay: css({
    width: '100%',
    height: '100%',
  }),
  window: css({
    backgroundColor: 'white',
    width: '90%',
    height: '100%',
    '@media (min-width: 650px)': {
      width: '70%',
    },
    '& li': {
      marginBottom: '1rem',
    },
  }),
  self: css({
    margin: 'auto',
    '& svg': {
      width: '2rem',
    },
    '& .perception_circle': {
      border: 'none',
      borderRadius: '50%',
      '& svg': {
        '@media (min-width: 650px)': {
          fontSize: '5rem',
        },
      },
    },
    '& .circle_1': {
      borderRadius: '50%',
      height: '9rem',
      width: '9rem',

      '@media (min-width: 650px)': {
        height: '13rem',
        width: '13rem',
      },
      '@media print': {
        //using display: none does not work with print for chart
        height: '13rem',
        width: '13rem',
        backgroundColor: 'red',
      },
    },
    '& .circle_2': {
      height: '8rem',
      width: '8rem',
      '@media (min-width: 650px)': {
        height: '12rem',
        width: '12rem',
      },
      '@media print': {
        //using display: none does not work with print for chart
        height: '13rem',
        width: '13rem',
      },
    },
    '& .circle_3': {
      height: '8rem',
      width: '8rem',

      '@media (min-width: 650px)': {
        height: '11rem',
        width: '11rem',
      },
      '@media print': {
        //using display: none does not work with print for chart
        height: '13rem',
        width: '13rem',
      },
    },
    '& .circle_4': {
      border: '1px solid grey',
      backgroundColor: 'white !important',
      color: 'black',
      height: '7.5rem',
      width: '7.5rem',
      '@media (min-width: 650px)': {
        height: '9rem',
        width: '9rem',
      },
    },
  }),
  buttonCross: css`
    text-align: right;
    color: white;
    & button {
      color: white;
    }
  `,
  title: css`
    color: #463986;
    font-family: Montserrat;
    font-weight: bold;
    text-transform: capitalize;
  `,
  paragraph: css`
    color: #463986;
    font-family: Montserrat;
    font-weight: bold;
  `,
};
const PerceptionCircle: React.FC<TPerceptionCircle> = ({
  icon,
  type,
  classCSS,
  original,
}) => {
  const [isOverlayVisible, setIsOverlayVisible] = useState(false);
  const { locale } = useRouter();
  const handleClose = () => setIsOverlayVisible(false);

  return (
    <div css={componentCSS.self}>
      <div className="flex_end_end">
        <button
          type="button"
          className={`perception_circle ${classCSS}`}
          // css={{ backgroundColor: colors[Math.floor(Math.random() * 11)] }}
          css={{ backgroundColor: colorDiagnostic[original] }}
          onClick={() => setIsOverlayVisible(true)}
        />
        <div>
          <IconsLogos type={icon} />
          <span
            className="font_size_18_medium"
            style={{ textTransform: 'capitalize' }}
          >
            {type}
          </span>
        </div>
      </div>
      {isOverlayVisible && (
        <Overlay css={componentCSS.overlay}>
          <Content css={componentCSS.window}>
            <div css={componentCSS.buttonCross}>
              <CloseButton
                functionToClose={handleClose}
                color={themeStyle.background.fifth}
              />
            </div>
            <h1 css={componentCSS.title}>{type}</h1>
            <p
              className="info_bubble"
              css={componentCSS.paragraph}
              dangerouslySetInnerHTML={{
                __html:
                  evaluationData[locale].bubble.informations[original].content,
              }}
            ></p>
          </Content>
        </Overlay>
      )}
    </div>
  );
};
export default PerceptionCircle;
