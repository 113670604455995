import { css } from '@emotion/react';
import { ReactNode } from 'react';
import { ITheme } from '../../services/style/type';
import { useHeaderSizeContext } from '../../services/contexts/userHeaderSizeContext';

export interface IChildren {
  children: ReactNode;
}
const componentCSS = {
  main: (props: ITheme) =>
    css({
      width: '100%',
      maxWidth: '1000px',
      padding: '1rem',
      '@media (min-width: 1000px)': {
        borderRadius: '30px 30px 30px 30px',
        margin: 'auto',
        marginTop: '2rem',
        marginBottom: '2rem',
      },
      backgroundColor: 'white',
      flex: 2,
      '& h2': {
        letterSpacing: `${props.spacing.letter_spacing_primary}rem`,
      },
      '& h3': {
        letterSpacing: `${props.spacing.letter_spacing_secondary}rem`,
      },
    }),
};

const MainLayout = ({ children }: IChildren) => {
  const { headerSize } = useHeaderSizeContext();

  return (
    <main
      className="main_layout flex_column"
      id="main-layout"
      style={{ flexGrow: 1, marginTop: headerSize.height }}
    >
      <div css={componentCSS.main}>{children}</div>
    </main>
  );
};
export default MainLayout;
